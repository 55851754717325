import React, { useRef, useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { parseRGBAColor } from "../../utils/color";

interface ColorPickerProps {
  initialColor: string | null | undefined;
  onColorChange: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ initialColor, onColorChange }) => {
  // Use parseRGBAColor to generate the default color object for black
  const defaultColor = parseRGBAColor("rgba(0, 0, 0, 1)");

  const [color, setColor] = useState<ColorResult>(
    initialColor ? parseRGBAColor(initialColor) : defaultColor,
  );
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleColorChange = (newColor: ColorResult) => {
    setColor(newColor);
    onColorChange(
      `rgba(${newColor.rgb.r}, ${newColor.rgb.g}, ${newColor.rgb.b}, ${newColor.rgb.a})`,
    );
  };

  const togglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef} className="relative inline-block">
      <div
        className="w-10 h-10 border border-gray-300 rounded-full cursor-pointer"
        style={{
          backgroundColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        }}
        onClick={togglePicker}
      ></div>
      {isOpen && (
        <div className="absolute z-50 mt-2">
          <SketchPicker color={color.rgb} onChange={handleColorChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
