import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chapterPromptsElementsAtom,
  currentCharacterEmotionState,
  isChapterCompleteState,
  isChapterStartedState,
  isGameLostState,
  objectiveHasJustBeenCompletedState,
} from "../states/chapterState.tsx";
import { MessageType } from "../constants/messageType.ts";
import { useWebSocket } from "../contexts/WebSocketProvider.tsx";
import AudioRecorder from "../components/AudioRecorder.tsx";
import { audioDataQueueState, isThinkingState } from "../states/audioDataState.ts";
import AnimatedText from "../components/AnimatedText.tsx";
import { useFetchChapterPrompt } from "../hooks/useFetchChapterPrompt.ts";
import ErrorModal from "../components/ErrorModal.tsx";
import { formatDateString } from "../utils/date.ts";
import { useNavigateWithQueryParams } from "../hooks/useNavigateWithQueryParams.ts";
import { CHAPTER_TYPE_WON_GAME } from "../constants/constant.ts";
import { formatRGBAColor } from "../utils/color.ts";
import { useDebouncedValue } from "../hooks/useDebounceValue.ts";
import useBackgroundMusicManager from "../hooks/useBackgroundMusicManager.ts";
import { capitalize } from "../utils/stringUtil.ts";
import GameVisualEngine, {
  POSITION_BACKGROUND,
  POSITION_CHARACTER_SPEAKING,
} from "../components/GameVisualEngine.tsx";

const GameScreen: React.FC = () => {
  const webSocketWrapper = useWebSocket();
  const [isChapterComplete, setIsChapterComplete] = useRecoilState(isChapterCompleteState);
  const [isGameLost] = useRecoilState(isGameLostState);
  const [isChapterStarted, setIsChapterStarted] = useRecoilState(isChapterStartedState);
  const [characterEmotion] = useRecoilState(currentCharacterEmotionState);
  const [objectiveHasJustBeenCompleted] = useRecoilState(objectiveHasJustBeenCompletedState);
  const chaptersPromptElements = useRecoilValue(chapterPromptsElementsAtom);
  const [, setIsThinking] = useRecoilState(isThinkingState);
  const [audioDataQueue] = useRecoilState(audioDataQueueState);
  const navigate = useNavigateWithQueryParams();
  useFetchChapterPrompt();
  const { loadBackgroundMusic } = useBackgroundMusicManager();
  const debouncedQueueLength = useDebouncedValue(audioDataQueue.length, 500, 1);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  useEffect(() => {
    setActiveImageIndex(debouncedQueueLength === 0 ? 0 : 1);
  }, [debouncedQueueLength]);

  const startChapter = useCallback(() => {
    if (chaptersPromptElements.length > 0 && webSocketWrapper) {
      setIsChapterStarted(true);
      setIsChapterComplete(false);
      setIsThinking(true);
      webSocketWrapper.sendMessage(MessageType.USER_INPUT, chaptersPromptElements[0]);
      loadBackgroundMusic({
        storyId: chaptersPromptElements[0]?.blueprint_story_id,
        fileName: chaptersPromptElements[0]?.sound_intro,
        volume: chaptersPromptElements[0]?.sound_intro_volume,
        secondFileName: chaptersPromptElements[0]?.sound_outro,
      });
    }
  }, [chaptersPromptElements, webSocketWrapper]);

  const onRecordingComplete = useCallback(
    (audioBlob: Blob) => {
      if (webSocketWrapper && chaptersPromptElements.length > 0) {
        setIsThinking(true);
        webSocketWrapper.sendMessage(MessageType.USER_INPUT, chaptersPromptElements[0], audioBlob);
      }
    },
    [chaptersPromptElements, webSocketWrapper],
  );

  useEffect(() => {
    console.log("game screen chaptersPromptElements", chaptersPromptElements);
    if (
      isChapterComplete &&
      chaptersPromptElements.length > 0 &&
      chaptersPromptElements[0].parent_chapter_id != null
    ) {
      startChapter();
    }
  }, [chaptersPromptElements]);

  const renderStartScreen = () => (
    <>
      {chaptersPromptElements.length > 0 && (
        <div
          className="pl-12 pr-12 pt-40 text-4xl flex flex-col h-screen"
          style={{
            backgroundColor: chaptersPromptElements[0].background_rgba_color
              ? formatRGBAColor(chaptersPromptElements[0].background_rgba_color)
              : "black",
          }}
        >
          {chaptersPromptElements[0].scene_date && chaptersPromptElements[0].language && (
            <AnimatedText
              text={formatDateString(
                chaptersPromptElements[0].scene_date,
                chaptersPromptElements[0].language,
              )}
              rgbaColor={chaptersPromptElements[0].text_rgba_color}
            />
          )}
          <h1
            className="fade-in font-sans font-bold"
            style={{
              color: chaptersPromptElements[0].text_rgba_color
                ? formatRGBAColor(chaptersPromptElements[0].text_rgba_color)
                : "black",
            }}
          >
            {capitalize(chaptersPromptElements[0].chapter_name)}{" "}
            <button
              style={{
                color: chaptersPromptElements[0].control_rgba_color
                  ? formatRGBAColor(chaptersPromptElements[0].control_rgba_color)
                  : "black",
              }}
              className="opacity-100 hover:opacity-50 transition-opacity duration-500"
              onClick={() => startChapter()}
            >
              Start
            </button>
          </h1>
        </div>
      )}
    </>
  );

  const renderInGameScreen = () => (
    <>
      {!isChapterComplete && (
        <div
          className="relative w-full h-screen flex flex-col"
          style={{
            backgroundColor: chaptersPromptElements[0]?.background_rgba_color
              ? formatRGBAColor(chaptersPromptElements[0].background_rgba_color)
              : "black",
          }}
        >
          {/* Game Visual Container */}
          <div className="flex-grow relative">
            <div className="absolute inset-0">
              <GameVisualEngine
                storyId={chaptersPromptElements[0]?.blueprint_story_id ?? ""}
                backgroundImages={chaptersPromptElements[0]?.chapter_image_details}
                characterImages={chaptersPromptElements[0]?.character_image_details}
                currentPosition={
                  activeImageIndex === 0 ? POSITION_BACKGROUND : POSITION_CHARACTER_SPEAKING
                }
                characterEmotion={characterEmotion.emotionKey}
                characterEmotionStrength={characterEmotion.emotionStrength}
                isEvent={objectiveHasJustBeenCompleted}
              />
            </div>
          </div>

          {/* Audio Recorder Container */}
          <div className="w-full bg-black bg-opacity-90 fixed bottom-0 h-1/5 left-0 right-0 z-50 flex justify-center ">
            <AudioRecorder onRecordingComplete={onRecordingComplete} />
          </div>
        </div>
      )}
    </>
  );

  const renderEndOfChapterScreen = () => (
    <div
      className="pl-12 pr-12 pt-40 text-4xl flex flex-col h-screen"
      style={{
        backgroundColor: chaptersPromptElements[0].background_rgba_color
          ? formatRGBAColor(chaptersPromptElements[0].background_rgba_color)
          : "black",
      }}
    >
      <AnimatedText text="CHAPTER COMPLETE" rgbaColor={chaptersPromptElements[0].text_rgba_color} />
      <h1 className="fade-in">
        <button
          style={{
            color: chaptersPromptElements[0].control_rgba_color
              ? formatRGBAColor(chaptersPromptElements[0].control_rgba_color)
              : "black",
          }}
          className=" opacity-100 hover:opacity-50 transition-opacity duration-500"
          onClick={() => setIsChapterStarted(false)}
        >
          Start next chapter
        </button>
      </h1>
    </div>
  );

  const renderLostScreen = () => (
    <div
      className="pl-12 pr-12 pt-40 text-4xl flex flex-col h-screen"
      style={{
        backgroundColor: chaptersPromptElements[0].background_rgba_color
          ? formatRGBAColor(chaptersPromptElements[0].background_rgba_color)
          : "black",
      }}
    >
      <AnimatedText text="YOU LOOSE" rgbaColor={chaptersPromptElements[0].text_rgba_color} />
      <h1 className="fade-in ">
        <button
          style={{
            color: chaptersPromptElements[0].control_rgba_color
              ? formatRGBAColor(chaptersPromptElements[0].control_rgba_color)
              : "black",
          }}
          className="opacity-100 hover:opacity-50 transition-opacity duration-500"
          onClick={() => navigate("/launch")}
        >
          Restart
        </button>
      </h1>
    </div>
  );

  const renderGameWonScreen = () => (
    <>
      <AnimatedText text="YOU WON" rgbaColor={chaptersPromptElements[0].text_rgba_color} />
      <h1 className="fade-in">
        <button
          style={{
            color: chaptersPromptElements[0].control_rgba_color
              ? formatRGBAColor(chaptersPromptElements[0].control_rgba_color)
              : "black",
          }}
          className="text-white opacity-100 hover:opacity-50 transition-opacity duration-500"
          onClick={() => navigate("/launch")}
        >
          Restart
        </button>
      </h1>
    </>
  );

  return (
    <div className=" text-4xl flex flex-col h-screen text-black gap-3">
      {/*<div className="absolute z-10">*/}
      {/*  <p className="text-base">{"isChapterStarted: " + isChapterStarted.toString()}</p>*/}
      {/*  <p className="text-base">{"isChapterComplete: " + isChapterComplete.toString()}</p>*/}
      {/*  <p className="text-base">{"audioDataQueue.length: " + audioDataQueue.length.toString()}</p>*/}
      {/*  <p className="text-base">*/}
      {/*    {"parent_chapter_id: " + chaptersPromptElements[0]?.parent_chapter_id?.toString()}*/}
      {/*  </p>*/}
      {/*  <p className="text-base">*/}
      {/*    {"chapter_name: " + chaptersPromptElements[0]?.chapter_name?.toString()}*/}
      {/*  </p>*/}
      {/*</div>*/}
      <ErrorModal />
      {(() => {
        const isStartScreen = !isChapterStarted;
        const isGameLostScreen = isGameLost && isChapterStarted;
        const isGameWonScreen =
          chaptersPromptElements[0]?.chapter_type === CHAPTER_TYPE_WON_GAME && isChapterStarted;
        const isEndOfChapterScreen =
          isChapterComplete &&
          chaptersPromptElements[0].parent_chapter_id == null &&
          audioDataQueue.length == 0;

        if (isStartScreen) {
          return renderStartScreen();
        } else if (isGameLostScreen) {
          return renderLostScreen();
        } else if (isEndOfChapterScreen) {
          return renderEndOfChapterScreen();
        } else if (isGameWonScreen) {
          return renderGameWonScreen();
        } else {
          return renderInGameScreen();
        }
      })()}
    </div>
  );
};

export default GameScreen;
