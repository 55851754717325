import { useState } from "react";
import {
  databaseOperations,
  DatabaseTypes,
  handleDatabaseError,
  SearchFilters,
} from "@/components/admin/promptManagement/database/databaseOperations.ts";

interface UsePromptLogsReturn {
  logs: DatabaseTypes["PromptExecutionDetails"][];
  selectedLog: DatabaseTypes["PromptExecutionDetails"] | null;
  errorMessage: string | null;
  isLoading: boolean;
  setSelectedLog: (log: DatabaseTypes["PromptExecutionDetails"] | null) => void;
  setErrorMessage: (message: string | null) => void;
  fetchLogs: (filters?: SearchFilters) => Promise<void>;
  handleEvalCheckboxChange: (
    log: DatabaseTypes["PromptExecutionDetails"],
    isChecked: boolean,
    currentFilters: SearchFilters,
  ) => Promise<void>;
}

export const usePromptLogs = (promptId: string | undefined): UsePromptLogsReturn => {
  const [logs, setLogs] = useState<DatabaseTypes["PromptExecutionDetails"][]>([]);
  const [selectedLog, setSelectedLog] = useState<DatabaseTypes["PromptExecutionDetails"] | null>(
    null,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchLogs = async (searchFilters?: SearchFilters) => {
    if (!promptId) return;
    setIsLoading(true);

    try {
      const { data: logsData, error: logsError } = await databaseOperations.fetchPromptExecutions(
        promptId,
        searchFilters,
      );
      if (logsError) throw logsError;

      setLogs(logsData);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEvalCheckboxChange = async (
    log: DatabaseTypes["PromptExecutionDetails"],
    isChecked: boolean,
    currentFilters: SearchFilters,
  ) => {
    if (!log.id) return;

    try {
      await databaseOperations.updateUsedForEvalInPromptExecution(log.id, isChecked);
      fetchLogs(currentFilters);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  return {
    logs,
    selectedLog,
    errorMessage,
    isLoading,
    setSelectedLog,
    setErrorMessage,
    fetchLogs,
    handleEvalCheckboxChange,
  };
};
