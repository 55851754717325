import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  analyserBackgroundMusicNodeState,
  audioContextState,
  gainNodeState,
  sourceNodeState,
  volumeState,
} from "../states/audioDataState";
import { supabase } from "../vendor/supabaseClient";
import { SOUND_BUCKET_NAME } from "../constants/constant";

interface BackgroundMusicParams {
  storyId: string | null | undefined;
  fileName: string | null | undefined;
  volume: number | null | undefined;
  secondFileName?: string | null | undefined;
}

const useBackgroundMusicManager = () => {
  const audioContext = useRecoilValue(audioContextState);
  const [gainNode, setGainNode] = useRecoilState(gainNodeState);
  const [analyserNode, setAnalyserNode] = useRecoilState(analyserBackgroundMusicNodeState);
  const [sourceNode, setSourceNode] = useRecoilState(sourceNodeState);
  const [volume, setVolume] = useRecoilState(volumeState);

  const loadAudioFile = async (storyId: string, fileName: string): Promise<AudioBuffer | null> => {
    const { data, error } = await supabase.storage
      .from(SOUND_BUCKET_NAME)
      .download(`${storyId}/${fileName}`);

    if (error) {
      console.error("Error fetching audio file:", error);
      return null;
    }

    if (data && audioContext) {
      const audioBlob = new Blob([data], { type: "audio/mp3" });
      const arrayBuffer = await audioBlob.arrayBuffer();
      return await audioContext.decodeAudioData(arrayBuffer);
    }

    return null;
  };

  const loadBackgroundMusic = useCallback(
    async ({ storyId, fileName, volume, secondFileName }: BackgroundMusicParams) => {
      if (!storyId || !fileName || (volume === undefined) === undefined) {
        return;
      }
      setVolume(volume ? 0.01 * volume : 1);

      // Stop the current source node if it exists
      if (sourceNode) {
        sourceNode.stop();
        sourceNode.disconnect();
        setSourceNode(null);
      }

      const buffer = await loadAudioFile(storyId, fileName);

      if (buffer && audioContext) {
        const newGainNode = audioContext.createGain();
        newGainNode.gain.value = volume ? 0.01 * volume : 1;
        setGainNode(newGainNode);

        const newAnalyserNode = audioContext.createAnalyser();
        newAnalyserNode.fftSize = 2048;
        setAnalyserNode(newAnalyserNode);

        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(newGainNode).connect(newAnalyserNode).connect(audioContext.destination);
        source.loop = false;
        source.start();
        setSourceNode(source);
        console.log(`Started playing first file: ${fileName}`);

        // Handle second file if provided
        if (secondFileName) {
          source.onended = async () => {
            console.log(
              `First file (${fileName}) ended, attempting to play second file (${secondFileName})`,
            );
            const secondBuffer = await loadAudioFile(storyId, secondFileName);
            if (secondBuffer) {
              const secondSource = audioContext.createBufferSource();
              secondSource.buffer = secondBuffer;
              secondSource
                .connect(newGainNode)
                .connect(newAnalyserNode)
                .connect(audioContext.destination);
              secondSource.loop = true;
              secondSource.start();
              setSourceNode(secondSource);
            }
          };
        }
      }
    },
    [audioContext, setGainNode, setAnalyserNode, setSourceNode, sourceNode],
  );

  const decreaseBackgroundVolumeForRecording = (duration: number = 500) => {
    if (audioContext && gainNode) {
      const startTime = audioContext.currentTime;
      const endTime = startTime + duration / 1000;
      gainNode.gain.setValueAtTime(gainNode.gain.value, startTime);
      gainNode.gain.exponentialRampToValueAtTime(0.001, endTime);
      setGainNode(gainNode);
    }
  };

  const resetBackgroundVolumeAfterRecording = (duration: number = 1000) => {
    if (audioContext && gainNode) {
      const startTime = audioContext.currentTime;
      const endTime = startTime + duration / 1000;
      gainNode.gain.setValueAtTime(gainNode.gain.value, startTime);
      gainNode.gain.exponentialRampToValueAtTime(volume, endTime);
      setGainNode(gainNode);
    }
  };

  const stopBackgroundMusic = useCallback(() => {
    if (sourceNode) {
      sourceNode.stop();
      sourceNode.disconnect();
      setSourceNode(null);
    }
  }, [sourceNode, setSourceNode]);

  const getFrequencyData = () => {
    if (!analyserNode) return null;
    const dataArray = new Uint8Array(analyserNode.frequencyBinCount);
    analyserNode.getByteFrequencyData(dataArray);
    return dataArray;
  };

  return {
    getFrequencyData,
    decreaseBackgroundVolumeForRecording,
    resetBackgroundVolumeAfterRecording,
    loadBackgroundMusic,
    stopBackgroundMusic,
  };
};

export default useBackgroundMusicManager;
