import React, { useEffect, useRef } from "react";
import anime from "animejs";
import { formatRGBAColor } from "../utils/color.ts";

interface AnimatedTextProps {
  text: string;
  rgbaColor: string | null;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ text, rgbaColor }) => {
  const textWrapperRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (textWrapperRef.current) {
      textWrapperRef.current.innerHTML = text.replace(/\S/g, "<span class='letter'>$&</span>");

      anime
        .timeline({ loop: false })
        .add({
          targets: ".ml1 .letter",
          scale: [0.3, 1],
          opacity: [0, 1],
          translateZ: 0,
          easing: "easeOutExpo",
          duration: 600,
          delay: (_el, i) => 70 * (i + 1),
        })
        .add({
          targets: ".ml1 .line",
          scaleX: [0, 1],
          opacity: [0.5, 1],
          easing: "easeOutExpo",
          duration: 700,
          offset: "-=875",
          delay: (_el, i, l) => 80 * (l - i),
        });
    }
  }, [text]);

  return (
    <h1
      className="ml1"
      style={{
        color: rgbaColor ? formatRGBAColor(rgbaColor) : "black",
      }}
    >
      <span className="text-wrapper relative inline-block ">
        <span
          style={{
            backgroundColor: rgbaColor ? formatRGBAColor(rgbaColor) : "black",
          }}
          className="line line1 absolute left-0 h-[3px] w-full scale-x-0"
        ></span>
        <span className="letters" ref={textWrapperRef}>
          {text}
        </span>
        <span
          style={{
            backgroundColor: rgbaColor ? formatRGBAColor(rgbaColor) : "black",
          }}
          className="line line2 absolute left-0 bottom-0 h-[3px] w-full scale-x-0"
        ></span>
      </span>
    </h1>
  );
};

export default AnimatedText;
