import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalScenesGenerateState } from "../../../states/ModalState.ts";
import { useGenerateScenes } from "./useGenerateScenesHooks.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalScenesGenerateState);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const {
    data: scenes,
    status: sceneStatus,
    callApi: generateScenes,
    setData: setScenes,
  } = useGenerateScenes();

  const handleClose = () => {
    setIsOpen(false);
    setStepIndex(0);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    scenes,
    sceneStatus,
    generateScenes,
    setScenes,

    handleClose,
  };
};
