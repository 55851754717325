type Params = {
  [key: string]: string | undefined;
};

export const getQueryParams = (): Params => {
  const searchParams = new URLSearchParams(window.location.search);
  const params: Params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params;
};
