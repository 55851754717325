import { useEffect, useState } from "react";
import { LOCALE_ENGLISH } from "@/constants/constant";
import { formatDateString } from "@/utils/date";
import { Card, CardContent } from "@/components/admin/Card";
import { ExecutionLogsRenderer } from "@/components/admin/promptManagement/ExecutionLogsRenderer";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/catalyst/table";
import { Checkbox, CheckboxField } from "@/components/catalyst/checkbox";
import SearchInterface from "@/components/admin/promptManagement/PromptLogSearch";
import {
  DatabaseTypes,
  SearchFilters,
} from "@/components/admin/promptManagement/database/databaseOperations.ts";
import { usePromptLogs } from "@/components/admin/promptManagement/database/usePromptLogsDatabase.ts";

export interface PromptLogsProps {
  promptId: string;
  isUsedToDefineDataset?: boolean;
  evalDatasets?: Record<string, boolean>;
  handleEvalDatasetsUpdate?: (
    log: DatabaseTypes["PromptExecutionDetails"],
    isChecked: boolean,
  ) => Promise<void>;
}

const LogsViewer: React.FC<PromptLogsProps> = ({
  promptId,
  isUsedToDefineDataset = false,
  evalDatasets = null,
  handleEvalDatasetsUpdate = null,
}) => {
  const {
    logs,
    selectedLog,
    errorMessage,
    isLoading,
    setSelectedLog,
    setErrorMessage,
    fetchLogs,
    handleEvalCheckboxChange,
  } = usePromptLogs(promptId);

  const [filters, setFilters] = useState<SearchFilters>({
    searchTerm: "",
    dateRange: { startDate: null, endDate: null },
    version: "",
    evalStatus: isUsedToDefineDataset ? "true" : "all",
  });

  useEffect(() => {
    fetchLogs(filters);
  }, []);

  const handleSearch = (newFilters: SearchFilters) => {
    setFilters(newFilters);
    fetchLogs(newFilters);
  };

  if (isUsedToDefineDataset && (!evalDatasets || !handleEvalDatasetsUpdate)) return;

  return (
    <div className="h-screen flex flex-col">
      <div className="top-section sticky top-0 backdrop-blur-xl z-50 p-6">
        {errorMessage && (
          <div
            className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
            onClick={() => setErrorMessage(null)}
          >
            {errorMessage}
          </div>
        )}
        <h1 className="text-5xl font-bold mb-4">{logs[0] ? logs[0].prompt_name : "No logs"}</h1>
        <SearchInterface
          onSearch={handleSearch}
          isLoading={isLoading}
          hideUsedInEval={isUsedToDefineDataset}
        />
      </div>
      <Card isFullWidth={true} className="flex-1 overflow-hidden">
        <CardContent className="h-full">
          <div className="flex gap-4 h-full">
            <div className="w-1/2 flex flex-col">
              <div className="overflow-auto">
                <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
                  <TableHead className="sticky top-0 bg-white z-10">
                    <TableRow>
                      <TableHeader>
                        <div className="pl-4">Last executed at</div>
                      </TableHeader>
                      <TableHeader>Version</TableHeader>
                      <TableHeader>Input</TableHeader>
                      <TableHeader>Output</TableHeader>
                      <TableHeader>Perf</TableHeader>
                      <TableHeader>{isUsedToDefineDataset ? "Run " : "Used "} in Eval</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs.map((log) => (
                      <TableRow
                        key={log.id}
                        className={`cursor-pointer ${
                          selectedLog?.id === log.id
                            ? "bg-black text-white hover:bg-black/90"
                            : "hover:bg-zinc-950/5"
                        }`}
                        onClick={() => setSelectedLog(log)}
                      >
                        <TableCell>
                          <div className="pl-4">
                            {formatDateString(log.created_at, LOCALE_ENGLISH)}
                          </div>
                        </TableCell>
                        <TableCell>{log.version}</TableCell>
                        <TableCell>{log.input_token_count}</TableCell>
                        <TableCell>{log.output_token_count}</TableCell>
                        <TableCell>{log.execution_time?.toFixed(1)}s</TableCell>
                        {isUsedToDefineDataset && handleEvalDatasetsUpdate && evalDatasets ? (
                          <TableCell onClick={(e) => e.stopPropagation()}>
                            <CheckboxField className="items-center">
                              <Checkbox
                                checked={evalDatasets[log.id!] || false}
                                disabled={isLoading}
                                onChange={(isChecked) => handleEvalDatasetsUpdate(log, isChecked)}
                              />
                            </CheckboxField>
                          </TableCell>
                        ) : (
                          <TableCell onClick={(e) => e.stopPropagation()}>
                            <CheckboxField className="items-center">
                              <Checkbox
                                checked={log.used_for_eval ? log.used_for_eval : false}
                                disabled={isLoading}
                                onChange={(isChecked) =>
                                  handleEvalCheckboxChange(log, isChecked, filters)
                                }
                              />
                            </CheckboxField>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className="w-1/2 overflow-hidden">
              <div className="h-full overflow-y-auto">
                <CardContent className="pl-10 mt-1">
                  {selectedLog ? (
                    <ExecutionLogsRenderer executionLogs={selectedLog} />
                  ) : (
                    <div className="text-gray-500 text-center py-8">
                      Select an item to view details
                    </div>
                  )}
                </CardContent>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default LogsViewer;
