import React from "react";
import { Input } from "../catalyst/input.tsx";

interface FormFieldProps {
  value: number | null;
  onChange: (value: number) => void;
  label?: string;
  // New props for decimal configuration
  isDecimal?: boolean;
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: number;
}

const NumericStepper: React.FC<FormFieldProps> = ({
  value,
  onChange,
  label,
  isDecimal = false,
  min = isDecimal ? 0 : undefined,
  max = isDecimal ? 1 : undefined,
  step = isDecimal ? 0.01 : 1,
  defaultValue = isDecimal ? 0 : 5,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parseFunction = isDecimal ? parseFloat : parseInt;
    const newValue = parseFunction(e.target.value);

    if (isNaN(newValue)) return;

    // Apply min/max constraints if they exist
    if (min !== undefined && newValue < min) return;
    if (max !== undefined && newValue > max) return;

    // Round to appropriate decimal places if decimal mode
    const finalValue = isDecimal ? Math.round(newValue * (1 / step)) / (1 / step) : newValue;

    onChange(finalValue);
  };

  return (
    <div className="flex items-center">
      {label && <label className=" text-sm">{label}</label>}
      <div className="ml-2" style={{ width: `${isDecimal ? "80px" : "70px"} ` }}>
        <Input
          type="number"
          value={value ?? defaultValue}
          onChange={handleChange}
          step={step}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};

export default NumericStepper;
