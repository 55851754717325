import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  chapterPromptsElementsAtom,
  playerChaptersAtom,
  useSelectChaptersPrompt,
} from "../states/chapterState";
import { playerStoriesAtom } from "../states/storyState.tsx";
import { useNavigateWithQueryParams } from "./useNavigateWithQueryParams.ts"; // Assume these types are defined according to your data structure

export function useFetchChapterPrompt(): void {
  const navigate = useNavigateWithQueryParams();
  const stories = useRecoilValue(playerStoriesAtom);
  const chapters = useRecoilValue(playerChaptersAtom);
  const setChaptersPromptElements = useSetRecoilState(chapterPromptsElementsAtom);
  const selectChapterPrompt = useSelectChaptersPrompt();

  useEffect(() => {
    if (stories.length === 0) {
      navigate("/");
      return;
    }

    const fetchPromptElements = async () => {
      console.log("refreshing chapter prompt");
      try {
        const promptElements = await selectChapterPrompt(stories[0].id);
        if (promptElements !== null) {
          setChaptersPromptElements(promptElements);
        }
      } catch (error) {
        console.error("Error fetching chapter prompts:", error);
      }
    };

    fetchPromptElements();
  }, [stories, chapters]);
}
