import React, { useEffect, useState } from "react";
import { DocumentCheckIcon, SparklesIcon } from "@heroicons/react/16/solid";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import { base64ToMp3Blob, uploadToSupabaseData } from "../../../utils/mediaUtil.ts";
import { sanitizeForURL } from "../../../utils/stringUtil.ts";
import StorageFileAudioPlayer from "../StorageFileAudioPlayer.tsx";
import { SOUND_BUCKET_NAME } from "../../../constants/constant.ts";
import SpokableListBox from "../SpokableListBox.tsx";
import { Tables } from "../../../types/database.ts";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { getEnvironment } from "../../../utils/envUtil.ts";

interface previewVoiceProps {
  voiceId: string;
  dialogue: string;
  storyId: string;
  onSoundSelected: (soundUrl: string) => void;
}

const PreviewVoice: React.FC<previewVoiceProps> = ({
  voiceId,
  dialogue,
  storyId,
  onSoundSelected,
}) => {
  const [exampleDialogue, setExampleDialogue] = useState(dialogue || "");
  const { soundOutput, voicePreview, soundStatus } = useGenerateSteps();
  const [uploadedSoundUrl, setUploadedSoundUrl] = useState<string | null>(null);
  const [selectedVoice, setSelectedVoice] = useState<Tables<"blueprint_voices"> | null>(null);
  const [voices, setVoices] = useState<Tables<"blueprint_voices">[] | null>(null);

  useEffect(() => {
    fetchVoices();
  }, []);

  useEffect(() => {
    if (voices && voices.length > 0 && voiceId) {
      const matchingVoice = voices.find((voice) => voice.id === voiceId);
      setSelectedVoice(matchingVoice || null);
    }
  }, [voices, voiceId]);

  const fetchVoices = async () => {
    const { data, error } = await supabase
      .from("blueprint_voices")
      .select("*")
      .eq("environment", getEnvironment())
      .order("created_at", { ascending: true });

    if (error) {
      console.log("Error fetching voices: " + error.message);
    } else {
      setVoices(data);
    }
  };

  useEffect(() => {
    convertAndUploadSound();
  }, [soundOutput]);

  const convertAndUploadSound = async () => {
    if (!soundOutput) return;
    const soundUrl = await uploadToSupabaseData(
      storyId,
      base64ToMp3Blob(soundOutput.base64Audio),
      sanitizeForURL(exampleDialogue.substring(0, 30)) + ".mp3",
    );
    console.log("soundUrl", soundUrl);
    const url = soundUrl?.split("/").pop();
    if (url) setUploadedSoundUrl(url);
  };

  const handleGenerate = async () => {
    await voicePreview({
      dialogue: exampleDialogue,
      key_in_tts_system: selectedVoice?.key_in_tts_system,
      tts_system: selectedVoice?.tts_system,
    });
    setUploadedSoundUrl(null);
  };

  return (
    <div className="flex-grow overflow-y-auto p-6 pt-0">
      <div className="flex items-center space-x-4">
        <div className="flex-grow">
          <AutoResizeTextArea
            value={exampleDialogue}
            onChange={(e) => {
              setExampleDialogue(e);
            }}
          />
        </div>
      </div>
      <div className="flex items-center space-x-4 mt-4">
        {voices && (
          <SpokableListBox
            options={voices.map((voice) => ({
              key: voice.id,
              value: `${voice.name} | ${voice.description} | ${voice.tts_system}`,
            }))}
            value={
              selectedVoice
                ? {
                    key: selectedVoice.id,
                    value: `${selectedVoice.name} | ${selectedVoice.description} | ${selectedVoice.tts_system}`,
                  }
                : {
                    key: voices[0].id,
                    value: `${voices[0].name} | ${voices[0].description} | ${voices[0].tts_system}`,
                  }
            }
            onChange={(option) => {
              const selectedVoiceData = voices.find((voice) => voice.id === option.key);
              setSelectedVoice(selectedVoiceData || null);
            }}
          />
        )}
      </div>
      <SpokableButton onClick={handleGenerate} className="mb-4 mt-4">
        <SparklesIcon className="mr-2" />
        Preview
      </SpokableButton>
      {soundOutput && selectedVoice && uploadedSoundUrl && (
        <SpokableButton
          onClick={() => onSoundSelected(selectedVoice.id)}
          className="mb-4 mt-4 ml-4"
        >
          <DocumentCheckIcon className="mr-2" />
          Save voice setting
        </SpokableButton>
      )}
      {soundStatus && <div>{soundStatus}</div>}
      {soundOutput && uploadedSoundUrl && (
        <div>
          <StorageFileAudioPlayer
            storyId={storyId}
            fileName={uploadedSoundUrl}
            bucketName={SOUND_BUCKET_NAME}
          />
        </div>
      )}
    </div>
  );
};

export default PreviewVoice;
