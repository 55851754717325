import React, { useEffect, useMemo, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { LoadingSpinner } from "../LoadingSpinner";
import DynamicForm from "../DynamicForm";
import { BeatSheet, OnePager, RankedBranchIdea } from "../../../types/fastApiTypes";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { Tables } from "../../../types/database.ts";
import {
  getMergedBeatsByBranchName,
  mergeBeatsAndBeatsheets,
  mergeBeatsAndBeatsheetsByBranch,
} from "../../../utils/valueObject.ts";
import { SpokableButton } from "../SpokableButton.tsx";

export interface GenerateBeatSheetProps {
  beatsheet: BeatSheet | null;
  status: string;
  setBeatsheet: React.Dispatch<React.SetStateAction<BeatSheet | null>>;
  generateBeatsheet: (payload: any) => void;
  selectedIdea: RankedBranchIdea | null;
  onePager: OnePager | null;
  beats: Tables<"blueprint_beats">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  branches: Tables<"blueprint_branches">[];
  characters: Tables<"blueprint_characters">[];
  clickedBranch: Tables<"blueprint_branches"> | null;
  clickedBeatsheet: Tables<"blueprint_beatsheets"> | null;
}

const GenerateBeatSheet: React.FC<GenerateBeatSheetProps> = ({
  beatsheet,
  status,
  setBeatsheet,
  generateBeatsheet,
  onePager,
  selectedIdea,
  beats,
  beatsheets,
  branches,
  characters,
  clickedBeatsheet,
  clickedBranch,
}) => {
  const memoizedBeatSheet = useMemo(() => beatsheet, [beatsheet]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      onePager &&
      characters &&
      beatsheet === null &&
      clickedBeatsheet &&
      selectedIdea &&
      clickedBranch
    ) {
      const mergedBeats = mergeBeatsAndBeatsheetsByBranch(beats, beatsheets, branches);
      const beatsForClickedBranch = getMergedBeatsByBranchName(mergedBeats, clickedBranch.name);

      generateBeatsheet({
        idea: "",
        branch_idea: selectedIdea.idea,
        beats: beatsForClickedBranch,
        one_pager: onePager,
        branches: branches,
        characters: characters,
        clickedBranch: clickedBranch,
        clickedBeatsheet: mergeBeatsAndBeatsheets(beats, [clickedBeatsheet]),
      });
    }
  }, [beatsheet, onePager, generateBeatsheet, setBeatsheet]);

  const handleRegenerate = () => {
    if (onePager) {
      setBeatsheet(null);
    }
  };

  return (
    <>
      <Dialog.Title as="h3" className="font-serif py-6 text-4xl font-bold mb-4">
        BeatSheets
        <SpokableButton color="light" className="ml-2" onClick={handleRegenerate}>
          <ArrowPathIcon />
        </SpokableButton>
        <span className="text-sm ml-2"> {status}</span>
      </Dialog.Title>
      <div className="flex">
        <div className="flex-grow pl-4">
          {status !== "" && <LoadingSpinner />}
          {memoizedBeatSheet && (
            <div ref={scrollContainerRef} className="mt-4 max-h-[calc(90vh-200px)] overflow-y-auto">
              <DynamicForm data={memoizedBeatSheet} setData={setBeatsheet} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GenerateBeatSheet;
