import { useEffect, useState } from "react";

export function useDebouncedValue(value: number, delay: number, instantTarget?: number): number {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (value === instantTarget) {
      // If the value is equal to instantTarget, update the debounced value instantly
      setDebouncedValue(value);
    } else {
      // Otherwise, debounce the transition
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value, delay, instantTarget]);

  return debouncedValue;
}
