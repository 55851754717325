import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalUpscaleImageState } from "../../../states/ModalState.ts";
import { useUpscaleImage } from "./useUpscaleImageHooks.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalUpscaleImageState);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const {
    data: upscaledImage,
    status: imageStatus,
    callApi: upscaleImage,
    setData: setUpscaledImage,
  } = useUpscaleImage();

  const handleClose = () => {
    setIsOpen(false);
    setStepIndex(0);
    setUpscaledImage(null);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    upscaledImage,
    imageStatus,
    upscaleImage,
    setUpscaledImage,

    handleClose,
  };
};
