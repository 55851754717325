export function computeRecommendationPercentage(
  positiveCount: number | null | undefined,
  negativeCount: number | null | undefined,
): number {
  if (!positiveCount || !negativeCount) {
    return 0;
  }

  const totalCount = positiveCount + negativeCount;

  if (totalCount === 0) {
    return 0;
  }

  return (positiveCount / totalCount) * 100;
}

export const formatDuration = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours > 0) {
    if (remainingMinutes > 0) {
      return `${hours}H${remainingMinutes}`;
    } else {
      return `${hours}H`;
    }
  } else {
    return `${minutes} minutes`;
  }
};
