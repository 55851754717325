import { Tag } from "../components/admin/SpokableTagSelector.tsx";

export const ALCOHOL_REFERENCE = "Alcohol Reference";
export const ANIMATED_BLOOD = "Animated Blood";
export const BLOOD = "Blood";
export const BLOOD_AND_GORE = "Blood and Gore";
export const COMIC_MISCHIEF = "Comic Mischief";
export const CRUDE_HUMOR = "Crude Humor";
export const DRUG_REFERENCE = "Drug Reference";
export const FANTASY_VIOLENCE = "Fantasy Violence";
export const GAMBLING_THEMES = "Gambling Themes";
export const INTENSE_VIOLENCE = "Intense Violence";
export const LANGUAGE = "Language";
export const LYRICS = "Lyrics";
export const MATURE_HUMOR = "Mature Humor";
export const NUDITY = "Nudity";
export const PARTIAL_NUDITY = "Partial Nudity";
export const REAL_GAMBLING = "Real Gambling";
export const SEXUAL_CONTENT = "Sexual Content";
export const SEXUAL_THEMES = "Sexual Themes";
export const SEXUAL_VIOLENCE = "Sexual Violence";
export const SIMULATED_GAMBLING = "Simulated Gambling";
export const STRONG_LANGUAGE = "Strong Language";
export const STRONG_LYRICS = "Strong Lyrics";
export const STRONG_SEXUAL_CONTENT = "Strong Sexual Content";
export const SUGGESTIVE_THEMES = "Suggestive Themes";
export const TOBACCO_REFERENCE = "Tobacco Reference";
export const USE_OF_ALCOHOL = "Use of Alcohol";
export const USE_OF_DRUGS = "Use of Drugs";
export const USE_OF_TOBACCO = "Use of Tobacco";
export const VIOLENCE = "Violence";
export const VIOLENT_REFERENCES = "Violent References";

// List of all content descriptors
export const contentDescriptors: string[] = [
  ALCOHOL_REFERENCE,
  ANIMATED_BLOOD,
  BLOOD,
  BLOOD_AND_GORE,
  COMIC_MISCHIEF,
  CRUDE_HUMOR,
  DRUG_REFERENCE,
  FANTASY_VIOLENCE,
  GAMBLING_THEMES,
  INTENSE_VIOLENCE,
  LANGUAGE,
  LYRICS,
  MATURE_HUMOR,
  NUDITY,
  PARTIAL_NUDITY,
  REAL_GAMBLING,
  SEXUAL_CONTENT,
  SEXUAL_THEMES,
  SEXUAL_VIOLENCE,
  SIMULATED_GAMBLING,
  STRONG_LANGUAGE,
  STRONG_LYRICS,
  STRONG_SEXUAL_CONTENT,
  SUGGESTIVE_THEMES,
  TOBACCO_REFERENCE,
  USE_OF_ALCOHOL,
  USE_OF_DRUGS,
  USE_OF_TOBACCO,
  VIOLENCE,
  VIOLENT_REFERENCES,
];

export const ESBR_TAGS: Tag[] = contentDescriptors.map((descriptor) => ({
  key: descriptor,
  value: descriptor,
}));

export const ESRB_10 = "Everyone 10+";
export const ESRB_ADULT = "Adult Only 18+";
export const ESRB_EVERYONE = "Everyone";
export const ESRB_MATURE = "Mature 17+";
export const ESRB_PENDING = "Rating Pending";
export const ESRB_Teen = "Teen";

export const SVG_ESRB_10 = "esrb_10.svg";
export const SVG_ESRB_ADULT = "esrb_adult.svg";
export const SVG_ESRB_EVERYONE = "esrb_everyone.svg";
export const SVG_ESRB_MATURE = "esrb_mature.svg";
export const SVG_ESRB_PENDING = "esrb_pending.svg";
export const SVG_ESRB_TEEN = "esrb_teen.svg";

export const KEY_ESRB_10 = "ESRB_10";
export const KEY_ESRB_ADULT = "ESRB_ADULT";
export const KEY_ESRB_EVERYONE = "ESRB_EVERYONE";
export const KEY_ESRB_MATURE = "ESRB_MATURE";
export const KEY_ESRB_PENDING = "ESRB_PENDING";
export const KEY_ESRB_TEEN = "ESRB_Teen";

export function getRatingValue(key: string): string | undefined {
  const rating = ESBR_RATINGS.find((rating) => rating.key === key);
  return rating ? rating.value : undefined;
}

export const ESBR_RATINGS = [
  { key: KEY_ESRB_10, value: ESRB_10 },
  { key: KEY_ESRB_ADULT, value: ESRB_ADULT },
  { key: KEY_ESRB_EVERYONE, value: ESRB_EVERYONE },
  { key: KEY_ESRB_MATURE, value: ESRB_MATURE },
  { key: KEY_ESRB_PENDING, value: ESRB_PENDING },
  { key: KEY_ESRB_TEEN, value: ESRB_Teen },
];

// Define a type for the keys
export type ESRBKey =
  | typeof KEY_ESRB_10
  | typeof KEY_ESRB_ADULT
  | typeof KEY_ESRB_EVERYONE
  | typeof KEY_ESRB_MATURE
  | typeof KEY_ESRB_PENDING
  | typeof KEY_ESRB_TEEN;

// Map the keys to the SVG paths
const svgPathMap: Record<ESRBKey, string> = {
  [KEY_ESRB_10]: SVG_ESRB_10,
  [KEY_ESRB_ADULT]: SVG_ESRB_ADULT,
  [KEY_ESRB_EVERYONE]: SVG_ESRB_EVERYONE,
  [KEY_ESRB_MATURE]: SVG_ESRB_MATURE,
  [KEY_ESRB_PENDING]: SVG_ESRB_PENDING,
  [KEY_ESRB_TEEN]: SVG_ESRB_TEEN,
};

// Function to get the SVG path based on the key
export function getRatingSvgPath(key: ESRBKey): string {
  return svgPathMap[key];
}
