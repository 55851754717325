import React, { useEffect, useMemo, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { LoadingSpinner } from "../LoadingSpinner";
import DynamicForm from "../DynamicForm";
import { BeatSheet, CharactersSummary, OnePager } from "../../../types/fastApiTypes";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { SpokableButton } from "../SpokableButton.tsx";

export interface GenerateBeatSheetProps {
  beatSheet: BeatSheet | null;
  status: string;
  setBeatSheet: React.Dispatch<React.SetStateAction<BeatSheet | null>>;
  generateBeatSheet: (payload: any) => void;
  onePager: OnePager | null;
  charactersSummary: CharactersSummary | null;
  audience: string;
}

const GenerateBeatSheet: React.FC<GenerateBeatSheetProps> = ({
  beatSheet,
  status,
  setBeatSheet,
  generateBeatSheet,
  onePager,
  charactersSummary,
  audience,
}) => {
  const memoizedBeatSheet = useMemo(() => beatSheet, [beatSheet]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (onePager && charactersSummary && beatSheet === null) {
      generateBeatSheet({
        one_pager: onePager || "",
        characters_summary: charactersSummary,
        audience: audience,
      });
    }
  }, [beatSheet, onePager, generateBeatSheet, setBeatSheet]);

  const handleRegenerate = () => {
    if (onePager && charactersSummary) {
      setBeatSheet(null);
    }
  };

  return (
    <div className=" px-8 py-6">
      <Dialog.Title as="h3" className="font-serif text-4xl font-bold mb-4">
        BeatSheets
        <SpokableButton
          color="light"
          isIconButton={true}
          className="ml-2"
          onClick={handleRegenerate}
        >
          <ArrowPathIcon />
        </SpokableButton>
        <span className="text-sm ml-2"> {status}</span>
      </Dialog.Title>
      <div className="flex">
        <div className="flex-grow">
          {status !== "" && <LoadingSpinner />}
          {memoizedBeatSheet && (
            <div ref={scrollContainerRef} className="mt-4 max-h-[calc(90vh-300px)] overflow-y-auto">
              <DynamicForm data={memoizedBeatSheet} setData={setBeatSheet} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerateBeatSheet;
