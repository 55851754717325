import { useRef, useState } from "react";

export const useCamera = () => {
  const [recording, setRecording] = useState(false);
  const [isCameraStarted, setIsCameraStarted] = useState(false);
  const [rawRecordedVideoUrl, setRawRecordedVideoUrl] = useState("");
  const rawHTMLVideoElementRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);

  const startCamera = async () => {
    try {
      // Always request 1:1 aspect ratio
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          aspectRatio: 1,
          width: { ideal: 960, max: 1280 }, // Reduced ideal size
          height: { ideal: 960, max: 1280 }, // But allow up to 1280 if needed
          frameRate: { ideal: 30, max: 30 }, // Explicit frame rate control
        },
      });

      if (rawHTMLVideoElementRef.current) {
        rawHTMLVideoElementRef.current.srcObject = stream;
        setIsCameraStarted(true);
      }
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  };

  const startRecording = () => {
    if (!rawHTMLVideoElementRef.current?.srcObject) return;

    chunksRef.current = [];
    const mediaRecorder = new MediaRecorder(
      rawHTMLVideoElementRef.current.srcObject as MediaStream,
      {
        mimeType: "video/webm;codecs=h264",
        videoBitsPerSecond: 2500000, // 5Mbps
      },
    );

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunksRef.current.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(chunksRef.current, { type: mediaRecorder.mimeType });
      if (rawRecordedVideoUrl) {
        URL.revokeObjectURL(rawRecordedVideoUrl);
      }
      const url = URL.createObjectURL(blob);
      setRawRecordedVideoUrl(url);
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
    setRecording(true);
  };

  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setRecording(false);
  };

  const stopCamera = () => {
    const stream = rawHTMLVideoElementRef.current?.srcObject as MediaStream;
    stream?.getTracks().forEach((track) => track.stop());
    if (rawHTMLVideoElementRef.current) {
      rawHTMLVideoElementRef.current.srcObject = null;
    }
    setIsCameraStarted(false);
  };

  const getRecordedBlob = () => {
    if (chunksRef.current.length === 0) return null;
    return new Blob(chunksRef.current, {
      type: mediaRecorderRef.current?.mimeType || "video/webm",
    });
  };

  return {
    recording,
    isCameraStarted,
    rawRecordedVideoUrl,
    rawHTMLVideoElementRef,
    startCamera,
    stopCamera,
    startRecording,
    stopRecording,
    getRecordedBlob,
  };
};
