import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { popupOpenState } from "../../states/ModalState.ts";

interface OverlayPanelProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  width?: string;
}

const OverlayPanel: React.FC<OverlayPanelProps> = ({
  children,
  isOpen,
  onClose,
  width = "w-1/2",
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const panelRef = useRef<HTMLDivElement>(null);
  const isPopupOpen = useRecoilValue(popupOpenState);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (panelRef.current && !panelRef.current.contains(event.target as Node) && !isPopupOpen) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose, isPopupOpen]);

  if (!isVisible && !isOpen) return null;

  return (
    <div
      className={`z-50 fixed inset-0 bg-black transition-opacity duration-300 ease-in-out ${isOpen ? "bg-opacity-50" : "bg-opacity-0"} ${isVisible ? "visible" : "invisible"}`}
    >
      <div
        ref={panelRef}
        className={`fixed top-0 right-0 h-full bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${width} ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } flex flex-col`}
      >
        <div className="flex-grow overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default OverlayPanel;
