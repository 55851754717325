import React, { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useSetRecoilState } from "recoil";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import { popupOpenState } from "@/states/ModalState.ts";

interface ModalButtonProps {
  children: React.ReactNode;
  buttonLabel: string;
  modalTitle?: string | null;
  className?: string;
  trigger?: React.ReactNode;
  icon: React.ReactNode | null;
  buttonStyleLight?: boolean;
  onClose?: () => void;
}

const ModalButton = ({
  children,
  buttonLabel,
  modalTitle = null,
  className = "",
  trigger,
  icon = null,
  buttonStyleLight = false,
  onClose,
}: ModalButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const setPopupOpen = useSetRecoilState(popupOpenState);

  const closePopup = () => {
    setIsOpen(false);
    setPopupOpen(false);
    if (onClose) onClose();
  };

  return (
    <>
      {trigger ? (
        <span
          onClick={() => {
            setIsOpen(true);
            setPopupOpen(true);
          }}
        >
          {trigger}
        </span>
      ) : (
        <SpokableButton
          color={buttonStyleLight ? "light" : "dark/zinc"}
          onClick={() => {
            setIsOpen(true);
            setPopupOpen(true);
          }}
        >
          {icon} {buttonLabel}
        </SpokableButton>
      )}

      <Dialog
        open={isOpen}
        onClose={() => {
          closePopup();
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel
            className={`${className} w-4/5 max-h-[90vh] transform rounded-md bg-white shadow-xl transition-all flex flex-col`}
          >
            {modalTitle && (
              <DialogTitle className="font-serif p-6 text-4xl font-bold mb-4 flex-none">
                {modalTitle}
              </DialogTitle>
            )}
            <div className="flex-1 overflow-auto">
              {React.cloneElement(children as React.ReactElement, {
                onClose: closePopup,
              })}
            </div>
            <div className="p-6 bg-gray-50 border-t flex-none">
              <div className="flex justify-between items-center">
                <SpokableButton onClick={() => closePopup()} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default ModalButton;
