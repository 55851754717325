// ReviewIdea.tsx
import { Dialog } from "@headlessui/react";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import React from "react";
import { RankedBranchIdea } from "../../../types/fastApiTypes.ts";
import FormField from "../FormField.tsx";

export interface ReviewIdeaProps {
  selectedIdea: RankedBranchIdea | null;
  setSelectedIdea: React.Dispatch<React.SetStateAction<RankedBranchIdea | null>>;
}

const ReviewBranchIdea: React.FC<ReviewIdeaProps> = ({ selectedIdea, setSelectedIdea }) => {
  return (
    <>
      <Dialog.Title as="h3" className="mb-2 text-lg font-medium leading-6 text-gray-900 mb-8">
        Review best idea
      </Dialog.Title>
      <FormField label="Description">
        <AutoResizeTextArea
          value={selectedIdea?.idea.branch_description || ""}
          onChange={(value) =>
            setSelectedIdea((prevState) => {
              if (prevState === null) return null;
              return {
                ...prevState,
                idea: {
                  ...prevState.idea,
                  branch_description: value,
                },
              };
            })
          }
        />
      </FormField>
      <FormField label="Decision">
        <AutoResizeTextArea
          className="mt-2"
          value={selectedIdea?.idea.divergence_point || ""}
          onChange={(value) =>
            setSelectedIdea((prevState) => {
              if (prevState === null) return null;
              return {
                ...prevState,
                idea: {
                  ...prevState.idea,
                  divergence_point: value,
                },
              };
            })
          }
        />
      </FormField>
    </>
  );
};

export default ReviewBranchIdea;
