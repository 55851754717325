import React, { useState } from "react";
import clsx from "clsx";

interface PersonaButtonProps {
  onClick: () => void;
  label: string;
  className?: string;
}

const PersonaButton: React.FC<PersonaButtonProps> = ({ onClick, label, className }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onClick={onClick}
      className={clsx(
        "cursor-pointer text-base leading-10 max-w-[160px] w-full tracking-[0.3rem] font-semibold relative no-underline uppercase flex justify-center transition-all duration-1000 px-5",
        isHovered ? "text-[#E5FF76]" : "text-white",
        className,
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg
        style={{
          height: "40px",
          left: 0,
          top: 0,
          position: "absolute",
          width: "100%",
        }}
      >
        <rect
          x="0"
          y="0"
          fill="none"
          width="100%"
          height="100%"
          style={{
            fill: "none",
            stroke: isHovered ? "#E5FF76" : "#fff",
            // strokeWidth: isHovered ? 5 : 2,
            strokeDasharray: isHovered ? "20, 300" : "450, 0",
            strokeDashoffset: 48,
            transition: "all 2s cubic-bezier(0.22, 1, 0.25, 1)",
          }}
        />
      </svg>
      {label}
    </div>
  );
};

export default PersonaButton;
