import React, { useState } from "react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import NumericStepper from "../NumericStepper.tsx";
import ImageGenerationStyleSelector from "../ImageGenerationStyleSelector.tsx";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import { downloadImageFromSupabase } from "../../../utils/mediaUtil.ts";
import AnimatedStatus from "../AnimatedStatus.tsx";

interface GenerateBaseImageProps {
  initialPrompt: string | null;
  initialPromptModifier: string | null;
  onImageSelected: (imageUrl: string) => void;
  imageReferenceFileName?: string | null;
  storyId: string;
}

const GenerateBaseImage: React.FC<GenerateBaseImageProps> = ({
  initialPrompt,
  initialPromptModifier,
  onImageSelected,
  storyId,
  imageReferenceFileName = null,
}) => {
  const [prompt, setPrompt] = useState(initialPrompt || "");
  const [promptModifier, setPromptModifier] = useState(initialPromptModifier || "");
  const [numberOfImages, setNumberOfImages] = useState(1);
  const [styleStrength, setStyleStrength] = useState(0.83);
  const [model, setModel] = useState<string | null>(null);
  const { generateImages, imageStatus, images } = useGenerateSteps();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const finalImagePrompt = promptModifier.replace("{prompt}", prompt);

  const handleGenerate = async () => {
    try {
      const baseParams = {
        image_generation_prompt: finalImagePrompt,
        number_of_images: numberOfImages,
      };

      if (imageReferenceFileName) {
        const imageUrl = `${storyId}/${imageReferenceFileName}`;
        const image = await downloadImageFromSupabase(imageUrl);

        if (!image) {
          console.error("Failed to download image from Supabase");
          return;
        }

        const file = new File([image], imageUrl, {
          type: "image/jpeg",
        });

        // Convert file to base64 using a Promise
        const base64Content = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64 = reader.result?.toString().split(",")[1];
            resolve(base64);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        });

        Object.assign(baseParams, {
          image_ref_file_name: imageReferenceFileName,
          image_ref_base_64: base64Content,
          style_strength: styleStrength,
        });
      }

      const modelParam = model ? { model } : {};

      await generateImages({
        ...baseParams,
        ...modelParam,
      });

      setSelectedImage(null); // Reset selected image when generating new ones
    } catch (error) {
      console.error("Error in handleGenerate:", error);
      throw error; // Re-throw to be handled by caller if needed
    }
  };

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    onImageSelected(image);
  };

  return (
    <div className="flex-grow overflow-y-auto p-6 pt-0">
      <AutoResizeTextArea className="mb-4" value={prompt} onChange={(e) => setPrompt(e)} />
      <div className="w-full">
        <div className="flex flex-col lg:flex-row lg:space-x-6 space-y-6 lg:space-y-0">
          <div className="w-full lg:w-1/2">
            <ImageGenerationStyleSelector
              promptStyle={promptModifier}
              onPromptStyleSelected={(promptStyle, selectedModel) => {
                setPromptModifier(promptStyle);
                setModel(selectedModel || null);
              }}
            />
          </div>

          {/* Steppers column - takes other half width on large screens */}
          <div className="w-full lg:w-1/2 flex flex-col space-y-6">
            <NumericStepper
              label="Number of images"
              value={numberOfImages}
              onChange={(value) => setNumberOfImages(value)}
            />
            <NumericStepper
              label="Style strength&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
              value={styleStrength}
              isDecimal={true}
              max={1}
              min={0}
              step={0.01}
              onChange={(value) => setStyleStrength(value)}
            />
          </div>
        </div>
      </div>
      <SpokableButton onClick={handleGenerate} className="mb-4 mt-4">
        <SparklesIcon className="mr-2" />
        Generate Images
      </SpokableButton>
      <AnimatedStatus status={imageStatus} />
      {images && (
        <div className="grid grid-cols-2 gap-4 mb-4">
          {images.image_urls.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Generated ${index + 1}`}
              className={`w-full h-auto cursor-pointer ${
                selectedImage === image ? "border-4 border-black" : ""
              }`}
              onClick={() => handleImageClick(image)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default GenerateBaseImage;
