type FilterOptions = {
  filterIds?: boolean;
  filterDates?: boolean;
  filterNulls?: boolean;
};

const isISODateString = (value: string): boolean => {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+(\+|-)\d{2}:\d{2}$/;
  return isoDateRegex.test(value);
};

const isDate = (value: unknown): boolean => {
  if (value instanceof Date) return true;
  if (typeof value === "string" && isISODateString(value)) return true;
  return false;
};

const shouldKeepField = (key: string, value: unknown, options: FilterOptions): boolean => {
  const { filterIds = false, filterDates = false, filterNulls = false } = options;

  if (!filterIds && !filterDates && !filterNulls) return true;
  if (filterIds && key.toLowerCase().includes("id")) return false;
  if (filterDates && isDate(value)) return false;
  if (filterNulls && value === null) return false;
  return true;
};

type FilterResult<T> =
  T extends Array<infer U>
    ? Array<U extends object ? FilterResult<U> : U>
    : T extends object
      ? { [K in keyof T]?: FilterResult<T[K]> }
      : T;

export function filterObject<T>(
  obj: T,
  options: FilterOptions = {
    filterIds: true,
    filterDates: true,
    filterNulls: true,
  },
): FilterResult<T> {
  if (Array.isArray(obj)) {
    return obj.map((item) =>
      typeof item === "object" && item !== null ? filterObject(item, options) : item,
    ) as FilterResult<T>;
  }

  if (typeof obj !== "object" || obj === null) {
    return obj as FilterResult<T>;
  }

  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      // Skip this field if it should be filtered out
      if (!shouldKeepField(key, value, options)) {
        return acc;
      }

      // If the value is null and we're filtering nulls, skip it
      if (options.filterNulls && value === null) {
        return acc;
      }

      if (typeof value === "object" && value !== null) {
        const filteredValue = filterObject(value, options);
        // Only add non-null objects that have at least one property
        if (
          filteredValue !== null &&
          (Array.isArray(filteredValue)
            ? filteredValue.length > 0
            : Object.keys(filteredValue).length > 0)
        ) {
          (acc as any)[key] = filteredValue;
        }
      } else {
        (acc as any)[key] = value;
      }

      return acc;
    },
    {} as { [K in keyof T]?: FilterResult<T[K]> },
  ) as FilterResult<T>;
}

export function prepareObjectForPrompt<T>(obj: T): string {
  return JSON.stringify(filterObject(obj));
}
