import { Tables } from "../../../types/promptManagementDatabase.ts";
import FormField from "../FormField.tsx";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import DynamicForm from "../DynamicForm.tsx";
import ResponseViewer from "./ResponseViewer.tsx";
import { Divider } from "../../catalyst/divider.tsx";

interface ExecutionLogsRendererProps {
  executionLogs: Tables<{ schema: "prompt_management" }, "prompt_execution_details_view">;
  readonly?: boolean;
  className?: string;
}

interface SchemaField {
  field_name: string;
  description: string;
}

interface InputOrOutputSchema {
  schema_name: string;
  fields: SchemaField[];
}

export const ExecutionLogsRenderer: React.FC<ExecutionLogsRendererProps> = ({
  className,
  executionLogs,
  readonly = true,
}) => {
  return (
    <div className={`${className}`}>
      <FormField label="Response" isSerif={true}>
        {executionLogs.output_schema && executionLogs.response && (
          <div className="max-w-3xl mr-auto">
            <ResponseViewer
              schema={executionLogs.output_schema as unknown as InputOrOutputSchema}
              response={executionLogs.response}
            />
          </div>
        )}
      </FormField>
      <Divider className="mt-16" />
      <FormField label="Input" divider={true} isSerif={true}>
        <div className="max-w-3xl mr-auto">
          <ResponseViewer
            schema={executionLogs.input_schema as unknown as InputOrOutputSchema}
            response={JSON.stringify(executionLogs.prompt_variable_inputs)}
          />
        </div>
      </FormField>
      <FormField label="General instruction" divider={true} isSerif={true}>
        <AutoResizeTextArea
          value={executionLogs.general_instructions || ""}
          onChange={(value) => console.log(value)}
          readonly={readonly}
          className="max-w-3xl mr-auto"
        />
      </FormField>
      <FormField label="Input schema" divider={true} isSerif={true}>
        {(executionLogs.input_schema as unknown as InputOrOutputSchema).fields.map(
          (field, index) => (
            <DynamicForm
              key={field.field_name}
              data={field}
              index={index}
              setData={(value) => console.log(value)}
              readonly={readonly}
              minNumberOfRows={1}
            />
          ),
        )}
      </FormField>
      <FormField label="Ouput schema" divider={true} isSerif={true}>
        {(executionLogs.output_schema as unknown as InputOrOutputSchema).fields.map(
          (field, index) => (
            <DynamicForm
              key={field.field_name}
              data={field}
              index={index}
              setData={(value) => console.log(value)}
              readonly={readonly}
              minNumberOfRows={1}
            />
          ),
        )}
      </FormField>
    </div>
  );
};
