// ReviewIdea.tsx
import { Dialog } from "@headlessui/react";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import React from "react";

export interface ReviewIdeaProps {
  selectedIdea: string | null;
  setSelectedIdea: React.Dispatch<React.SetStateAction<string | null>>;
}

const ReviewIdea: React.FC<ReviewIdeaProps> = ({ selectedIdea, setSelectedIdea }) => {
  return (
    <div className="px-8 pb-6">
      <Dialog.Title as="h3" className="font-serif py-6 text-4xl font-bold mb-4">
        Review best idea
      </Dialog.Title>
      <AutoResizeTextArea
        className="max-w-prose"
        value={selectedIdea || ""}
        onChange={(value) => setSelectedIdea(value)}
      />
    </div>
  );
};

export default ReviewIdea;
