import React from "react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import {
  downloadImageFromSupabase,
  getSupabaseImageUrl,
  uploadToSupabaseData,
} from "../../../utils/mediaUtil.ts";
import { IMAGE_BUCKET_NAME } from "../../../constants/constant.ts";
import ImageComparisonSlider from "../../ImageComparisonSlider.tsx";

interface UpscaleImageProps {
  oldImageUrl: string;
  storyId: string;
  onImageSelected: (upscaledImageUrl: string) => void;
}

const UpscaleImage: React.FC<UpscaleImageProps> = ({ oldImageUrl, storyId, onImageSelected }) => {
  const { upscaleImage, upscaledImage, imageStatus } = useGenerateSteps();

  const uploadImage = async () => {
    if (!upscaledImage) return;
    const response = await fetch(upscaledImage.image_urls[0]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    if (!blob) return;
    const imageUrl = await uploadToSupabaseData(
      storyId,
      blob,
      oldImageUrl,
      IMAGE_BUCKET_NAME,
      false,
    );

    const url = imageUrl?.split("/").pop();
    if (url) onImageSelected(url);
  };

  const handleGenerate = async () => {
    const imageUrl = storyId + "/" + oldImageUrl;
    const image = await downloadImageFromSupabase(imageUrl || "");
    if (!image) return;
    const file = new File([image], oldImageUrl, {
      type: "image/jpeg",
    });

    // Convert file to base64
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const base64Content = reader.result?.toString().split(",")[1];
      if (base64Content) {
        await upscaleImage({
          filename: file.name,
          file_content: base64Content,
        });
      }
    };
  };

  return (
    <div className="flex-grow overflow-y-auto p-6 pt-0">
      {!upscaledImage && (
        <SpokableButton onClick={handleGenerate} className="mb-4 mt-4">
          <SparklesIcon className="mr-2" />
          launch
        </SpokableButton>
      )}
      {imageStatus && <div>{imageStatus}</div>}
      {upscaledImage && (
        <>
          <SpokableButton onClick={() => uploadImage()} className="mb-4 mt-4">
            <SparklesIcon className="mr-2" />
            Save
          </SpokableButton>
          <ImageComparisonSlider
            beforeImage={getSupabaseImageUrl(storyId, oldImageUrl) || ""}
            afterImage={upscaledImage.image_urls[0]}
          />
        </>
      )}
    </div>
  );
};

export default UpscaleImage;
