import ModalButton from "@/components/admin/ModalButton.tsx";
import LogsViewer from "@/components/admin/promptManagement/LogsViewer.tsx";
import { CircleStackIcon } from "@heroicons/react/16/solid";
import { useDatasetModalButtonDatabase } from "@/components/admin/promptManagement/database/useDatasetModalButtonDatabase.ts";
import { useEffect } from "react";
import { DatabaseTypes } from "@/components/admin/promptManagement/database/databaseOperations.ts";

interface ViewDatasetButtonProps {
  promptId: string;
  evalId: string;
  onClose: () => void;
}

export default function DatasetModalButton({ promptId, evalId, onClose }: ViewDatasetButtonProps) {
  const { isExecutionIdInEvalDatasetMap, handleEvalDatasetChange, fetchEvalDatasets } =
    useDatasetModalButtonDatabase(promptId, evalId);

  useEffect(() => {
    fetchEvalDatasets();
  }, [promptId]);

  const handleCheckBoxChange = async (
    log: DatabaseTypes["PromptExecutionDetails"],
    isChecked: boolean,
  ) => {
    handleEvalDatasetChange(log, promptId, evalId, isChecked);
  };

  return (
    <ModalButton
      buttonLabel={"Dataset"}
      icon={<CircleStackIcon />}
      buttonStyleLight={true}
      onClose={onClose}
    >
      <LogsViewer
        promptId={promptId}
        isUsedToDefineDataset={true}
        evalDatasets={isExecutionIdInEvalDatasetMap}
        handleEvalDatasetsUpdate={handleCheckBoxChange}
      />
    </ModalButton>
  );
}
