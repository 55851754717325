import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { supabase } from "./vendor/supabaseClient.ts";
import AuthScreen from "./pages/AuthScreen.tsx";
import { WebSocketProvider } from "./contexts/WebSocketProvider.tsx";
import "./App.css";
import LaunchScreen from "./pages/LaunchScreen.tsx";
import GameScreen from "./pages/GameScreen.tsx";
import StoriesList from "./pages/admin/StoriesList.tsx";
import StoryDetail from "./pages/admin/StoryDetail.tsx";
import ChapterDetail from "./pages/admin/ChapterDetail.tsx";
import CharacterDetail from "./pages/admin/CharacterDetail.tsx";
import TransitionMap from "./pages/admin/ChapterGraph.tsx";
import TransitionDetail from "./pages/admin/TransitionDetail.tsx";
import { ReactFlowProvider } from "reactflow";
import ContextDetail from "./pages/admin/ContextDetail.tsx";
import Catalogue from "./pages/Catalogue.tsx";
import ContextList from "./pages/admin/ContextList.tsx";
import CharacterList from "./pages/admin/CharacterList.tsx";
import BeatsDetail from "./pages/admin/BeatsDetail.tsx";
import PromptsList from "./pages/admin/promptManagement/PromptsList.tsx";
import PromptLogs from "./pages/admin/promptManagement/PromptLogs.tsx";
import EvalDetail from "./pages/admin/promptManagement/EvalDetail.tsx";
import EvalsList from "./pages/admin/promptManagement/EvalsList.tsx";
import EvalRun from "./pages/admin/promptManagement/EvalRun.tsx";

const App: React.FC = () => {
  const [session, setSession] = useState<any | null>(null);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false); // Set loading to false after session is loaded
    });

    const { data: subscription } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setLoading(false); // Also set loading to false when auth state changes
    });

    return () => {
      if (subscription && subscription.subscription) {
        subscription.subscription.unsubscribe();
      }
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading while session info is being fetched
  }

  return (
    <div>
      <Router>
        <RecoilRoot>
          <ReactFlowProvider>
            <WebSocketProvider>
              <Routes>
                <Route
                  path="/"
                  element={!session ? <Navigate to="/login" /> : <Navigate to="/browse" />}
                />
                <Route path="/login" element={<AuthScreen />} />
                <Route path="/browse" element={<Catalogue />} />
                <Route
                  path="/launch/:storyId"
                  element={session ? <LaunchScreen /> : <Navigate to="/login" />}
                />
                <Route path="/game" element={session ? <GameScreen /> : <Navigate to="/login" />} />
                {/* admin */}
                <Route
                  path="/admin"
                  element={session ? <StoriesList /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/stories/:storyId"
                  element={session ? <StoryDetail /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/stories/:storyId/beats"
                  element={session ? <BeatsDetail /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/chapters/:chapterId"
                  element={session ? <ChapterDetail /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/stories/:storyId/characters"
                  element={session ? <CharacterList /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/characters/:characterId"
                  element={session ? <CharacterDetail /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/stories/:storyId/transitions"
                  element={session ? <TransitionMap /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/transitions/:transitionId"
                  element={session ? <TransitionDetail /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/stories/:storyId/contexts"
                  element={session ? <ContextList /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/contexts/:storyId/:contextId"
                  element={session ? <ContextDetail /> : <Navigate to="/login" />}
                />
                {/* Prompt management */}
                <Route
                  path="/admin/prompts"
                  element={session ? <PromptsList /> : <Navigate to="/login" />}
                />{" "}
                <Route
                  path="/admin/prompts/:promptId/logs"
                  element={session ? <PromptLogs /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/prompts/:promptId/evals/:evalId"
                  element={session ? <EvalDetail /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/prompts/:promptId/evals"
                  element={session ? <EvalsList /> : <Navigate to="/login" />}
                />
                <Route
                  path="/admin/prompts/:promptId/evals/:evalId/run"
                  element={session ? <EvalRun /> : <Navigate to="/login" />}
                />
              </Routes>
            </WebSocketProvider>
          </ReactFlowProvider>
        </RecoilRoot>
      </Router>
    </div>
  );
};

export default App;
