import React, { useEffect, useMemo, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { LoadingSpinner } from "../LoadingSpinner";
import DynamicForm from "../DynamicForm";
import { Divider } from "../../catalyst/divider";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { Chapters } from "../../../types/fastApiTypes";
import { Tables } from "../../../types/database.ts";
import { SpokableButton } from "../SpokableButton.tsx";

export interface GenerateChapterProps {
  scene: Tables<"blueprint_scenes"> | null;
  status: string;
  chapters: Chapters | null;
  setChapters: React.Dispatch<React.SetStateAction<Chapters | null>>;
  generateChapters: (payload: any) => void;
  characters: Tables<"blueprint_characters">[];
  audience: string;
}

const GenerateChaptersIdeas: React.FC<GenerateChapterProps> = ({
  status,
  scene,
  setChapters,
  generateChapters,
  chapters,
  characters,
  audience,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const memoizedScenes = useMemo(() => chapters, [chapters]);

  useEffect(() => {
    if (chapters === null && status === "" && scene) {
      generateChapters({
        characters: characters,
        scene: scene,
        audience: audience,
      });
    }
  }, [chapters, characters, scene]);

  const handleRegenerate = () => {
    setChapters(null);
  };

  const scrollToCharacterSheet = (index: number) => {
    if (scrollContainerRef.current) {
      const element = scrollContainerRef.current.querySelector(`#character-sheet-${index}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  return (
    <>
      <Dialog.Title as="h3" className="font-serif py-6 text-4xl font-bold mb-4">
        Chapters
        <SpokableButton
          color="light"
          isIconButton={true}
          className="ml-2"
          onClick={handleRegenerate}
        >
          <ArrowPathIcon />
        </SpokableButton>
        <span className="text-sm ml-2"> {status}</span>
      </Dialog.Title>
      <div className="flex">
        <div className="w-48 pr-4 border-r border-gray-200">
          {memoizedScenes && (
            <ul className="space-y-1 mt-6">
              {memoizedScenes.chapters.map((chapter, index) => (
                <li key={index}>
                  <button
                    onClick={() => scrollToCharacterSheet(index)}
                    className="w-full text-left text-sm text-zinc-600 hover:text-zinc-800 p-1 focus:text-zinc-800 focus:font-semibold"
                  >
                    {chapter.chapter_name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="flex-grow pl-4">
          {status !== "" && <LoadingSpinner />}
          {memoizedScenes && (
            <div ref={scrollContainerRef} className="mt-4 max-h-[calc(90vh-200px)] overflow-y-auto">
              {memoizedScenes.chapters.map((chapter, index) => (
                <div id={`character-sheet-${index}`} key={index}>
                  <DynamicForm data={chapter} index={index} setData={setChapters} />
                  <Divider className="mb-4" />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GenerateChaptersIdeas;
