import React, { useEffect, useMemo } from "react";
import { Dialog } from "@headlessui/react";
import { LoadingSpinner } from "../LoadingSpinner";
import AutoResizeTextArea from "../AutoResizeTextArea";
import { LogLine } from "../../../types/fastApiTypes";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { SpokableButton } from "../SpokableButton.tsx";

export interface GenerateLogLineProps {
  logLine: LogLine | null;
  status: string;
  setLogLine: React.Dispatch<React.SetStateAction<LogLine | null>>;
  generateLogLine: (payload: any) => void;
  selectedIdea: string | null;
}

const GenerateLogLine: React.FC<GenerateLogLineProps> = ({
  logLine,
  status,
  setLogLine,
  generateLogLine,
  selectedIdea,
}) => {
  const memoizedLogLine = useMemo(() => logLine, [logLine]);

  useEffect(() => {
    if (logLine === null && selectedIdea && status === "") {
      generateLogLine({ idea: selectedIdea || "" });
    }
  }, [logLine, status, generateLogLine, selectedIdea]);

  const handleRegenerate = () => {
    setLogLine(null);
  };

  return (
    <div className="px-8 pb-6">
      <Dialog.Title as="h3" className="font-serif py-6 text-4xl font-bold mb-4">
        Log line
        <SpokableButton
          isIconButton={true}
          color="light"
          className="ml-2"
          onClick={handleRegenerate}
        >
          <ArrowPathIcon className="h-4 w-4" />
        </SpokableButton>
        <span className="text-sm ml-2"> {status}</span>
      </Dialog.Title>
      {status !== "" && <LoadingSpinner />}
      {memoizedLogLine && (
        <AutoResizeTextArea
          className="max-w-prose"
          value={memoizedLogLine.log_line || ""}
          onChange={(value) => setLogLine({ ...memoizedLogLine, log_line: value })}
        />
      )}
    </div>
  );
};

export default GenerateLogLine;
