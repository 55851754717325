import React from "react";
import { LoadingSpinner } from "./LoadingSpinner.tsx";

interface AnimatedStatusProps {
  status: string;
}

const AnimatedStatus: React.FC<AnimatedStatusProps> = ({ status }) => {
  if (!status) return null;

  return (
    <div className="flex items-center gap-2 text-sm text-gray-500">
      <LoadingSpinner />
      <span>{status}</span>
    </div>
  );
};

export default AnimatedStatus;
