import React, { useEffect, useMemo, useState } from "react";
import { Dialog } from "@headlessui/react";
import { IdeaItem } from "./IdeaItem";
import { LoadingSpinner } from "../LoadingSpinner";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { SpokableButton } from "../SpokableButton.tsx";
import { RankedIdea, RankedIdeas } from "@/types/ideas_prompts_generated_types.ts";

export interface GenerateIdeasProps {
  ideas: { ranked_ideas: RankedIdea[] } | null;
  status: string;
  generateIdeas: (payload: any) => void;
  selectedIdea: string | null;
  setSelectedIdea: React.Dispatch<React.SetStateAction<string | null>>;
  setIdeas: React.Dispatch<React.SetStateAction<RankedIdeas | null>>;
  audience: string;
}

const GenerateIdeas: React.FC<GenerateIdeasProps> = ({
  ideas,
  status,
  generateIdeas,
  selectedIdea,
  setSelectedIdea,
  setIdeas,
  audience,
}) => {
  const memoizedIdeas = useMemo(() => ideas, [ideas]);
  const [ideaSeed, setIdeaSeed] = useState(audience);

  useEffect(() => {
    if (ideas === null && status === "") {
      generateIdeas({ idea: ideaSeed || "" });
    }
  }, [ideas, status]);

  const handleRegenerate = () => {
    setIdeas(null);
  };

  return (
    <div className="px-8">
      <Dialog.Title as="h3" className="font-serif py-4 text-4xl font-bold mb-4">
        Generate Ideas{" "}
        <SpokableButton
          isIconButton={true}
          color="light"
          className="ml-2"
          onClick={handleRegenerate}
        >
          <ArrowPathIcon />
        </SpokableButton>
        <span className="text-sm ml-2 font-sans"> {status}</span>
      </Dialog.Title>
      <div className="mt-2">
        {status !== "" && <LoadingSpinner />}
        {memoizedIdeas && (
          <>
            <div className="flex w-full space-x-2">
              <div className="flex-grow">
                <AutoResizeTextArea value={ideaSeed} onChange={(value) => setIdeaSeed(value)} />
              </div>
              <div>
                <SpokableButton onClick={handleRegenerate}>Generate</SpokableButton>
              </div>
            </div>
            <div className="rounded-lg p-3 mt-4 max-h-[calc(90vh-340px)] overflow-y-auto">
              {memoizedIdeas.ranked_ideas.map((idea, index) => (
                <IdeaItem
                  key={index}
                  idea={idea}
                  setSelectedIdea={setSelectedIdea}
                  selectedIdea={selectedIdea}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GenerateIdeas;
