import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { SpokableButton } from "./SpokableButton.tsx";
import GenerateSound from "./generateSoundModal/GenerateSound.tsx";
import { popupOpenState } from "../../states/ModalState.ts";
import { useSetRecoilState } from "recoil";

interface SoundGeneratorButtonProps {
  storyId: string;
  onUploadComplete: (fileName: string) => void;
  prompt: string | null;
}

export default function SoundGeneratorButton({
  storyId,
  onUploadComplete,
  prompt,
}: SoundGeneratorButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const setPopupOpen = useSetRecoilState(popupOpenState);

  const closePopup = () => {
    setIsOpen(false);
    setPopupOpen(false);
  };

  return (
    <>
      <SpokableButton
        onClick={() => {
          setIsOpen(true);
          setPopupOpen(true);
        }}
      >
        <SparklesIcon className="mr-2" /> Generate
      </SpokableButton>

      <Dialog
        open={isOpen}
        onClose={() => {
          closePopup();
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all flex flex-col">
            <DialogTitle className="font-serif p-6 text-4xl font-bold mb-4">
              Generate Sound
            </DialogTitle>
            <GenerateSound
              soundPrompt={prompt}
              onSoundSelected={(soundUrl) => {
                onUploadComplete(soundUrl);
                closePopup();
              }}
              storyId={storyId}
            />
            <div className="p-6 bg-gray-50 border-t">
              <div className="flex justify-between items-center">
                <SpokableButton onClick={() => closePopup()} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
