import {
  databaseOperations,
  DatabaseTypes,
  handleDatabaseError,
  SearchFilters,
  showTemporaryMessage,
} from "@/components/admin/promptManagement/database/databaseOperations.ts";
import { useEffect, useState } from "react";

interface UsePromptEvalDetailReturn {
  promptEval: DatabaseTypes["Eval"] | null;
  prompt: DatabaseTypes["Prompt"] | null;
  criterias: DatabaseTypes["Criteria"][];
  promptExecutionLogs: DatabaseTypes["PromptExecutionDetails"][];
  latestPromptForRun: DatabaseTypes["LatestPromptVersionForRun"] | null;
  errorMessage: string | null;
  handleSaveEval: (promptEval: DatabaseTypes["Eval"]) => Promise<void>;
  setErrorMessage: (message: string | null) => void;
  fetchPromptExecutionLogs: () => Promise<void>;
}

export const useEvalRunDatabase = (
  evalId: string | undefined,
  promptId: string | undefined,
): UsePromptEvalDetailReturn => {
  const [promptEval, setPromptEval] = useState<DatabaseTypes["Eval"] | null>(null);
  const [latestPromptForRun, setLatestPromptForRun] = useState<
    DatabaseTypes["LatestPromptVersionForRun"] | null
  >(null);
  const [prompt, setPrompt] = useState<DatabaseTypes["Prompt"] | null>(null);
  const [criterias, setCriterias] = useState<DatabaseTypes["Criteria"][]>([]);
  const [promptExecutionLogs, setPromptExecutionLogs] = useState<
    DatabaseTypes["PromptExecutionDetails"][]
  >([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchData = async () => {
    if (!evalId || !promptId) return;

    try {
      const evalResponse = await databaseOperations.fetchEvalById(evalId);
      if (evalResponse.error) throw evalResponse.error;
      setPromptEval(evalResponse.data);

      const promptResponse = await databaseOperations.fetchPrompt(promptId);
      if (promptResponse.error) throw promptResponse.error;
      setPrompt(promptResponse.data);

      const criteriasResponse = await databaseOperations.fetchEvalCriterias(evalId);
      if (criteriasResponse.error) throw criteriasResponse.error;
      setCriterias(criteriasResponse.data);

      const latestPromptResponse =
        await databaseOperations.fetchLatestPromptVersionForRun(promptId);
      if (latestPromptResponse.error) throw latestPromptResponse.error;
      setLatestPromptForRun(latestPromptResponse.data);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  const fetchPromptExecutionLogs = async () => {
    if (!evalId || !promptId) return;
    try {
      const evalDatasetResponse = await databaseOperations.fetchEvalDatasets(promptId, evalId);

      if (evalDatasetResponse.error) throw evalDatasetResponse.error;
      const filters: SearchFilters = {
        ids: evalDatasetResponse.data?.map((evalDataset) => evalDataset.prompt_execution_id),
      };

      const promptExecutionLogResponse = await databaseOperations.fetchPromptExecutions(
        promptId,
        filters,
      );
      if (promptExecutionLogResponse.error) throw promptExecutionLogResponse.error;

      setPromptExecutionLogs(promptExecutionLogResponse.data);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  const handleSaveEval = async (promptEval: DatabaseTypes["Eval"]) => {
    try {
      const { error } = await databaseOperations.updateEval(promptEval.id, promptEval.eval_name);
      if (error) throw error;

      setPromptEval(promptEval);
      showTemporaryMessage("Successfully saved!", setErrorMessage);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPromptExecutionLogs();
  }, [evalId, promptId]);

  return {
    promptEval,
    prompt,
    criterias,
    errorMessage,
    latestPromptForRun,
    promptExecutionLogs,
    fetchPromptExecutionLogs,
    handleSaveEval,
    setErrorMessage,
  };
};
