import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/catalyst/table.tsx";
import { LOCALE_ENGLISH } from "@/constants/constant.ts";
import { formatDateString } from "@/utils/date.ts";
import { Card, CardContent } from "@/components/admin/Card.tsx";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import { SideNavLayout } from "@/components/admin/SideNavLayout.tsx";
import { usePromptsListDatabase } from "@/components/admin/promptManagement/database/usePromptsListDatabase.tsx";

function PromptsList() {
  const navigate = useNavigate();
  const { prompts, errorMessage } = usePromptsListDatabase();

  const handleAction = (e: React.MouseEvent, promptId: string, action: "logs" | "evals") => {
    e.stopPropagation();
    navigate(`/admin/prompts/${promptId}/${action}`);
  };

  return (
    <SideNavLayout>
      <div className="top-section sticky top-0 backdrop-blur-xl z-50 p-6">
        {errorMessage && (
          <div className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer">
            {errorMessage}
          </div>
        )}
        <h1 className="text-5xl font-bold mb-12">Prompts</h1>
      </div>
      <Card isFullWidth={true}>
        <CardContent>
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
            <TableHead>
              <TableRow>
                <TableHeader></TableHeader>
                <TableHeader></TableHeader>
                <TableHeader>Name</TableHeader>
                <TableHeader>Last executed at</TableHeader>
                <TableHeader>Latest version</TableHeader>
                <TableHeader>Imported at</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {prompts.map((prompt) => (
                <TableRow key={prompt.prompt_id} className="hover:bg-zinc-950/5">
                  <TableCell>
                    <SpokableButton
                      className="ml-4"
                      color="light"
                      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                        handleAction(e, prompt.prompt_id || "", "logs")
                      }
                    >
                      Logs
                    </SpokableButton>
                  </TableCell>
                  <TableCell>
                    <SpokableButton
                      color="light"
                      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                        handleAction(e, prompt.prompt_id || "", "evals")
                      }
                    >
                      Evals
                    </SpokableButton>
                  </TableCell>
                  <TableCell>{prompt.prompt_name}</TableCell>
                  <TableCell>
                    {formatDateString(prompt.last_execution_at, LOCALE_ENGLISH)}
                  </TableCell>
                  <TableCell>{prompt.version}</TableCell>
                  <TableCell>{formatDateString(prompt.created_at, LOCALE_ENGLISH)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </SideNavLayout>
  );
}

export default PromptsList;
