import { useNavigate, useParams } from "react-router-dom";
import SideNavLayout from "../../../components/admin/SideNavLayout.tsx";
import { Card, CardContent } from "@/components/admin/Card.tsx";
import EditableTitle from "@/components/EditableTitle.tsx";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/catalyst/table.tsx";
import { formatDateString } from "@/utils/date.ts";
import { LOCALE_ENGLISH } from "@/constants/constant.ts";
import FormField from "@/components/admin/FormField.tsx";
import { Input } from "@/components/catalyst/input.tsx";
import AutoResizeTextArea from "@/components/admin/AutoResizeTextArea.tsx";
import { useEvalDetailDatabase } from "@/components/admin/promptManagement/database/useEvalDetailDatabase.ts";
import { PlayIcon } from "@heroicons/react/16/solid";

export default function EvalDetail() {
  const { evalId, promptId } = useParams();
  const navigate = useNavigate();

  const {
    promptEval,
    prompt,
    criterias,
    selectedCriteria,
    errorMessage,
    setSelectedCriteria,
    handleSaveEval,
    handleSaveCriteria,
    handleCreateCriteria,
    setErrorMessage,
  } = useEvalDetailDatabase(evalId, promptId);

  if (!promptEval)
    return (
      <div className="flex items-center justify-center h-32">
        <div className="animate-pulse text-gray-500">Loading...</div>
      </div>
    );

  return (
    <SideNavLayout>
      <div className="mb-20">
        <div className="top-sectio sticky top-0 backdrop-blur-xl z-50 p-6">
          {errorMessage && (
            <div
              className={`rounded px-4 py-3 mb-4 cursor-pointer 
                  bg-gray-100 border border-gray-400 text-gray-700
              `}
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}
          <EditableTitle
            initialTitle={promptEval.eval_name}
            titleClassName={"text-5xl font-bold font-serif"}
            onSave={(newTitle) => {
              const newPromptEval = {
                ...promptEval,
                eval_name: newTitle,
              };
              handleSaveEval(newPromptEval);
            }}
          />
          <h3 className="text-xl font-bold mb-4 text-zinc-500">{`${prompt?.prompt_name}`}</h3>
          <SpokableButton onClick={handleCreateCriteria}>Add Criteria</SpokableButton>
          <SpokableButton
            className={"ml-4"}
            onClick={() => navigate(`/admin/prompts/${promptId}/evals/${promptEval.id}/run`)}
          >
            <PlayIcon />
            Run Eval
          </SpokableButton>
        </div>
        <Card isFullWidth={true}>
          <CardContent>
            <h1 className="text-3xl font-bold mb-6">Criterias</h1>
            <div className="flex gap-4 h-[calc(100vh-230px)] w-full">
              <div className="w-1/2 overflow-hidden">
                <div className="h-full overflow-y-auto">
                  <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
                    <TableHead>
                      <TableRow>
                        <TableHeader>
                          <div className="pl-4">Name</div>
                        </TableHeader>
                        <TableHeader>Evaluation</TableHeader>
                        <TableHeader>Updated at</TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {criterias.map((criteria) => (
                        <TableRow
                          key={criteria.id}
                          className={`cursor-pointer ${
                            selectedCriteria?.id === criteria.id
                              ? "bg-black text-white hover:bg-black/90"
                              : "hover:bg-zinc-950/5"
                          }`}
                          onClick={() => setSelectedCriteria(criteria)}
                        >
                          <TableCell>
                            <div className="pl-4">{criteria.eval_criteria_name}</div>
                          </TableCell>
                          <TableCell>{criteria.criteria.substring(0, 30) + "..."}</TableCell>
                          <TableCell>
                            <div>{formatDateString(criteria.created_at, LOCALE_ENGLISH)}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div className="w-1/2 overflow-hidden">
                <div className="h-full overflow-y-auto">
                  <CardContent className="pl-10 mt-1">
                    {selectedCriteria ? (
                      <>
                        <FormField label={"Criteria Name"}>
                          <Input
                            type="text"
                            id="name"
                            value={selectedCriteria.eval_criteria_name || ""}
                            onChange={(e) =>
                              setSelectedCriteria({
                                ...selectedCriteria,
                                eval_criteria_name: e.target.value,
                              })
                            }
                          />
                        </FormField>
                        <FormField label="Evaluation criteria">
                          <AutoResizeTextArea
                            value={selectedCriteria.criteria || ""}
                            onChange={(e) =>
                              setSelectedCriteria({ ...selectedCriteria, criteria: e })
                            }
                          />
                        </FormField>
                        <SpokableButton onClick={() => handleSaveCriteria(selectedCriteria)}>
                          Save
                        </SpokableButton>
                      </>
                    ) : (
                      <div className="text-gray-500 text-center py-8">
                        Select an item to view details
                      </div>
                    )}
                  </CardContent>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </SideNavLayout>
  );
}
