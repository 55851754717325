import { atom, RecoilState, useRecoilCallback } from "recoil";
import { supabase } from "../vendor/supabaseClient";

// Generic atoms (you will create specific ones for each table)
export const createAtom = <T>(key: string, defaultVal: T) =>
  atom<T>({
    key,
    default: defaultVal,
  });

interface WithId {
  id: string; // or string, depending on your ID type
}

// Generic hook for CRUD operations
export const useTable = <T extends WithId, InsertType, UpdateType>(
  tableName: any,
  dataAtom: RecoilState<T[]>,
  errorAtom: RecoilState<string | null>,
) => {
  const createItem = useRecoilCallback(({ set, snapshot }) => async (newItem: InsertType) => {
    console.log("row about to be created");
    const { data, error } = await supabase.from(tableName).insert([newItem]).select();
    if (error) {
      console.log("error", error.message);
      set(errorAtom, error.message);
      return null;
    } else if (data) {
      console.log("row created: ", data);
      const currentItems = snapshot.getLoadable(dataAtom).getValue();
      set(dataAtom, [...currentItems, ...data]);
      return data;
    }
    return null;
  });

  const updateItem = useRecoilCallback(({ set, snapshot }) => async (itemToUpdate: UpdateType) => {
    const { id, ...itemWithoutId } = itemToUpdate as UpdateType & WithId;
    const response = await supabase.from(tableName).update(itemWithoutId).eq("id", id).select();

    if (response.error) {
      console.log("error", response.error.message);
      set(errorAtom, response.error.message);
      return false;
    } else {
      console.log("row updated: ", response.data);
      const updatedData = response.data as T[];
      if (updatedData && Array.isArray(updatedData) && updatedData.length > 0) {
        const currentItems = snapshot.getLoadable(dataAtom).getValue();
        const updatedItems = currentItems.map((item) =>
          (item as any).id === updatedData[0].id ? { ...item, ...updatedData[0] } : item,
        );
        set(dataAtom, updatedItems);
        return true;
      }
    }
    return false;
  });

  const selectItems = useRecoilCallback(({ set }) => async (queryParameters?: any) => {
    let data, error;

    if (queryParameters) {
      ({ data, error } = await supabase.from(tableName).select("*").match(queryParameters));
    } else {
      ({ data, error } = await supabase.from(tableName).select("*"));
    }

    if (error) {
      console.log("error", error.message);
      set(errorAtom, error.message);
      return null;
    } else {
      set(dataAtom, data as T[]);
      return data;
    }
  });

  const selectMostRecentItems = useRecoilCallback(
    ({ set }) =>
      async (dateColumn: string = "created_at", numItem: number = 1) => {
        const { data, error } = await supabase
          .from(tableName)
          .select("*")
          .order(dateColumn, { ascending: false }) // Replace 'created_at' with your date column name
          .limit(numItem);

        if (error) {
          console.log("error", error.message);
          set(errorAtom, error.message);
          return null;
        } else {
          set(dataAtom, data as T[]);
          return data;
        }
      },
  );

  const selectItemsBy = useRecoilCallback(
    ({ set }) =>
      async (idValue: number | string, column: string = "id", numItem: number = 1) => {
        const { data, error } = await supabase
          .from(tableName)
          .select("*")
          .eq(column, idValue) // Filters rows by the specified column and value
          .limit(numItem);

        if (error) {
          console.log("error", error.message);
          set(errorAtom, error.message);
          return null;
        } else {
          set(dataAtom, data as T[]);
          return data;
        }
      },
  );

  const deleteItem = useRecoilCallback(({ set, snapshot }) => async (id: string) => {
    const { error } = await supabase.from(tableName).delete().eq("id", id);

    if (error) {
      console.log("error", error.message);
      set(errorAtom, error.message);
      return false;
    } else {
      console.log("run deleted: ", id);
      const currentItems = snapshot.getLoadable(dataAtom).getValue();
      const updatedItems = currentItems.filter((item: T) => item.id !== id);
      set(dataAtom, updatedItems);
      return true;
    }
  });

  return { createItem, updateItem, deleteItem, selectItems, selectMostRecentItems, selectItemsBy };
};
