import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tables } from "../../types/database.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import ColorPicker from "../../components/admin/ColorPicker.tsx";
import StorageFileSelector from "../../components/admin/StorageFileSelector.tsx";
import FormField from "../../components/admin/FormField.tsx";
import {
  IMAGE_BUCKET_NAME,
  PAGE_GENERAL,
  SOUND_BUCKET_NAME,
  STORY_BRANCHING_LEVELS,
  STORY_INTERACTIVITY_LEVELS,
  VISUAL_TYPE_IMAGE,
} from "../../constants/constant.ts";
import { getSupabaseImageUrl } from "../../utils/mediaUtil.ts";
import {
  Card,
  CardContent,
  CardH1,
  CardHeader,
  CardImage,
  CardTitle,
} from "../../components/admin/Card.tsx";
import { Input } from "../../components/catalyst/input.tsx";
import StorageFileAudioPlayer from "../../components/admin/StorageFileAudioPlayer.tsx";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";
import AutoResizeTextArea from "../../components/admin/AutoResizeTextArea.tsx";
import NumericStepper from "../../components/admin/NumericStepper.tsx";
import SpokableTagSelector from "../../components/admin/SpokableTagSelector.tsx";
import { ESBR_RATINGS, ESBR_TAGS } from "../../constants/rating.ts";
import { GENRE_TAGS } from "../../constants/genres.ts";
import SpokableListBox from "../../components/admin/SpokableListBox.tsx";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { modalStoryGenerateState } from "../../states/ModalState.ts";
import { useRecoilState } from "recoil";
import GenerateStoryModal from "../../components/admin/generateStoryModal/GenerateStoryModal.tsx";
import ImageGenerationStyleSelector from "../../components/admin/ImageGenerationStyleSelector.tsx";
import { GlobalSideNavLayout } from "../../components/admin/GlobalSideNavLayout.tsx";
import { SpokableButton } from "../../components/admin/SpokableButton.tsx";
import ImageGeneratorSimpleButton from "../../components/admin/ImageGeneratorSimpleButton.tsx";
import { getYear } from "../../utils/date.ts";
import UpscaleImageButton from "../../components/admin/UpscaleImageButton.tsx";

function StoryDetail() {
  const { storyId } = useParams<{
    storyId: string;
  }>();
  const [story, setStory] = useState<Tables<"blueprint_stories"> | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { stopBackgroundMusic } = useBackgroundMusicManager();
  const [, setGenerateModalOpen] = useRecoilState(modalStoryGenerateState);
  // const [onePager, setOnePager] = useState<OnePager | null>(null);
  const [isOpen] = useRecoilState(modalStoryGenerateState);

  useEffect(() => {
    stopBackgroundMusic();
    fetchStory();
  }, []);

  useEffect(() => {
    if (!isOpen) {
      fetchStory();
    }
  }, [isOpen]);

  async function fetchStory() {
    if (storyId == undefined) return;
    const { data, error } = await supabase
      .from("blueprint_stories")
      .select("*")
      .eq("id", storyId)
      .returns<Tables<"blueprint_stories">[]>()
      .single();

    if (error) {
      setErrorMessage("Error fetching story: " + error.message);
    } else {
      setStory(data);
    }
  }

  async function updateStory() {
    if (!story) return;
    if (storyId == undefined) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: _id, ...storyWithoutId } = story;
    // storyWithoutId.one_pager = JSON.stringify(onePager);
    const { error } = await supabase
      .from("blueprint_stories")
      .update(storyWithoutId)
      .eq("id", storyId);

    if (error) {
      setErrorMessage("Error updating story: " + error.message);
    } else {
      setErrorMessage("Saved! ");
    }
  }

  if (!story) {
    return <div>Loading...</div>;
  }

  return (
    <GlobalSideNavLayout activePageId={PAGE_GENERAL} storyId={storyId || ""}>
      <div className="max-w-2xl mb-20">
        <div className="top-section sticky top-0 backdrop-blur-xl z-50 p-6">
          {errorMessage && (
            <div
              className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}
          <div className="flex gap-6">
            {story.background_image && (
              <img
                alt={"story image"}
                key={story.id}
                className="size-44 flex-shrink-0"
                src={
                  getSupabaseImageUrl(story.id, story.background_image, VISUAL_TYPE_IMAGE, true) ||
                  `https://api.dicebear.com/9.x/shapes/svg?seed=${story.id}`
                }
              />
            )}
            <div className="flex flex-col justify-between w-full">
              <div>
                <div className="text-sm text-gray-500 mb-2">
                  {story.created_at ? getYear(story.created_at) : ""}
                </div>
                <h1 className="text-5xl font-bold mb-2">{story.name}</h1>
                <div className="text-xl mb-4">{}</div>
              </div>
              <div className="flex gap-4">
                <SpokableButton onClick={updateStory}>Save</SpokableButton>
                <SpokableButton onClick={() => setGenerateModalOpen(true)}>
                  <SparklesIcon className="mr-2" />
                  Generate
                </SpokableButton>
              </div>
            </div>
          </div>
        </div>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Home Page" id="basic" />
            <FormField label="Name">
              <Input
                type="text"
                id="chapter_name"
                value={story.name || ""}
                onChange={(e) => setStory({ ...story, name: e.target.value })}
              />
            </FormField>
            <FormField label="Log line">
              <AutoResizeTextArea
                value={story.catalogue_description || ""}
                onChange={(e) => setStory({ ...story, catalogue_description: e })}
              />
            </FormField>
            <FormField label="Home page image prompt">
              <AutoResizeTextArea
                value={story.image_prompt || ""}
                onChange={(e) => setStory({ ...story, image_prompt: e })}
              />
            </FormField>
            <FormField label={"Style"}>
              <ImageGenerationStyleSelector
                promptStyle={story.image_prompt_style_key || ""}
                onPromptStyleSelected={(e) => {
                  setStory({
                    ...story,
                    image_prompt_style_key: e,
                  });
                }}
              />
            </FormField>
            <FormField label={"Poster"}>
              <ImageGeneratorSimpleButton
                story={story}
                onUploadComplete={(storageFileUrl) =>
                  setStory({ ...story, background_image: storageFileUrl })
                }
                basePrompt={story.image_prompt}
                promptModifier={story.image_prompt_style_key}
                baseFileName={story.name || "story"}
              />
              {story.background_image && (
                <Card className="sm:col-span-2 mt-4">
                  <CardHeader className="pb-3">
                    <CardImage
                      src={
                        getSupabaseImageUrl(
                          storyId,
                          story.background_image,
                          VISUAL_TYPE_IMAGE,
                          true,
                        ) ?? ""
                      }
                    />
                  </CardHeader>
                  <UpscaleImageButton
                    storyId={storyId || ""}
                    onUploadComplete={(url) => console.log(url)}
                    imageUrl={story.background_image}
                  />
                </Card>
              )}
            </FormField>

            <Card isFullWidth={true}>
              <CardContent>
                <CardH1 label="Catalogue details" id="basic" />
                <FormField label="">
                  <NumericStepper
                    value={story.estimated_minute_duration || 90}
                    onChange={(e) => {
                      setStory({ ...story, estimated_minute_duration: e });
                    }}
                    label={"Estimate duration in minutes"}
                  />
                </FormField>
                <FormField label="ESBR Rating">
                  <SpokableListBox
                    options={ESBR_RATINGS}
                    value={
                      ESBR_RATINGS.find((type) => type.key === story.esbr_rating) || ESBR_RATINGS[0]
                    }
                    onChange={(selectedType) => {
                      setStory({ ...story, esbr_rating: selectedType.key });
                    }}
                  />
                </FormField>
                <FormField label="ESBR tags">
                  <SpokableTagSelector
                    value={story.esbr_tags || []}
                    tags={ESBR_TAGS}
                    onChange={(e) => {
                      setStory({ ...story, esbr_tags: e });
                    }}
                  />
                </FormField>
                <FormField label="Genre tags">
                  <SpokableTagSelector
                    value={story.genre_tags || []}
                    tags={GENRE_TAGS}
                    onChange={(e) => {
                      setStory({ ...story, genre_tags: e });
                    }}
                  />
                </FormField>
                <FormField label="Interactivity level">
                  <SpokableListBox
                    options={STORY_INTERACTIVITY_LEVELS}
                    value={
                      STORY_INTERACTIVITY_LEVELS.find(
                        (type) => type.key === story.interactivity_level,
                      ) || STORY_INTERACTIVITY_LEVELS[0]
                    }
                    onChange={(selectedType) => {
                      setStory({ ...story, interactivity_level: selectedType.key });
                    }}
                  />
                </FormField>
                <FormField label="Branching level">
                  <SpokableListBox
                    options={STORY_BRANCHING_LEVELS}
                    value={
                      STORY_BRANCHING_LEVELS.find((type) => type.key === story.branching_level) ||
                      STORY_BRANCHING_LEVELS[0]
                    }
                    onChange={(selectedType) => {
                      setStory({ ...story, branching_level: selectedType.key });
                    }}
                  />
                </FormField>
                <div className="w-full lg:w-2/3">
                  <div className="flex justify-between gap-4">
                    <FormField label="Background">
                      <ColorPicker
                        initialColor={story.background_rgba_color || ""}
                        onColorChange={(color) =>
                          setStory({ ...story, background_rgba_color: color })
                        }
                      ></ColorPicker>
                    </FormField>
                    <FormField label="Text">
                      <ColorPicker
                        initialColor={story.text_rgba_color || ""}
                        onColorChange={(color) => setStory({ ...story, text_rgba_color: color })}
                      ></ColorPicker>
                    </FormField>
                    <FormField label="Control">
                      <ColorPicker
                        initialColor={story.control_rgba_color || ""}
                        onColorChange={(color) => setStory({ ...story, control_rgba_color: color })}
                      ></ColorPicker>
                    </FormField>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card isFullWidth={true}>
              <CardContent>
                <CardH1 label="Story home details" id="basic" />
                <FormField label={"Story logo"}>
                  <StorageFileSelector
                    bucketName={IMAGE_BUCKET_NAME}
                    storyId={story.id}
                    onStorageFileSelected={(storageFileUrl) =>
                      setStory({ ...story, story_logo_image: storageFileUrl })
                    }
                    value={story.story_logo_image}
                    hideImagePreview={true}
                  />
                  {story.story_logo_image && (
                    <Card className="sm:col-span-2">
                      <CardHeader className="pb-3">
                        <CardImage
                          src={getSupabaseImageUrl(storyId, story.story_logo_image) ?? ""}
                        />
                        <CardTitle>
                          {story.story_logo_image.replace(".png", "").toUpperCase()}
                        </CardTitle>
                      </CardHeader>
                    </Card>
                  )}
                </FormField>
                <FormField label="Sound intro">
                  <StorageFileSelector
                    bucketName="sound"
                    storyId={story.id}
                    onStorageFileSelected={(storageFileUrl) =>
                      setStory({ ...story, sound_intro: storageFileUrl })
                    }
                    value={story.sound_intro}
                  />
                  {story.sound_intro && (
                    <StorageFileAudioPlayer
                      bucketName={SOUND_BUCKET_NAME}
                      storyId={story.id}
                      fileName={story.sound_intro}
                    />
                  )}
                </FormField>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </div>
      <GenerateStoryModal story={story} />
    </GlobalSideNavLayout>
  );
}

export default StoryDetail;
