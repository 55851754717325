import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { SpokableButton } from "./SpokableButton.tsx";
import { popupOpenState } from "../../states/ModalState.ts";
import { useSetRecoilState } from "recoil";
import AutoComplete from "./autoCompleteModal/autoComplete.tsx";

interface AutoCompleteGenerateButtonProps {
  onAutoComplete: (value: string) => void;
  basePrompt: string;
  generateFrom: string;
}

export default function AutoCompleteGenerateButton({
  onAutoComplete,
  basePrompt,
  generateFrom,
}: AutoCompleteGenerateButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const setPopupOpen = useSetRecoilState(popupOpenState);

  const closePopup = () => {
    setIsOpen(false);
    setPopupOpen(false);
  };

  return (
    <>
      <SpokableButton
        color="light"
        onClick={() => {
          setIsOpen(true);
          setPopupOpen(true);
        }}
        isIconButton
      >
        <SparklesIcon className="mr-2" />
      </SpokableButton>

      <Dialog
        open={isOpen}
        onClose={() => {
          closePopup();
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all flex flex-col">
            <DialogTitle className="font-serif p-6 text-4xl font-bold mb-4">Fill input</DialogTitle>
            <AutoComplete
              basePrompt={basePrompt}
              generateFrom={generateFrom}
              onAutoComplete={(completion) => {
                onAutoComplete(completion);
                closePopup();
              }}
            />
            <div className="p-6 bg-gray-50 border-t">
              <div className="flex justify-between items-center">
                <SpokableButton onClick={() => closePopup()} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
