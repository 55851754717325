import ReactSlider from "react-slider";
import {
  CameraIcon,
  ChevronRightIcon,
  FilmIcon,
  ScissorsIcon,
  StopCircleIcon,
} from "@heroicons/react/16/solid";
import { SpokableButton } from "../SpokableButton";
import { useCamera } from "./useCamera";
import { useVideoTrimming } from "./useVideoTrimming";
import { useVideoUpload } from "./useVideoUpload.ts";
import { useVideoConversion } from "./useVideoConversion.ts";
import { getCustomEmotionVideoFileName } from "../../../utils/mediaUtil.ts";
import { downloadFile } from "../generateImagesModal/ImageVariationUtil.ts";
import { useImageVariationCall } from "../generateImagesModal/useImageVariationCall.tsx";
import { ImageVariation } from "../../../types/fastApiTypes.ts";
import { useMemo, useState } from "react";

interface VideoCaptureProps {
  storyId: string;
  collectionName: string;
  emotionKey: string;
  onVideoDone: () => void;
  baseImageFile: File;
}

const VideoCapture = ({
  storyId,
  collectionName,
  emotionKey,
  onVideoDone,
  baseImageFile,
}: VideoCaptureProps) => {
  const {
    recording,
    isCameraStarted,
    rawRecordedVideoUrl,
    rawHTMLVideoElementRef,
    startCamera,
    startRecording,
    stopRecording,
    getRecordedBlob,
  } = useCamera();

  const { uploadVideo, state: uploadState } = useVideoUpload({
    storyId,
    collectionName,
    emotionKey,
  });

  const { convertVideo, state } = useVideoConversion();

  const {
    state: trimmingState,
    previewRef,
    handleRangeChange,
  } = useVideoTrimming(rawRecordedVideoUrl);

  const { makeImageVariationCall, isProcessing: isImageVariationProcessing } =
    useImageVariationCall();
  const checkedEmotions = new Set([emotionKey]);
  const [testVideoUrl, setTestVideoUrl] = useState<string | null>(null);
  const [isCrunchingVideo, setIsCrunchingVideo] = useState<boolean>(false);

  const handleProcessAndUpload = async () => {
    if (!previewRef.current || !trimmingState.isVideoReady || !trimmingState.isValidDuration)
      return;

    setIsCrunchingVideo(true);
    const startFrame = trimmingState.startFrame;
    let endFrame = trimmingState.endFrame;
    if (endFrame <= startFrame) {
      endFrame = previewRef.current.duration;
    }

    try {
      const trimmedBlob = getRecordedBlob();
      if (!trimmedBlob || trimmedBlob.size === 0) {
        console.error("Failed to get trimmed blob");
        setIsCrunchingVideo(false);
        return;
      }
      const convertedUrl = await convertVideo(trimmedBlob, storyId, startFrame, endFrame);

      if (!convertedUrl) {
        console.error("Failed to convert video");
        setIsCrunchingVideo(false);
        return;
      }

      console.log("convertedUrl", convertedUrl);
      const response = await fetch(convertedUrl);
      if (!response.ok) {
        setIsCrunchingVideo(false);
        console.error("Failed to fetch converted video", response);
      }

      const convertedBlob = await response.blob();

      await uploadVideo(convertedBlob);
      await handleTestDrivingVideo();
      setIsCrunchingVideo(false);
    } catch (error) {
      setIsCrunchingVideo(false);
      console.error("Failed to process and upload video:", error);
    }
  };

  const handleTestDrivingVideo = async () => {
    setTestVideoUrl(null);
    if (!baseImageFile) return;
    const fileName = getCustomEmotionVideoFileName(collectionName, emotionKey);

    const file = await downloadFile(storyId + "/" + fileName);
    if (!file) return;

    const options = {
      numberOfVariations: 0,
      computeDepthMap: false,
      removeBackground: false,
      computeEmotionVideos: true,
      computeCinematicVideos: false,
      checkedEmotions: checkedEmotions,
      cinematicPrompt: "",
      eventPrompt: "",
      emotionVideos: [file],
    };

    const result = await makeImageVariationCall(baseImageFile, options);
    if (result.variations) {
      console.log("result.variations", result.variations);
      console.log("url", getTestVideoUrl(result.variations, emotionKey));
      setTestVideoUrl(getTestVideoUrl(result.variations, emotionKey));
    }
    if (result.error) {
      console.error("Processing failed:", result.error);
    } else {
      console.log("Processing succeeded:", result.variations);
    }
  };

  const { currentStatus } = useMemo(() => {
    if (state.isConverting) return { isProcessing: true, currentStatus: "Converting..." };
    if (uploadState.isUploading) return { isProcessing: true, currentStatus: "Uploading..." };
    if (isImageVariationProcessing) return { isProcessing: true, currentStatus: "Testing..." };
    return { isProcessing: false, currentStatus: null };
  }, [state.isConverting, uploadState.isUploading, isImageVariationProcessing]);

  const getTestVideoUrl = (variations: ImageVariation[], emotionKey: string): string | null => {
    if (variations.length > 0 && variations[0].emotion_videos) {
      const matchingVideo = variations[0].emotion_videos.find(
        (video) => video.emotion === emotionKey,
      );
      return matchingVideo?.url || null;
    }
    return null;
  };

  return (
    <div className="max-w-6xl mx-auto p-4 overflow-auto">
      <div className="flex items-start justify-between gap-8">
        {/* Step 1: Camera and Recording */}
        <div className="flex-1 space-y-4">
          <h3 className="text-lg font-semibold text-center">1. Record</h3>

          <div className="relative w-48 h-48 rounded-lg">
            <video
              ref={rawHTMLVideoElementRef}
              autoPlay
              muted
              className="w-48 h-48 bg-gray-900 rounded-lg"
            />
          </div>

          <div className="h-1" />
          <div className="flex justify-center gap-4 ">
            {!isCameraStarted ? (
              <SpokableButton
                onClick={() => startCamera()}
                className="flex items-center gap-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-900"
              >
                <CameraIcon className="w-5 h-5" />
                Start Camera
              </SpokableButton>
            ) : (
              <SpokableButton
                onClick={!recording ? startRecording : stopRecording}
                className="flex items-center gap-2 px-4 py-2 bg-black text-white rounded-lg"
              >
                {!recording ? (
                  <>
                    <FilmIcon className="w-5 h-5" />
                    Record
                  </>
                ) : (
                  <>
                    <StopCircleIcon className="w-5 h-5" />
                    Stop
                  </>
                )}
              </SpokableButton>
            )}
          </div>
        </div>

        <ChevronRightIcon className="h-16 text-gray-400 mt-32" />

        {/* Step 2: Trimming */}
        <div className="flex-1 space-y-4">
          <h3 className="text-lg font-semibold text-center">2. Adjust loop</h3>
          {rawRecordedVideoUrl ? (
            <div className="w-48 h-48">
              <video ref={previewRef} controls autoPlay loop className={`w-48 h-48 rounded-lg`} />
              {trimmingState.isVideoReady && (
                <div className="space-y-2">
                  <ReactSlider
                    className="h-6 flex items-center"
                    thumbClassName="w-4 h-4 bg-gray-500 rounded-full cursor-grab focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    trackClassName="h-2 bg-gray-200 rounded-full"
                    min={0}
                    max={trimmingState.duration}
                    step={0.1}
                    value={[trimmingState.startFrame, trimmingState.endFrame]}
                    onChange={handleRangeChange}
                    minDistance={0.1}
                    pearling
                  />
                  {!trimmingState.isValidDuration ? (
                    <div className="text-sm text-center">
                      <span>Loop should be under 5 seconds</span>
                    </div>
                  ) : (
                    <div className="flex justify-center">
                      <SpokableButton
                        onClick={handleProcessAndUpload}
                        disabled={isCrunchingVideo}
                        className="flex items-center gap-2 px-4 py-2 bg-black text-white rounded-lg disabled:bg-gray-400"
                      >
                        {isCrunchingVideo ? (
                          <>
                            <ScissorsIcon className="w-5 h-5 animate-spin" />
                            {currentStatus}
                          </>
                        ) : (
                          <>
                            <ScissorsIcon className="w-5 h-5" />
                            Trim
                          </>
                        )}
                      </SpokableButton>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="w-48 h-48 flex items-center justify-center border-2 border-dashed rounded-lg">
              <p className="text-gray-500">Record a video first</p>
            </div>
          )}
        </div>

        <ChevronRightIcon className="h-16 text-gray-400 mt-32" />

        {/* Step 3: Test and Save */}
        <div className="flex-1 space-y-4">
          <h3 className="text-lg font-semibold text-center">3. Test & Save</h3>
          <div className="space-y-4">
            {testVideoUrl ? (
              <>
                <video
                  controls
                  autoPlay
                  loop
                  className={`w-48 h-48 ${testVideoUrl ? "" : "bg-gray-900"} rounded-lg`}
                  src={testVideoUrl || ""}
                />
                <div className="h-1" />
                <div className="flex justify-center gap-4 ">
                  <SpokableButton onClick={onVideoDone}>Save</SpokableButton>
                </div>
              </>
            ) : (
              <div className="w-48 h-48 flex items-center justify-center border-2 border-dashed rounded-lg">
                <p className="text-gray-500">Result preview</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCapture;
