import { useEffect, useState } from "react";
import { supabase } from "../../vendor/supabaseClient";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { GenerateImageVariations } from "./generateImagesModal/GenerateImageVariations";
import {
  IMAGE_CONFIG_TYPE_BACKGROUND_IMAGE,
  IMAGE_CONFIG_TYPE_CHARACTER_IMAGE,
} from "../../constants/constant";

import { TablesInsert } from "../../types/database";
import { uploadToSupabaseFileFromUrl } from "../../utils/mediaUtil.ts";
import { sanitizeForURL } from "../../utils/stringUtil.ts";
import GenerateBaseImage from "./generateImagesModal/GenerateBaseImage.tsx";
import { SpokableButton } from "./SpokableButton.tsx";
import { popupOpenState } from "../../states/ModalState.ts";
import { useSetRecoilState } from "recoil";

export interface ImageToBeSaved {
  image_url: string;
  image_type: string;
  has_version_without_background?: boolean;
  has_depth_map?: boolean;
  has_original?: boolean;
  has_emotion_videos?: boolean;
  has_cinematic_videos?: boolean;
}

interface ImageGeneratorUploaderButtonProps {
  storyId: string;
  characterOrChapterId: string;
  baseFileName: string | undefined | null;
  onUploadComplete: (fileName: string) => void;
  basePrompt: string | null;
  promptModifier: string | null;
  generationType: string;
  imageReferenceFileName: string | null;
  videoPrompt?: string | null | undefined;
  videoEventPrompt?: string | null | undefined;
}

export default function ImageGeneratorAndVariationButton({
  characterOrChapterId,
  storyId,
  onUploadComplete,
  basePrompt,
  promptModifier,
  baseFileName,
  generationType,
  videoPrompt,
  videoEventPrompt,
  imageReferenceFileName,
}: ImageGeneratorUploaderButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageReplicateUrl, setSelectedImageReplicateUrl] = useState<string | null>(null);
  const [selectedImageSupabaseUrl, setSelectedImageSupabaseUrl] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<string>("");

  const [numberOfVariations, setNumberOfVariations] = useState<number>(0);
  const [computeDepthMap, setComputeDepthMap] = useState<boolean>(false);
  const [removeBackground, setRemoveBackground] = useState<boolean>(false);
  const [computeEmotionVideos, setComputeEmotionVideos] = useState<boolean>(false);
  const [computeCinematicVideos, setComputeCinematicVideos] = useState<boolean>(false);
  const setPopupOpen = useSetRecoilState(popupOpenState);

  useEffect(() => {
    setNumberOfVariations(0);
    setRemoveBackground(false);

    if (generationType === IMAGE_CONFIG_TYPE_CHARACTER_IMAGE) {
      setComputeDepthMap(false);
      setComputeEmotionVideos(true);
      setComputeCinematicVideos(false);
    }
    if (generationType === IMAGE_CONFIG_TYPE_BACKGROUND_IMAGE) {
      setComputeDepthMap(false);
      setComputeCinematicVideos(true);
    }
  }, [generationType]);

  async function createImage(stagedImage: ImageToBeSaved) {
    if (generationType === IMAGE_CONFIG_TYPE_CHARACTER_IMAGE) {
      createCharacterImage(stagedImage);
    } else if (generationType === IMAGE_CONFIG_TYPE_BACKGROUND_IMAGE) {
      createChapterImage(stagedImage);
    } else {
      if (fileName) onUploadComplete(fileName);
    }
    closePopup();
  }

  async function createCharacterImage(stagedImage: ImageToBeSaved) {
    const imageData: TablesInsert<"blueprint_character_images"> = {
      image_type: stagedImage.image_type,
      image_url: stagedImage.image_url,
      blueprint_character_id: characterOrChapterId,
      blueprint_story_id: storyId,
      has_original: stagedImage.has_original,
      has_depth_map: stagedImage.has_depth_map,
      has_version_without_background: stagedImage.has_version_without_background,
      has_cinematic_videos: stagedImage.has_cinematic_videos,
      has_emotion_videos: stagedImage.has_emotion_videos,
    };

    const { error } = await supabase.from("blueprint_character_images").insert(imageData);

    if (error) {
      setUploadStatus("Error creating character images: " + error.message);
    } else {
      if (fileName) onUploadComplete(fileName);
    }
  }

  async function createChapterImage(stagedImage: ImageToBeSaved) {
    const imageData: TablesInsert<"blueprint_chapter_images"> = {
      image_type: stagedImage.image_type,
      image_url: stagedImage.image_url,
      blueprint_chapter_id: characterOrChapterId,
      blueprint_story_id: storyId,
      has_original: stagedImage.has_original,
      has_depth_map: stagedImage.has_depth_map,
      has_version_without_background: stagedImage.has_version_without_background,
      has_cinematic_videos: stagedImage.has_cinematic_videos,
      has_emotion_videos: stagedImage.has_emotion_videos,
    };

    const { error } = await supabase.from("blueprint_chapter_images").insert(imageData);

    if (error) {
      setUploadStatus("Error creating Chapter images: " + error.message);
    } else {
      if (fileName) onUploadComplete(fileName);
    }
  }

  const uploadGeneratedImage = async () => {
    if (!selectedImageReplicateUrl) return;
    setUploadStatus("Uploading Image...");
    const fileNameInStorage = `${sanitizeForURL(baseFileName || "generated")}-${Date.now()}.jpg`;
    const url = await uploadToSupabaseFileFromUrl(
      storyId,
      selectedImageReplicateUrl,
      fileNameInStorage,
    );
    setFileName(fileNameInStorage);
    setSelectedImageSupabaseUrl(url);

    setStepIndex(stepIndex + 1);
    setUploadStatus("");
  };

  const closePopup = () => {
    setIsOpen(false);
    setPopupOpen(false);
  };

  return (
    <>
      <SpokableButton
        onClick={() => {
          setIsOpen(true);
          setPopupOpen(true);
        }}
      >
        <SparklesIcon className="mr-2" />
        Generate
      </SpokableButton>

      <Dialog
        open={isOpen}
        onClose={() => {
          closePopup();
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all flex flex-col">
            <DialogTitle className="font-serif p-6 text-4xl font-bold mb-4">
              Generate Visuals
            </DialogTitle>
            {stepIndex === 0 && (
              <GenerateBaseImage
                storyId={storyId}
                initialPrompt={basePrompt}
                initialPromptModifier={promptModifier}
                onImageSelected={(imageUrl) => {
                  setSelectedImageReplicateUrl(imageUrl);
                }}
                imageReferenceFileName={imageReferenceFileName}
              />
            )}
            {stepIndex === 1 && (
              <GenerateImageVariations
                baseImageUrlWithStory={selectedImageSupabaseUrl}
                storyId={storyId}
                number_of_variations={numberOfVariations}
                compute_depth_map={computeDepthMap}
                remove_background={removeBackground}
                compute_emotion_videos={computeEmotionVideos}
                compute_cinematic_videos={computeCinematicVideos}
                onVariationsCompleted={createImage}
                videoPrompt={videoPrompt}
                videoEventPrompt={videoEventPrompt}
              />
            )}
            <div className="p-6 bg-gray-50 border-t">
              <div className="flex justify-between items-center">
                {stepIndex === 0 && (
                  <SpokableButton
                    onClick={() => uploadGeneratedImage()}
                    disabled={!selectedImageReplicateUrl}
                  >
                    Use Selected Image
                  </SpokableButton>
                )}
                {stepIndex === 1 && (
                  <SpokableButton color="light" onClick={() => setStepIndex(stepIndex - 1)}>
                    Previous
                  </SpokableButton>
                )}

                {uploadStatus && <p>{uploadStatus}</p>}
                <SpokableButton onClick={() => closePopup()} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
