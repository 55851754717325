import { useState } from "react";
import { getCustomEmotionVideoFileName, uploadToSupabaseData } from "../../../utils/mediaUtil.ts";
import { IMAGE_BUCKET_NAME } from "../../../constants/constant.ts";

interface UploadConfig {
  storyId: string;
  collectionName: string;
  emotionKey: string;
}

export const useVideoUpload = ({ storyId, collectionName, emotionKey }: UploadConfig) => {
  const [state, setState] = useState({
    isUploading: false,
    uploadedUrl: "",
  });

  const uploadVideo = async (blob: Blob) => {
    try {
      console.log("here", blob.size);
      setState((prev) => ({ ...prev, isUploading: true }));
      const fileName = getCustomEmotionVideoFileName(collectionName, emotionKey);
      console.log("here", fileName);
      const videoUrl = await uploadToSupabaseData(
        storyId,
        blob,
        fileName,
        IMAGE_BUCKET_NAME,
        false,
      );

      if (videoUrl) {
        const finalFileName = videoUrl.split("/").pop();
        console.log("here", finalFileName);
        setState((prev) => ({ ...prev, uploadedUrl: finalFileName ? finalFileName : "" }));
      }
      setState((prev) => ({ ...prev, isUploading: false }));
      return videoUrl;
    } catch (error) {
      setState((prev) => ({ ...prev, isUploading: false }));
      throw error;
    }
  };

  return {
    state,
    uploadVideo,
  };
};
