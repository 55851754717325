import React, { useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";

interface WaveformProps {
  url: string;
}

const Waveform: React.FC<WaveformProps> = ({ url }) => {
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);

  useEffect(() => {
    if (waveformRef.current) {
      if (!wavesurferRef.current) {
        wavesurferRef.current = WaveSurfer.create({
          container: waveformRef.current,
          waveColor: "rgb(2,2,2)",
          progressColor: "rgb(171,170,170)",
          cursorColor: "transparent",
          barWidth: 2,
          barRadius: 1,
          height: 100,
          interact: true,
        });
      }

      wavesurferRef.current.load(url);

      wavesurferRef.current.on("click", () => {
        wavesurferRef.current?.playPause();
      });

      return () => {
        if (wavesurferRef.current) {
          wavesurferRef.current.stop();
          wavesurferRef.current.unAll();
          wavesurferRef.current.empty();
        }
      };
    }
  }, [url]);

  return <div ref={waveformRef} className="w-full mt-6 h-24 cursor-pointer" />;
};

export default Waveform;
