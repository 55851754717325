import {
  databaseOperations,
  DatabaseTypes,
  handleDatabaseError,
} from "@/components/admin/promptManagement/database/databaseOperations.ts";
import { useEffect, useState } from "react";

interface UsePromptEvalsReturn {
  evals: DatabaseTypes["Eval"][];
  prompt: DatabaseTypes["Prompt"] | null;
  errorMessage: string | null;
  fetchPrompt: () => Promise<void>;
  fetchEvals: () => Promise<void>;
  createEval: () => Promise<DatabaseTypes["Eval"] | null>;
}

export const useEvalsListDatabase = (promptId: string | undefined): UsePromptEvalsReturn => {
  const [evals, setEvals] = useState<DatabaseTypes["Eval"][]>([]);
  const [prompt, setPrompt] = useState<DatabaseTypes["Prompt"] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchPrompt = async () => {
    if (!promptId) return;
    try {
      const { data, error } = await databaseOperations.fetchPrompt(promptId);
      if (error) throw error;
      setPrompt(data);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  const fetchEvals = async () => {
    if (!promptId) return;
    try {
      const { data, error } = await databaseOperations.fetchEvals(promptId);
      if (error) throw error;
      setEvals(data);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  const createEval = async () => {
    if (!promptId) return null;
    try {
      const { data, error } = await databaseOperations.createEval(promptId);
      if (error) throw error;
      return data;
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
      return null;
    }
  };

  useEffect(() => {
    if (promptId) {
      fetchPrompt();
      fetchEvals();
    }
  }, [promptId]);

  return { evals, prompt, errorMessage, fetchPrompt, fetchEvals, createEval };
};
