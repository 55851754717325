import React, { useEffect, useState } from "react";
import AutoResizeTextArea from "./AutoResizeTextArea.tsx";

interface ImageGenerationStyleSelectorProps {
  promptStyle: string;
  onPromptStyleSelected: (promptStyle: string, model?: string) => void;
}

const ImageGenerationStyleSelector: React.FC<ImageGenerationStyleSelectorProps> = ({
  promptStyle,
  onPromptStyleSelected,
}) => {
  const [selectedPromptStyle, setSelectedPromptStyle] = useState<string>(promptStyle);

  useEffect(() => {
    setSelectedPromptStyle(promptStyle);
  }, [promptStyle]);

  return (
    <div className="w-full space-y-4 self-start">
      <div className="bg-gray-200 rounded-md p-4 h-full">
        <AutoResizeTextArea
          className="text-sm border-none bg-gray-200"
          value={selectedPromptStyle || promptStyle}
          onChange={(e) => {
            setSelectedPromptStyle(e);
            onPromptStyleSelected(e);
          }}
        ></AutoResizeTextArea>
      </div>
    </div>
  );
};

export default ImageGenerationStyleSelector;
