import React, { useEffect, useMemo } from "react";
import { Dialog } from "@headlessui/react";
import { LoadingSpinner } from "../LoadingSpinner";
import DynamicForm from "../DynamicForm";
import { LogLine, OnePager } from "../../../types/fastApiTypes";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { SpokableButton } from "../SpokableButton.tsx";

export interface GenerateOnePagerProps {
  onePager: OnePager | null;
  status: string;
  setOnePager: React.Dispatch<React.SetStateAction<OnePager | null>>;
  generateOnePager: (payload: any) => void;
  logline: LogLine | null;
  audience: string;
}

const GenerateOnePager: React.FC<GenerateOnePagerProps> = ({
  onePager,
  status,
  setOnePager,
  generateOnePager,
  logline,
  audience,
}) => {
  const memoizedOnePager = useMemo(() => onePager, [onePager]);

  useEffect(() => {
    if (logline && onePager === null && status === "") {
      generateOnePager({ logline: logline?.log_line || "", audience: audience });
    }
  }, [onePager, logline, generateOnePager, setOnePager]);

  const handleRegenerate = () => {
    if (logline) {
      setOnePager(null);
    }
  };

  return (
    <div className=" px-8 py-6">
      <Dialog.Title as="h3" className="font-serif text-4xl font-bold mb-4">
        One Pager
        <SpokableButton
          isIconButton={true}
          color="light"
          className="ml-2"
          onClick={handleRegenerate}
        >
          <ArrowPathIcon className="h-4 w-4" />
        </SpokableButton>
      </Dialog.Title>
      {status !== "" && <LoadingSpinner />}
      {memoizedOnePager && (
        <div className="mt-4 max-h-[calc(90vh-300px)] overflow-y-auto justify-items-start">
          <DynamicForm data={memoizedOnePager} setData={setOnePager} />
        </div>
      )}
    </div>
  );
};

export default GenerateOnePager;
