import { useEffect, useState } from "react";
import {
  databaseOperations,
  DatabaseTypes,
  handleDatabaseError,
} from "@/components/admin/promptManagement/database/databaseOperations.ts";

export interface UsePromptsReturn {
  prompts: DatabaseTypes["PromptWithExecutions"][];
  errorMessage: string | null;
  fetchPromptWithExecutions: () => Promise<void>;
}

export const usePromptsListDatabase = (): UsePromptsReturn => {
  const [prompts, setPrompts] = useState<DatabaseTypes["PromptWithExecutions"][]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchPromptWithExecutions = async () => {
    try {
      const { data, error } = await databaseOperations.fetchPromptWithExecutions();
      if (error) throw error;
      setPrompts(data);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  useEffect(() => {
    fetchPromptWithExecutions();
  }, []);

  return { prompts, errorMessage, fetchPromptWithExecutions };
};
