import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { Tables } from "../../types/database";
import { uploadToSupabaseFileFromUrl } from "../../utils/mediaUtil.ts";
import { sanitizeForURL } from "../../utils/stringUtil.ts";
import GenerateBaseImage from "./generateImagesModal/GenerateBaseImage.tsx";
import { SpokableButton } from "./SpokableButton.tsx";

interface ImageGeneratorSimpleButtonProps {
  story: Tables<"blueprint_stories">;
  baseFileName: string | undefined;
  onUploadComplete: (fileName: string) => void;
  basePrompt: string | null;
  promptModifier: string | null;
}

export default function ImageGeneratorSimpleButton({
  story,
  onUploadComplete,
  basePrompt,
  promptModifier,
  baseFileName,
}: ImageGeneratorSimpleButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageReplicateUrl, setSelectedImageReplicateUrl] = useState<string | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>("");

  const uploadGeneratedImage = async () => {
    if (!selectedImageReplicateUrl) return;
    setUploadStatus("Uploading Image...");
    const fileNameInStorage = `${sanitizeForURL(baseFileName || "generated")}-${Date.now()}.jpg`;
    await uploadToSupabaseFileFromUrl(story.id, selectedImageReplicateUrl, fileNameInStorage);
    setUploadStatus("");
    onUploadComplete(fileNameInStorage);
    closePopup();
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <>
      <SpokableButton onClick={() => setIsOpen(true)}>
        <SparklesIcon className="mr-2" />
        Generate
      </SpokableButton>

      <Dialog
        open={isOpen}
        onClose={() => {
          closePopup();
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all flex flex-col">
            <DialogTitle className="font-serif p-6 text-4xl font-bold mb-4">
              Generate Image
            </DialogTitle>
            <GenerateBaseImage
              storyId={story.id}
              initialPrompt={basePrompt}
              initialPromptModifier={promptModifier}
              onImageSelected={(imageUrl) => {
                setSelectedImageReplicateUrl(imageUrl);
              }}
            />
            <div className="p-6 bg-gray-50 border-t">
              <div className="flex justify-between items-center">
                <SpokableButton
                  onClick={() => uploadGeneratedImage()}
                  disabled={!selectedImageReplicateUrl}
                >
                  Use Selected Image
                </SpokableButton>

                {uploadStatus && <p>{uploadStatus}</p>}
                <SpokableButton onClick={() => closePopup()} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
