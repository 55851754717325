import { Table, TableBody, TableCell, TableRow } from "../catalyst/table.tsx";
import { SpokableButton } from "./SpokableButton.tsx";
import React, { ReactNode, useState } from "react";
import { generateMediaFileName, getSupabaseImageUrl } from "../../utils/mediaUtil.ts";
import {
  CHARACTER_VISUALS_LIST,
  IMAGE_TYPE_CINEMATIC_VIDEO,
  IMAGE_TYPE_EMOTION_VIDEO,
  IMAGE_TYPE_EVENT_VIDEO,
  VISUAL_TYPE_IMAGE,
  VISUAL_TYPE_VIDEO,
  VisualItem,
} from "../../constants/constant.ts";
import { CardVideo } from "./Card.tsx";

interface SpokableImageCardProps {
  imageUrl: string;
  storyId: string;
  listItems: VisualItem[];
  whiteBackground?: boolean;
  hideDelete?: boolean;
  onDelete?: () => void;
}

interface MediaContainerProps {
  children: ReactNode;
}

const VisualCard: React.FC<SpokableImageCardProps> = ({
  imageUrl,
  listItems,
  whiteBackground = false,
  hideDelete = false,
  onDelete,
  storyId,
}) => {
  const [selectedItem, setSelectedItem] = useState(listItems[0]);
  const [imageError, setImageError] = useState(false);

  const onClick = (item: VisualItem) => {
    setSelectedItem(item);
    setImageError(false);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const MediaContainer: React.FC<MediaContainerProps> = ({ children }) => (
    <div className="w-full h-80 flex items-center justify-center overflow-hidden bg-gray-200">
      {children}
    </div>
  );

  return (
    <div>
      <div className={`w-full ${whiteBackground ? "bg-white" : "bg-gray-100"} flex`}>
        <MediaContainer>
          {selectedItem && selectedItem.type == VISUAL_TYPE_IMAGE && (
            <img
              className="w-full h-full object-cover"
              alt="Image"
              src={
                imageError
                  ? "/404.jpg"
                  : getSupabaseImageUrl(storyId, selectedItem.key + imageUrl) || ""
              }
              onError={handleImageError}
            />
          )}
          {selectedItem &&
            selectedItem.type == VISUAL_TYPE_VIDEO &&
            CHARACTER_VISUALS_LIST.find((item) => item.key === selectedItem.key) && (
              <CardVideo
                className="w-full h-full object-cover"
                src={
                  getSupabaseImageUrl(
                    storyId,
                    generateMediaFileName(
                      imageUrl,
                      IMAGE_TYPE_EMOTION_VIDEO,
                      selectedItem.key,
                      true,
                    ),
                  ) || ""
                }
                fallbackSrc="/404.jpg"
              />
            )}
          {selectedItem && selectedItem.key == IMAGE_TYPE_CINEMATIC_VIDEO && (
            <CardVideo
              className="w-full h-full object-cover"
              src={
                getSupabaseImageUrl(
                  storyId,
                  generateMediaFileName(
                    imageUrl,
                    IMAGE_TYPE_CINEMATIC_VIDEO,
                    selectedItem.key,
                    true,
                  ),
                ) || ""
              }
              fallbackSrc="/404.jpg"
            />
          )}
          {selectedItem && selectedItem.key == IMAGE_TYPE_EVENT_VIDEO && (
            <CardVideo
              className="w-full h-full object-cover"
              src={
                getSupabaseImageUrl(
                  storyId,
                  generateMediaFileName(imageUrl, IMAGE_TYPE_EVENT_VIDEO, selectedItem.key, true),
                ) || ""
              }
              fallbackSrc="/404.jpg"
            />
          )}
        </MediaContainer>
        <div className="flex flex-col justify-between w-full h-80">
          <div className="overflow-y-auto flex-grow">
            <Table>
              <TableBody>
                {listItems.map((item) => (
                  <TableRow
                    key={item.key}
                    onClick={() => onClick(item)}
                    className={`cursor-pointer hover:bg-black hover:text-white ${
                      item.key == selectedItem.key
                        ? whiteBackground
                          ? "bg-gray-100"
                          : "bg-white"
                        : ""
                    }`}
                  >
                    <TableCell className="!pl-8">{item.value}</TableCell>
                    <TableCell className="text-right !pr-8"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      {!hideDelete && onDelete && (
        <div className="flex gap-4 p-4">
          <SpokableButton color="light" onClick={onDelete}>
            Delete
          </SpokableButton>
        </div>
      )}
    </div>
  );
};

export default VisualCard;
