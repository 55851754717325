import React, { useState } from "react";

import AnimatedTextsWithTransition from "./AnimatedTextsWithTransition.tsx";
import PersonaButton from "./PersonaButton.tsx";
import { useSetupAudioContext } from "../hooks/useSetupAudioContext.ts";

interface LoadingCreditComponentProps {
  onAudioEnabled: () => void;
  storyName: string | null | undefined;
}

const LoadingCreditComponent: React.FC<LoadingCreditComponentProps> = ({
  onAudioEnabled,
  storyName,
}) => {
  const [isAnimationEnded, setIsAnimationEnded] = useState(false);
  const setupAudioContext = useSetupAudioContext();

  const enableAudio = async () => {
    setupAudioContext();
    onAudioEnabled();
  };
  return (
    <div className="flex flex-col items-center h-screen text-white bg-black">
      <AnimatedTextsWithTransition
        texts={["A SPOKABLE STORY", storyName ? storyName : ""]}
        onAnimationComplete={() => setIsAnimationEnded(true)}
      />

      <div
        className={`mt-8 transition-opacity duration-1000 ${
          isAnimationEnded ? "opacity-100" : "opacity-0"
        }`}
      >
        <PersonaButton onClick={enableAudio} label={"Start"} />
      </div>
    </div>
  );
};

export default LoadingCreditComponent;
