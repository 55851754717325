import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tables, TablesInsert } from "../../types/database.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import SpokableListBox from "../../components/admin/SpokableListBox.tsx";
import {
  CHAPTER_TYPES,
  IMAGE_BUCKET_NAME,
  IMAGE_CONFIG_TYPE_BACKGROUND_IMAGE,
  IMAGE_TYPE_ORIGINAL,
  LOCALE_FRENCH,
  SOUND_BUCKET_NAME,
} from "../../constants/constant.ts";
import StorageFileSelector from "../../components/admin/StorageFileSelector.tsx";
import AutoResizeTextArea from "../../components/admin/AutoResizeTextArea.tsx";
import DatabaseSelectInput from "../../components/admin/DatabaseSelectInput.tsx";
import { useCreatePlayerStoriesAndChapters } from "../../states/storyState.tsx";
import { DEV_VITE_URL } from "../../vendor/config.ts";
import FormField from "../../components/admin/FormField.tsx";
import { Checkbox, CheckboxField } from "../../components/catalyst/checkbox.tsx";
import { Description, Label } from "../../components/catalyst/fieldset.tsx";
import { Card, CardContent, CardH1 } from "../../components/admin/Card.tsx";
import { getSupabaseImageUrl, moveCharacterImageToChapterImage } from "../../utils/mediaUtil.ts";
import { Input } from "../../components/catalyst/input.tsx";
import StorageFileAudioPlayer from "../../components/admin/StorageFileAudioPlayer.tsx";
import NumericStepper from "../../components/admin/NumericStepper.tsx";
import { SideNavLayout } from "../../components/admin/SideNavLayout.tsx";
import { capitalize } from "../../utils/stringUtil.ts";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";
import {
  useChapterImageFetcher,
  useCharacterImageFetcher,
  useFetchAllChapterData,
  useFetchChapters,
  useFetchStory,
} from "../../hooks/database/useChapter.ts";
import { SpokableButton } from "../../components/admin/SpokableButton.tsx";
import ColorPicker from "../../components/admin/ColorPicker.tsx";
import CharacterImageCard from "../../components/admin/CharacterImageCard.tsx";
import ImageGeneratorAndVariationButton from "../../components/admin/ImageGeneratorAndVariationButton.tsx";
import ChapterImageCard from "../../components/admin/ChapterImageCard.tsx";
import SoundGeneratorButton from "../../components/admin/SoundGeneratorButton.tsx";
import { getEnvironment } from "../../utils/envUtil.ts";

interface ChapterDetailProps {
  chapterId?: string | null;
  onChapterUpdate?: (updatedChapter: Tables<"blueprint_chapters">) => void;
}

function ChapterDetail({ chapterId: propsChapterId, onChapterUpdate }: ChapterDetailProps) {
  const { chapterId: paramsChapterId } = useParams<{ chapterId: string }>();
  const navigate = useNavigate();
  const { stopBackgroundMusic } = useBackgroundMusicManager();
  const [isDirty, setIsDirty] = useState(false);
  const [chapter, setChapter] = useState<Tables<"blueprint_chapters"> | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const createStoryAndChapter = useCreatePlayerStoriesAndChapters();
  const chapterId = propsChapterId || paramsChapterId;
  const [characterImages, setCharacterImages] = useState<
    Tables<"blueprint_character_images">[] | null
  >(null);
  const [chapterImages, setChapterImages] = useState<Tables<"blueprint_chapter_images">[] | null>(
    null,
  );

  const { fetchAllChapterData, ...allChapterData } = useFetchAllChapterData();
  const { fetchChapters } = useFetchChapters();
  const { fetchCharacterImages } = useCharacterImageFetcher();
  const { fetchChapterImages } = useChapterImageFetcher();
  const { fetchStory, story } = useFetchStory();

  useEffect(() => {
    fetchStory(chapter?.blueprint_story_id);
    retrieveChapterImages();
  }, [chapter]);

  useEffect(() => {
    if (chapterId) {
      stopBackgroundMusic();
      fetchAllChapterData(chapterId);
    }
  }, [chapterId]);

  useEffect(() => {
    setChapter(allChapterData.chapterData.chapter);
    if (allChapterData.chapterData.chapter?.blueprint_story_id) {
      fetchChapters(allChapterData.chapterData.chapter.blueprint_story_id);
    }
  }, [allChapterData.chapterData.chapter]);

  useEffect(() => {
    if (!allChapterData.chapterData.chapter?.blueprint_character_id) return;
    retrieveCharacterImage(allChapterData.chapterData.chapter?.blueprint_character_id);
  }, [allChapterData.chapterData.chapter?.blueprint_character_id]);

  async function retrieveCharacterImage(characterId: string) {
    const images = await fetchCharacterImages(characterId);
    setCharacterImages(images);
  }

  async function retrieveChapterImages() {
    if (!chapter) return;
    const images = await fetchChapterImages(chapter.id);
    setChapterImages(images);
  }

  const isFullPageAndNotASubComponent = (): boolean => {
    return !propsChapterId;
  };

  function reload() {
    if (chapterId) fetchAllChapterData(chapterId);
  }

  async function updateChapter() {
    if (!chapter) return;
    if (chapterId == undefined) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...chapterWithoutId } = chapter;
    const { error } = await supabase
      .from("blueprint_chapters")
      .update(chapterWithoutId)
      .eq("id", chapterId)
      .select();

    if (error) {
      setErrorMessage("Error updating chapter: " + error.message);
    } else {
      if (onChapterUpdate) onChapterUpdate(chapter);
      setErrorMessage("Saved!");
      setIsDirty(false);
      reload();
    }
  }

  async function createCharacter() {
    if (!chapter) return;
    const { data: voice, error: voiceError } = await supabase
      .from("blueprint_voices")
      .select("*")
      .eq("environment", getEnvironment())
      .limit(1)
      .single();

    if (!voice) return;

    const characterData: TablesInsert<"blueprint_characters"> = {
      name: "New character",
      voice_id: voice.id,
      blueprint_story_id: chapter?.blueprint_story_id,
    };

    const { data, error } = await supabase
      .from("blueprint_characters")
      .insert(characterData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error updating character: " + error.message);
    } else if (voiceError) {
      setErrorMessage("Error updating character: " + voiceError);
    } else {
      console.log(data);
      const createdCharacter = data as Tables<"blueprint_characters">;
      handleNavigation(`/admin/characters/${createdCharacter.id}`);
    }
  }

  const handleCancel = () => {
    if (!chapter) return;
    handleNavigation(`/admin/stories/${chapter.blueprint_story_id}`);
  };

  const handleTestChapter = async () => {
    if (!chapter) return;
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (user) {
      await createStoryAndChapter(
        {
          user_id: user.id,
          blueprint_story_id: chapter.blueprint_story_id,
          language: LOCALE_FRENCH,
        },
        chapter?.id,
      );
      let url;
      try {
        url = process.env.VITE_URL;
      } catch (e) {
        url = DEV_VITE_URL;
      }
      window.open(url + `launch/${chapter.blueprint_story_id}`, "_blank", "noopener,noreferrer");
    }
  };
  const handleNavigation = (url: string) => {
    if (isDirty) {
      const userConfirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to navigate away?",
      );
      if (!userConfirmed) {
        return;
      }
    }
    navigate(url);
  };

  async function createChapterImageFromExisting(fileName: string) {
    if (!chapter) return;

    moveCharacterImageToChapterImage(fileName, chapter.blueprint_story_id);

    const imageData: TablesInsert<"blueprint_chapter_images"> = {
      image_type: IMAGE_TYPE_ORIGINAL,
      image_url: fileName,
      blueprint_chapter_id: chapter.id,
      blueprint_story_id: chapter?.blueprint_story_id,
      has_original: true,
      has_depth_map: false,
      has_version_without_background: false,
      has_cinematic_videos: true,
      has_emotion_videos: false,
    };

    const { error } = await supabase.from("blueprint_chapter_images").insert(imageData);

    if (error) {
      setErrorMessage("Error creating Chapter images: " + error.message);
    } else {
      retrieveChapterImages();
    }
  }

  const handleContextCheckboxChange = async (contextId: string, isChecked: boolean) => {
    console.log(contextId, isChecked);
    if (!chapter) return;
    if (isChecked) {
      const contextData: TablesInsert<"blueprint_chapter_context_links"> = {
        chapter_id: chapter?.id,
        context_block_id: contextId,
      };

      const { error } = await supabase
        .from("blueprint_chapter_context_links")
        .upsert(contextData)
        .select()
        .single();

      if (error) {
        setErrorMessage("Error creating context link: " + error.message);
      }
    } else {
      const { error } = await supabase
        .from("blueprint_chapter_context_links")
        .delete()
        .eq("context_block_id", contextId)
        .eq("chapter_id", chapter.id);
      if (error) {
        setErrorMessage("Error deleting context link: " + error.message);
      }
    }
    reload();
  };

  if (!chapter || !story) {
    return <div>Loading...</div>;
  }

  return (
    <SideNavLayout showSidePanel={isFullPageAndNotASubComponent()}>
      <div className="max-w-2xl">
        <div className="top-section top-0 backdrop-blur-xl z-10 p-6">
          {errorMessage && (
            <div
              className="rounded border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}
          <div className="flex gap-6 items-stretch">
            {(allChapterData.chapterData.backgroundImages.length > 0 ||
              allChapterData.chapterData.characterImages.length > 0) && (
              <div className="flex flex-col justify-between">
                {chapterImages &&
                  chapterImages
                    .slice(0, 1)
                    .map((image) => (
                      <img
                        key={image.id}
                        alt="Chapter Image"
                        className={`object-cover ${
                          chapterImages.length === 0 ? "w-64 h-full" : "w-24 h-1/2"
                        }`}
                        src={
                          getSupabaseImageUrl(chapter.blueprint_story_id, image.image_url) ||
                          `https://api.dicebear.com/9.x/shapes/svg?seed=${chapterId}`
                        }
                      />
                    ))}
                {characterImages &&
                  characterImages
                    .slice(0, 1)
                    .map((image) => (
                      <img
                        alt="Character Image"
                        key={image.id}
                        className={`object-cover ${
                          characterImages.length === 0 ? "w-64 h-full" : "w-24 h-1/2"
                        }`}
                        src={
                          getSupabaseImageUrl(chapter.blueprint_story_id, image.image_url) ||
                          `https://api.dicebear.com/9.x/lorelei-neutral/svg?seed=${chapterId}`
                        }
                      />
                    ))}
              </div>
            )}
            <div className="flex flex-col justify-between w-full">
              <div>
                <div className="text-sm text-gray-500 mb-2">Chapter</div>
                <h1 className="text-5xl font-bold mb-2">{capitalize(chapter.chapter_name)}</h1>
                <div className="text-xl mb-4">{capitalize(chapter.chapter_type)}</div>
              </div>
              <div className="flex gap-2">
                <SpokableButton onClick={handleTestChapter}>Test Chapter</SpokableButton>
                <SpokableButton onClick={updateChapter}>Save</SpokableButton>
                {isFullPageAndNotASubComponent() && (
                  <SpokableButton onClick={handleCancel}>Back</SpokableButton>
                )}
              </div>
            </div>
          </div>
        </div>

        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Basic" id="basic" />

            <FormField label="Chapter Name">
              <Input
                type="text"
                id="chapter_name"
                value={capitalize(chapter.chapter_name) || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, chapter_name: e.target.value });
                  setIsDirty(true);
                }}
              />
            </FormField>

            <FormField label="Scene Date">
              <Input
                type="datetime-local"
                id="scene_date"
                value={chapter.scene_date?.toString().slice(0, 16) || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, scene_date: e.target.value ? e.target.value : null });
                  setIsDirty(true);
                }}
              />
            </FormField>

            <FormField label="Chapter Setting">
              <AutoResizeTextArea
                value={chapter.chapter_setting || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, chapter_setting: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Chapter objectives">
              <AutoResizeTextArea
                value={chapter.chapter_objectives || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, chapter_objectives: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Chapter Traps">
              <AutoResizeTextArea
                value={chapter.chapter_traps || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, chapter_traps: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Chapter Type">
              <SpokableListBox
                options={CHAPTER_TYPES}
                value={
                  CHAPTER_TYPES.find((type) => type.key === chapter.chapter_type) ||
                  CHAPTER_TYPES[0]
                }
                onChange={(selectedType) => {
                  setChapter({ ...chapter, chapter_type: selectedType.key });
                  setIsDirty(true);
                }}
              />
            </FormField>

            <FormField label="Grouped with">
              <DatabaseSelectInput
                table="blueprint_chapters"
                keyColumn="id"
                labelColumn="chapter_name"
                value={chapter.parent_chapter_id || ""}
                onChange={(value) => {
                  setChapter({ ...chapter, parent_chapter_id: value });
                  setIsDirty(true);
                }}
                placeholder="Select a chapter"
                addNullValueOption={true}
                storyId={chapter.blueprint_story_id}
                conditions={[
                  { column: "parent_chapter_id", value: null },
                  { column: "id", value: chapter.id, notOperator: true },
                ]}
              />
            </FormField>
            <FormField label="Belong to scene">
              <DatabaseSelectInput
                table="blueprint_scenes"
                keyColumn="id"
                labelColumn="name"
                value={chapter.scene_id || ""}
                onChange={(value) => {
                  setChapter({ ...chapter, scene_id: value });
                  setIsDirty(true);
                }}
                placeholder="Select a scene"
                addNullValueOption={false}
                storyId={chapter.blueprint_story_id}
                storyIdColumn={"story_id"}
              />
            </FormField>
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Character" id="character" />

            <FormField>
              <DatabaseSelectInput
                table="blueprint_characters"
                keyColumn="id"
                labelColumn="name"
                storyId={chapter.blueprint_story_id}
                value={chapter.blueprint_character_id || ""}
                onChange={(value) => {
                  setChapter({ ...chapter, blueprint_character_id: value || "" });
                  if (value) retrieveCharacterImage(value);
                  setIsDirty(true);
                }}
                placeholder="Select a character"
              />

              <div className="mt-6">
                <SpokableButton color={"light"} onClick={createCharacter}>
                  Create New Character
                </SpokableButton>
                {chapter.blueprint_character_id && (
                  <SpokableButton
                    className="ml-6"
                    onClick={() => {
                      handleNavigation(`/admin/characters/${chapter?.blueprint_character_id}`);
                    }}
                  >
                    Edit
                  </SpokableButton>
                )}
              </div>
            </FormField>
            <FormField>
              <ul>
                {characterImages &&
                  characterImages.map((image) => {
                    return (
                      <li key={image.id} className="mb-8">
                        <CharacterImageCard
                          storyId={chapter.blueprint_story_id}
                          image={image}
                          hideDelete={true}
                          whiteBackground={true}
                        />
                      </li>
                    );
                  })}
              </ul>
            </FormField>

            <FormField label="Starting Dialogue">
              <AutoResizeTextArea
                value={chapter.starting_dialogue || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, starting_dialogue: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Scene Example Dialogues">
              <AutoResizeTextArea
                value={chapter.engine_character_scene_example_dialogues || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, engine_character_scene_example_dialogues: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Scene Agenda">
              <AutoResizeTextArea
                value={chapter.engine_character_scene_agenda || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, engine_character_scene_agenda: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Scene Description">
              <AutoResizeTextArea
                value={chapter.engine_character_scene_description || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, engine_character_scene_description: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>

            <FormField label="Scene Arc">
              <AutoResizeTextArea
                value={chapter.engine_character_scene_arc || ""}
                onChange={(e) => {
                  setChapter({ ...chapter, engine_character_scene_arc: e });
                  setIsDirty(true);
                }}
              ></AutoResizeTextArea>
            </FormField>
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Sound" id="sound" />
            <FormField label="Intro">
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <StorageFileSelector
                  bucketName={SOUND_BUCKET_NAME}
                  storyId={chapter.blueprint_story_id}
                  onStorageFileSelected={(storageFileUrl) => {
                    setChapter({ ...chapter, sound_intro: storageFileUrl });
                    setIsDirty(true);
                  }}
                  value={chapter.sound_intro}
                />
                <SoundGeneratorButton
                  storyId={story.id}
                  onUploadComplete={(storageFileUrl) => {
                    setChapter({ ...chapter, sound_intro: storageFileUrl });
                    setIsDirty(true);
                  }}
                  prompt={chapter.opening_sound_prompt}
                />
              </div>
              {chapter.sound_intro && (
                <>
                  <StorageFileAudioPlayer
                    bucketName={SOUND_BUCKET_NAME}
                    storyId={chapter.blueprint_story_id}
                    fileName={chapter.sound_intro}
                  />
                </>
              )}
            </FormField>

            <FormField label="Background">
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <StorageFileSelector
                  bucketName={SOUND_BUCKET_NAME}
                  storyId={chapter.blueprint_story_id}
                  onStorageFileSelected={(storageFileUrl) => {
                    setChapter({ ...chapter, sound_outro: storageFileUrl });
                    setIsDirty(true);
                  }}
                  value={chapter.sound_outro}
                />
                <SoundGeneratorButton
                  storyId={story.id}
                  onUploadComplete={(storageFileUrl) => {
                    setChapter({ ...chapter, sound_outro: storageFileUrl });
                    setIsDirty(true);
                  }}
                  prompt={chapter.background_sound_prompt}
                />
              </div>
              {chapter.sound_outro && (
                <StorageFileAudioPlayer
                  bucketName={SOUND_BUCKET_NAME}
                  storyId={chapter.blueprint_story_id}
                  fileName={chapter.sound_outro}
                />
              )}
            </FormField>
            <div className={`w-full ${isFullPageAndNotASubComponent() ? "lg:w-1/3" : ""} `}>
              <div className="flex justify-between">
                <FormField label="Sound Volume">
                  <NumericStepper
                    value={chapter.sound_intro_volume}
                    onChange={(e) => {
                      setChapter({ ...chapter, sound_intro_volume: e });
                      setIsDirty(true);
                    }}
                    label={""}
                  />
                </FormField>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Visuals" id="image" />

            <FormField>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <StorageFileSelector
                  bucketName={IMAGE_BUCKET_NAME}
                  storyId={story.id}
                  onStorageFileSelected={(storageFileUrl) =>
                    createChapterImageFromExisting(storageFileUrl)
                  }
                  value={story.story_logo_image}
                  hideImagePreview={true}
                  useSimpleSelect={true}
                  showUploader={false}
                />
                <ImageGeneratorAndVariationButton
                  storyId={chapter.blueprint_story_id}
                  onUploadComplete={() => retrieveChapterImages()}
                  generationType={IMAGE_CONFIG_TYPE_BACKGROUND_IMAGE}
                  basePrompt={chapter.image_generation_prompt}
                  promptModifier={story.image_prompt_style_key}
                  baseFileName={chapter.chapter_name}
                  characterOrChapterId={chapter.id}
                  videoPrompt={chapter.video_generation_prompt}
                  videoEventPrompt={chapter.video_generation_event_prompt}
                  imageReferenceFileName={
                    chapterImages
                      ? chapterImages[Math.floor(Math.random() * chapterImages.length)]?.image_url
                      : story.background_image || null
                  }
                />
              </div>
            </FormField>

            {chapterImages && chapterImages.length > 0 && (
              <FormField label="Selected Images:">
                <ul>
                  {chapterImages.map((image) => {
                    return (
                      <li key={image.id}>
                        <ChapterImageCard
                          storyId={chapter.blueprint_story_id}
                          image={image}
                          whiteBackground={true}
                          onRefreshNeeded={retrieveChapterImages}
                        />
                      </li>
                    );
                  })}
                </ul>
              </FormField>
            )}

            <div className={`w-full ${isFullPageAndNotASubComponent() ? "lg:w-1/2" : ""} `}>
              <div className="flex justify-between">
                <FormField label="Background">
                  <ColorPicker
                    initialColor={chapter.background_rgba_color || ""}
                    onColorChange={(color) => {
                      setChapter({ ...chapter, background_rgba_color: color });
                      setIsDirty(true);
                    }}
                  ></ColorPicker>
                </FormField>
                <FormField label="Text">
                  <ColorPicker
                    initialColor={chapter.text_rgba_color || ""}
                    onColorChange={(color) => {
                      setChapter({ ...chapter, text_rgba_color: color });
                      setIsDirty(true);
                    }}
                  ></ColorPicker>
                </FormField>
                <FormField label="Controls">
                  <ColorPicker
                    initialColor={chapter.control_rgba_color || ""}
                    onColorChange={(color) => {
                      setChapter({ ...chapter, control_rgba_color: color });
                      setIsDirty(true);
                    }}
                  ></ColorPicker>
                </FormField>
              </div>
            </div>
          </CardContent>
        </Card>
        {allChapterData.chapterData.contextBlocks.length > 0 && (
          <Card className="pb-20" isFullWidth={true}>
            <CardContent>
              <CardH1 label="Context" id="context" />
              <ul className="grid grid-cols-2 gap-4">
                {allChapterData.chapterData.contextBlocks.map((context) => {
                  const isLinked = allChapterData.chapterData.contextBlockLinks.some(
                    (link) => link.context_block_id === context.id,
                  );

                  return (
                    <li key={context.id}>
                      <CheckboxField>
                        <Checkbox
                          name="discoverability"
                          value="show_on_events_page"
                          checked={isLinked}
                          onChange={(isChecked) =>
                            handleContextCheckboxChange(context.id, isChecked)
                          }
                        />
                        <Label>
                          {context.context_block_name}{" "}
                          <Link to={`/admin/contexts/${story.id}/${context.id}`}>(edit)</Link>
                        </Label>
                        <Description className="overflow-hidden text-ellipsis whitespace-nowrap">
                          {context.context_block_description}
                        </Description>
                      </CheckboxField>
                    </li>
                  );
                })}
              </ul>
            </CardContent>
          </Card>
        )}
      </div>
    </SideNavLayout>
  );
}

export default ChapterDetail;
