import React, { ReactNode, useEffect, useRef, useState } from "react";

interface TooltipProps {
  children: React.ReactElement;
  content: ReactNode;
  className?: string;
}

const DefaultTooltipContent: React.FC<{ content: string }> = ({ content }) => (
  <div
    className="max-w-prose overflow-hidden text-ellipsis"
    style={{
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
    }}
  >
    {content}
  </div>
);

const Tooltip: React.FC<TooltipProps> = ({ children, content, className = "" }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isVisible && triggerRef.current && tooltipRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      setPosition({
        top: triggerRect.bottom + window.scrollY,
        left: Math.max(
          0,
          Math.min(
            triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2 + window.scrollX,
            window.innerWidth - tooltipRect.width,
          ),
        ),
      });
    }
  }, [isVisible]);

  const renderContent = () => {
    if (typeof content === "string") {
      return <DefaultTooltipContent content={content} />;
    }
    return content;
  };

  return (
    <>
      <div
        ref={triggerRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div
          ref={tooltipRef}
          className={`fixed z-50 p-4 bg-white border border-gray-200 rounded-lg shadow-lg ${className}`}
          style={{
            top: `${position.top}px`,
            left: `${position.left}px`,
            maxWidth: typeof content === "string" ? "50vw" : "300px",
            maxHeight: "auto",
          }}
        >
          {renderContent()}
        </div>
      )}
    </>
  );
};

export default Tooltip;
