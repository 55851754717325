import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import GenerateBeatIdeas from "./GenerateBeatIdeas.tsx";
import { Tables } from "../../../types/database.ts";
import ReviewBranchIdea from "./ReviewIdeas.tsx";
import GenerateOnePager from "./GenerateOnePager.tsx";
import GenerateBeatSheet from "./GenerateBeatSheet.tsx";
import SaveAll from "./SaveAll.tsx";
import { Badge } from "../../catalyst/badge.tsx";
import { mergeBeatsAndBeatsheets } from "../../../utils/valueObject.ts";
import { SpokableButton } from "../SpokableButton.tsx";

export interface GenerateBeatModalProps {
  story: Tables<"blueprint_stories"> | null;
  beats: Tables<"blueprint_beats">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  branches: Tables<"blueprint_branches">[];
  characters: Tables<"blueprint_characters">[];
  clickedBranch: Tables<"blueprint_branches"> | null;
  clickedBeatsheet: Tables<"blueprint_beatsheets"> | null;
}

const GenerateBeatModal: React.FC<GenerateBeatModalProps> = ({
  story,
  beats,
  beatsheets,
  branches,
  characters,
  clickedBranch,
  clickedBeatsheet,
}) => {
  const {
    isOpen,
    stepIndex,
    setStepIndex,
    selectedIdea,
    setSelectedIdea,
    handleClose,
    ideas,
    setIdeas,
    ideasStatus,
    generateIdeas,
    onePager,
    setOnePager,
    onePagerStatus,
    generateOnePager,
    beatsheet,
    setBeatsheet,
    beatsheetStatus,
    generateBeatsheet,
  } = useGenerateSteps();

  const maxStepIndex = 4;

  const renderStepContent = () => {
    switch (stepIndex) {
      case 0:
        return (
          <GenerateBeatIdeas
            ideas={ideas}
            status={ideasStatus}
            generateIdeas={generateIdeas}
            setSelectedIdea={setSelectedIdea}
            selectedIdea={selectedIdea}
            setIdeas={setIdeas}
            beats={beats}
            beatsheets={beatsheets}
            branches={branches}
            characters={characters}
            clickedBranch={clickedBranch}
            clickedBeatsheet={clickedBeatsheet}
          />
        );
      case 1:
        return <ReviewBranchIdea selectedIdea={selectedIdea} setSelectedIdea={setSelectedIdea} />;
      case 2:
        return (
          <GenerateOnePager
            selectedIdea={selectedIdea}
            onePager={onePager}
            status={onePagerStatus}
            setOnePager={setOnePager}
            generateOnePager={generateOnePager}
            beats={beats}
            beatsheets={beatsheets}
            branches={branches}
            characters={characters}
            clickedBranch={clickedBranch}
            clickedBeatsheet={clickedBeatsheet}
          />
        );
      case 3:
        return (
          <GenerateBeatSheet
            onePager={onePager}
            selectedIdea={selectedIdea}
            beatsheet={beatsheet}
            status={beatsheetStatus}
            setBeatsheet={setBeatsheet}
            generateBeatsheet={generateBeatsheet}
            beats={beats}
            beatsheets={beatsheets}
            branches={branches}
            characters={characters}
            clickedBranch={clickedBranch}
            clickedBeatsheet={clickedBeatsheet}
          />
        );
      case 4:
        return (
          <SaveAll
            beatSheet={beatsheet}
            onePager={onePager}
            story={story}
            clickedBranch={clickedBranch}
            clickedBeatsheet={clickedBeatsheet}
            handleClose={handleClose}
            transitionDescription={selectedIdea?.idea.divergence_point}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 font-nunito" onClose={handleClose}>
        <div className="fixed inset-0 bg-black/60" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6">
            <Dialog.Panel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all">
              {clickedBranch && clickedBeatsheet && (
                <div className="pt-6 ml-6">
                  <Badge>{clickedBranch.name}</Badge>
                  <Badge className="ml-2">
                    {mergeBeatsAndBeatsheets(beats, [clickedBeatsheet]).pop()?.beat_name}
                  </Badge>
                </div>
              )}
              <div className="p-6">
                {renderStepContent()}
                <div className="mt-4 flex justify-between">
                  <div>
                    {selectedIdea && stepIndex > 0 && (
                      <SpokableButton
                        color="light"
                        onClick={() => setStepIndex(Math.max(0, stepIndex - 1))}
                        className="mr-2"
                      >
                        Previous
                      </SpokableButton>
                    )}
                    {selectedIdea && stepIndex < maxStepIndex && (
                      <SpokableButton onClick={() => setStepIndex(stepIndex + 1)}>
                        Next
                      </SpokableButton>
                    )}
                  </div>
                  <SpokableButton color="light" onClick={handleClose}>
                    Close
                  </SpokableButton>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GenerateBeatModal;
