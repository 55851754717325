import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalImageGenerateState } from "../../../states/ModalState.ts";
import { useGenerateImages } from "./useGenerateImagesHooks.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalImageGenerateState);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const {
    data: images,
    status: imageStatus,
    callApi: generateImages,
    setData: setImages,
  } = useGenerateImages();

  const handleClose = () => {
    setIsOpen(false);
    setStepIndex(0);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    images,
    imageStatus,
    generateImages,
    setImages,

    handleClose,
  };
};
