import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { audioContextState } from "../states/audioDataState";

interface UseAudioRecorderReturn {
  startRecording: () => Promise<void>;
  stopRecording: () => Promise<void>;
  isRecording: boolean;
  rms: number;
}

export const useAudioRecorder = (
  onRecordingComplete: (audioBlob: Blob) => void,
): UseAudioRecorderReturn => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audioContext] = useRecoilState(audioContextState);
  const scriptProcessorRef = useRef<ScriptProcessorNode | null>(null);
  const audioChunksRef = useRef<Float32Array[]>([]);
  const [rms, setRMS] = useState<number>(0);

  useEffect(() => {
    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);

  const startRecording = async (): Promise<void> => {
    if (!navigator.mediaDevices || !window.AudioContext || !audioContext) {
      alert("Audio recording is not supported in this browser.");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const source = audioContext.createMediaStreamSource(stream);
      const scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);
      scriptProcessorRef.current = scriptProcessor;

      audioChunksRef.current = [];
      const volumeThreshold = 0.01;
      let silenceDuration = 0; // in milliseconds
      const silenceThreshold = 3 * 1000; // 3 seconds in milliseconds
      const sampleRate = audioContext.sampleRate; // usually 44100 or 48000 Hz

      scriptProcessor.onaudioprocess = (event) => {
        const { inputBuffer } = event;
        const buffer = inputBuffer.getChannelData(0);
        const bufferSize = buffer.length;
        let sum = 0;

        // Calculate RMS of the buffer
        for (let i = 0; i < bufferSize; i++) {
          sum += buffer[i] * buffer[i];
        }

        const rms = Math.sqrt(sum / bufferSize);
        setRMS(rms);
        if (rms >= volumeThreshold) {
          audioChunksRef.current.push(buffer.slice());
          silenceDuration = 0;
        } else {
          console.log("silence detected");
          silenceDuration += (bufferSize / sampleRate) * 1000;
        }

        if (silenceDuration > silenceThreshold) {
          console.log("silence detected for more than the threshold");
          stopRecording();
        }
      };

      source.connect(scriptProcessor);
      scriptProcessor.connect(audioContext.destination);

      setIsRecording(true);
    } catch (err) {
      console.error("Error accessing the microphone:", err);
    }
  };

  const stopRecording = async (): Promise<void> => {
    if (!audioContext || !scriptProcessorRef.current) return;

    scriptProcessorRef.current.disconnect();
    scriptProcessorRef.current = null;

    const sampleRate = audioContext.sampleRate;
    const mergedSamples = mergeAudioChunks(audioChunksRef.current);
    const audioBlob = await encodeAudioData(mergedSamples, sampleRate);
    if (audioBlob) {
      onRecordingComplete(audioBlob);
      const newAudioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(newAudioUrl);
    }
    setIsRecording(false);
  };

  const mergeAudioChunks = (audioChunks: Float32Array[]): Int16Array => {
    const numSamples = audioChunks.reduce((sum, chunk) => sum + chunk.length, 0);
    const mergedSamples = new Int16Array(numSamples);
    let offset = 0;
    audioChunks.forEach((chunk) => {
      for (let i = 0; i < chunk.length; i++) {
        const val = Math.max(-1, Math.min(1, chunk[i])); // Clamp the value between -1 and 1
        mergedSamples[offset++] = val < 0 ? val * 0x8000 : val * 0x7fff; // Convert to Int16
      }
    });
    return mergedSamples;
  };

  const encodeAudioData = async (samples: Int16Array, sampleRate: number): Promise<Blob | null> => {
    try {
      const lamejs = await import("lamejstmp");
      const mp3Encoder = new lamejs.Mp3Encoder(1, sampleRate, 128); // Mono channel, 44.1 kHz sample rate, 128 kbps
      const mp3Data: Int8Array[] = [];
      let mp3Buffer = mp3Encoder.encodeBuffer(samples); // Encode MP3
      if (mp3Buffer.length > 0) {
        mp3Data.push(new Int8Array(mp3Buffer));
      }
      mp3Buffer = mp3Encoder.flush(); // Finish writing MP3
      if (mp3Buffer.length > 0) {
        mp3Data.push(new Int8Array(mp3Buffer));
      }
      return new Blob(mp3Data, { type: "audio/mp3" });
    } catch (error) {
      console.error("Failed to encode MP3:", error);
      return null;
    }
  };
  return { startRecording, stopRecording, isRecording, rms };
};
