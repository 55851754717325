import React, { ReactNode } from "react";

interface FormFieldProps {
  label?: string;
  children: ReactNode;
  className?: string;
  labelClassName?: string;
  contentClassName?: string;
  divider?: boolean;
  isSerif?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  children,
  className = "mb-8",
  labelClassName = "block mb-2 text-zinc-500",
  contentClassName = "",
  divider = false,
  isSerif = false,
}) => {
  return (
    <div className={className}>
      {divider && <div className="mb-16" />}
      {label && (
        <label className={` ${isSerif ? "font-serif text-2xl" : ""} ${labelClassName}`}>
          {label}
        </label>
      )}
      <div className={contentClassName}>{children}</div>
    </div>
  );
};

export default FormField;
