// Frontend hook (useVideoConversion.ts)
import { useState } from "react";
import { DEV_FAST_API_HTTP } from "../../../vendor/config.ts";

interface ConversionState {
  isConverting: boolean;
  convertedVideoUrl: string;
  uploadProgress: number;
  error: string | null;
}

interface ConversionResponse {
  videoUrl: string;
  message: string;
}

export const useVideoConversion = () => {
  const [state, setState] = useState<ConversionState>({
    isConverting: false,
    convertedVideoUrl: "",
    uploadProgress: 0,
    error: null,
  });

  const convertVideo = async (
    videoBlob: Blob,
    storyId: string,
    startFrame: number,
    endFrame: number,
  ): Promise<string | null> => {
    setState((prev) => ({ ...prev, isConverting: true, error: null }));

    try {
      console.log("storyId", storyId);
      const formData = new FormData();
      formData.append("video", videoBlob, "raw-video.webm");
      formData.append("story_id", storyId);
      formData.append("start_frame", startFrame.toString());
      formData.append("end_frame", endFrame.toString());

      const apiEndpoint = import.meta.env.VITE_FAST_API_HTTP || DEV_FAST_API_HTTP;

      const response = await fetch(`${apiEndpoint}convert_video`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ message: response.statusText }));
        throw new Error(errorData.message || `Conversion failed: ${response.statusText}`);
      }

      const data: ConversionResponse = await response.json();

      if (!data.videoUrl) {
        throw new Error("No video URL received from server");
      }

      setState((prev) => ({
        ...prev,
        isConverting: false,
        convertedVideoUrl: data.videoUrl,
        uploadProgress: 100,
      }));

      return data.videoUrl;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "Unknown error occurred";
      setState((prev) => ({
        ...prev,
        isConverting: false,
        error: errorMessage,
        uploadProgress: 0,
      }));
      console.error("Video conversion error:", error);
      return null;
    }
  };

  return {
    state,
    convertVideo,
  };
};
