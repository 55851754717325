// LoadingSpinner.tsx
import React from "react";
import { chaoticOrbit } from "ldrs";

chaoticOrbit.register();
export const LoadingSpinner: React.FC = () => (
  <p className="text-sm text-gray-500m mt-4">
    <l-chaotic-orbit size="35" speed="1.5" color="black"></l-chaotic-orbit>
  </p>
);
