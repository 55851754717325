import { Tables, TablesInsert, TablesUpdate } from "../types/database.ts";
import { createAtom, useTable } from "./databaseTableState.ts";
import { useCreatePlayerChapters, useSelectStartingBlueprintChapters } from "./chapterState.tsx";
import { useRecoilCallback } from "recoil";
import { supabase } from "../vendor/supabaseClient.ts";

export const playerStoriesAtom = createAtom<Tables<"player_stories">[]>("playerStoriesAtom", []);

export const blueprintStoryIdState = createAtom<string | null | undefined>(
  "blueprintStoryIdState",
  null,
);

export const errorAtom = createAtom<string | null>("errorAtom", null);

export const storyBeatsState = createAtom<Tables<"blueprint_beats">[] | null>(
  "storyBeatsState",
  null,
);

export const useCreatePlayerStories = () => {
  const { createItem } = useTable<
    Tables<"player_stories">,
    TablesInsert<"player_stories">,
    TablesUpdate<"player_stories">
  >("player_stories", playerStoriesAtom, errorAtom);
  return createItem;
};

export const useCreatePlayerStoriesAndChapters = () => {
  const createPlayerStory = useCreatePlayerStories();
  const selectStartingBlueprintChapter = useSelectStartingBlueprintChapters();
  const createChapter = useCreatePlayerChapters();
  const selectLastStory = useSelectPlayerStories();

  return useRecoilCallback(
    ({ set }) =>
      async (newPlayerStory: TablesInsert<"player_stories">, chapterId?: string) => {
        try {
          const createdStories = await createPlayerStory(newPlayerStory);
          console.log("createdStories", createdStories);
          if (createdStories && createdStories.length > 0) {
            const createdStory = createdStories[0];
            let blueprintChapterId;
            // If chapterId is provided, use it. Otherwise, determine it from the starting blueprint chapters.
            if (chapterId) {
              blueprintChapterId = chapterId;
            } else {
              const startingBluePrintChapters = await selectStartingBlueprintChapter(
                createdStory.blueprint_story_id,
              );
              console.log("startingBluePrintChapters", startingBluePrintChapters);
              if (startingBluePrintChapters && startingBluePrintChapters.length > 0) {
                const startingBluePrintChapter = startingBluePrintChapters[0];
                blueprintChapterId = startingBluePrintChapter.id;
              } else {
                window.alert("Starting chapter not found");
                return;
              }
            }
            const newChapter: TablesInsert<"player_chapters"> = {
              blueprint_chapter_id: blueprintChapterId,
              player_story_id: createdStory.id,
              user_id: newPlayerStory.user_id,
            };
            console.log("about to create chapter");
            await createChapter(newChapter);
            console.log("chapter created");

            // Update the atom with the new story
            const lastStory = await selectLastStory();
            if (lastStory) {
              set(playerStoriesAtom, (prevStories: Tables<"player_stories">[]) => {
                return [...prevStories, lastStory as unknown as Tables<"player_stories">];
              });
            }
          }
        } catch (error) {
          console.error("Error in creating player story and chapters: ", error);
          set(errorAtom, "Error in creating player story and chapters");
        }
      },
    [createPlayerStory, selectStartingBlueprintChapter, createChapter, selectLastStory],
  );
};

export const useSelectPlayerStories = () => {
  const { selectMostRecentItems } = useTable<
    Tables<"player_stories">,
    TablesInsert<"player_stories">,
    TablesUpdate<"player_stories">
  >("player_stories", playerStoriesAtom, errorAtom);
  return selectMostRecentItems;
};
export const useSelectBlueprintStory = async (storyId: string) => {
  const { data, error } = await supabase
    .from("blueprint_stories")
    .select("*")
    .eq("id", storyId)
    .returns<Tables<"blueprint_stories">[]>()
    .single();

  if (error) {
    console.log("Error fetching story: " + error.message);
  } else {
    return data;
  }
};
