import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  blueprintStoryIdState,
  playerStoriesAtom,
  useCreatePlayerStoriesAndChapters,
  useSelectBlueprintStory,
  useSelectPlayerStories,
} from "../states/storyState.tsx";
import { supabase } from "../vendor/supabaseClient.ts";
import TextSwitcher from "../components/TextSwitcher.tsx";

import { isChapterStartedState } from "../states/chapterState.tsx";
import { useNavigateWithQueryParams } from "../hooks/useNavigateWithQueryParams.ts";
import { LOCALE_ENGLISH, LOCALE_FRENCH } from "../constants/constant.ts";
import { formatRGBAColor } from "../utils/color.ts";
import { Tables } from "../types/database.ts";
import LoadingCreditAndAudioEnabling from "../components/LoadingCreditAndAudioEnabling.tsx";
import { getSupabaseImageUrl } from "../utils/mediaUtil.ts";
import useBackgroundMusicManager from "../hooks/useBackgroundMusicManager.ts";
import { audioContextState } from "../states/audioDataState.ts";
import { useParams } from "react-router-dom";

const LaunchScreen: React.FC = () => {
  const [, setStoryId] = useRecoilState(blueprintStoryIdState);
  const { storyId } = useParams<{
    storyId: string;
  }>();

  useEffect(() => {
    setStoryId(storyId);
  }, [storyId]);

  const navigate = useNavigateWithQueryParams();
  const stories = useRecoilValue(playerStoriesAtom);
  const [, setIsChapterStarted] = useRecoilState(isChapterStartedState);
  const [blueprintStory, setBlueprintStory] = useState<Tables<"blueprint_stories">>();
  const [language, setLanguage] = useState("french");
  const [isLoading, setIsLoading] = useState(true);
  const [showMainComponent, setShowMainComponent] = useState(false);
  const audioContext = useRecoilValue(audioContextState);
  const [backgroundMusicIsSetup, setBackgroundMusicIsSetup] = useState(false);
  const fetchLastStory = useSelectPlayerStories();
  const fetchBlueprintStory = useSelectBlueprintStory;
  const createStoryAndChapter = useCreatePlayerStoriesAndChapters();
  const { loadBackgroundMusic } = useBackgroundMusicManager();

  useEffect(() => {
    if (blueprintStory && audioContext && !backgroundMusicIsSetup) {
      loadBackgroundMusic({
        storyId: blueprintStory.id,
        fileName: blueprintStory.sound_intro,
        volume: blueprintStory.sound_intro_volume,
      });
      setBackgroundMusicIsSetup(true);
    }
  }, [blueprintStory, audioContext]);

  useEffect(() => {
    if (!storyId) return;
    Promise.all([
      fetchLastStory().catch(console.error),
      fetchBlueprintStory(storyId).then((story) => setBlueprintStory(story)),
    ]).then(() => {
      setIsLoading(false);
    });
  }, []);

  const startANewStoryAndNavigateToGameScreen = async () => {
    if (!storyId) return;
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (user) {
      await createStoryAndChapter({
        user_id: user.id,
        blueprint_story_id: storyId,
        language: language,
      });

      navigateToGameScreen();
    }
  };

  const navigateToGameScreen = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsChapterStarted(false);

      navigate("/game");
    } catch (error) {
      console.error("Access to the microphone was denied or an error occurred:", error);
      alert("Need to accept microphone access");
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <h1>Loading</h1>
      </div>
    );
  }

  if (!showMainComponent) {
    return (
      <LoadingCreditAndAudioEnabling
        onAudioEnabled={() => setShowMainComponent(true)}
        storyName={blueprintStory?.name}
      />
    );
  }

  return (
    <div
      className="pl-12 pr-12 pt-40 text-4xl flex flex-col h-screen relative"
      style={{
        backgroundColor: blueprintStory
          ? formatRGBAColor(blueprintStory.background_rgba_color)
          : "black",
      }}
    >
      <div className="aspect-w-1 aspect-h-1">
        <img
          src={getSupabaseImageUrl(blueprintStory?.id, blueprintStory?.background_image) as string}
          className="absolute inset-0 w-full h-full object-cover z-0"
          alt=""
        />
      </div>
      {blueprintStory?.story_logo_image && (
        <div className="absolute inset-0 flex justify-center items-center z-10">
          <img
            src={
              blueprintStory?.story_logo_image
                ? (getSupabaseImageUrl(
                    blueprintStory?.id,
                    blueprintStory?.story_logo_image,
                  ) as string)
                : ""
            }
            alt="Centered Image"
            className="w-auto h-auto max-w-[20%] max-h-[20%] object-contain"
          />
        </div>
      )}
      <div className="relative z-10">
        <h1
          className="font-sans [writing-mode:vertical-lr]"
          style={{
            color: blueprintStory ? formatRGBAColor(blueprintStory.text_rgba_color) : "black",
          }}
        >
          {blueprintStory?.name}
        </h1>
        <p
          style={{
            color: blueprintStory ? formatRGBAColor(blueprintStory.text_rgba_color) : "black",
          }}
        >
          Play in
          <TextSwitcher
            className={"text-white"}
            options={[
              { value: LOCALE_FRENCH, label: "French" },
              { value: LOCALE_ENGLISH, label: "English" },
            ]}
            onChange={setLanguage}
            textRgbaColor={
              blueprintStory ? formatRGBAColor(blueprintStory.control_rgba_color) : "white"
            }
          />
        </p>
        <div
          style={{
            color: blueprintStory ? formatRGBAColor(blueprintStory.text_rgba_color) : "black",
          }}
        >
          You can{" "}
          {stories.length > 0 && (
            <span>
              <button
                style={{
                  color: blueprintStory
                    ? formatRGBAColor(blueprintStory.control_rgba_color)
                    : "white",
                }}
                className="opacity-100 hover:opacity-50 transition-opacity duration-500"
                onClick={navigateToGameScreen}
              >
                continue
              </button>{" "}
              or{" "}
            </span>
          )}
          <button
            className="opacity-100 hover:opacity-50 transition-opacity duration-500"
            onClick={startANewStoryAndNavigateToGameScreen}
            style={{
              color: blueprintStory ? formatRGBAColor(blueprintStory.control_rgba_color) : "white",
            }}
          >
            start
          </button>{" "}
          a new game. <br />
          <br />
          <button onClick={() => navigate("/admin")}>Admin</button>
        </div>
      </div>
    </div>
  );
};

export default LaunchScreen;
