import React, { useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/16/solid";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { SpokableButton } from "../SpokableButton.tsx";
import VideoCapture from "./VideoCapture.tsx";
import { Tables, TablesInsert } from "../../../types/database.ts";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { getCustomEmotionVideoFileName, getSupabaseImageUrl } from "../../../utils/mediaUtil.ts";
import { VISUAL_TYPE_VIDEO } from "../../../constants/constant.ts";
import { CardText } from "../Card.tsx";

interface MediaUploadCardProps {
  aVideoAlreadyExist: boolean;
  className?: string;
  storyId: string;
  emotionKey: string;
  collection: Tables<"blueprint_driving_video_collections">;
  onVideoCreated: () => void;
  baseImageFile: File;
}

const MediaUploadCard: React.FC<MediaUploadCardProps> = ({
  aVideoAlreadyExist,
  className = "",
  storyId,
  emotionKey,
  collection,
  onVideoCreated,
  baseImageFile,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const closePopup = () => {
    setIsOpen(false);
  };

  async function onVideoDone() {
    const newVideo: TablesInsert<"blueprint_driving_videos"> = {
      blueprint_driving_video_collection_id: collection.id,
      blueprint_story_id: storyId,
      video_key: emotionKey,
    };
    const { error } = await supabase.from("blueprint_driving_videos").insert(newVideo);
    if (error) {
      console.log("Error creating collection: " + error.message);
    } else {
      onVideoCreated();
      closePopup();
    }
  }

  return (
    <>
      <div
        className={`relative w-full h-full bg-red-500 cursor-pointer ${className}`}
        onClick={() => setIsOpen(true)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {aVideoAlreadyExist ? (
          <>
            <video
              autoPlay
              loop
              src={
                getSupabaseImageUrl(
                  storyId,
                  getCustomEmotionVideoFileName(collection.collection_name, emotionKey),
                  VISUAL_TYPE_VIDEO,
                ) || ""
              }
              className="absolute inset-0 object-cover w-full h-full"
            />
            {isHovered && (
              <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center transition-opacity">
                <ArrowPathIcon className="w-16 h-16 text-white" />
              </div>
            )}
          </>
        ) : (
          <div className="w-full  h-full bg-gray-100 flex items-center justify-center cursor-pointer hover:bg-gray-300 transition-colors">
            <PlusCircleIcon className="w-20 h-20" />
          </div>
        )}
      </div>
      <CardText>{emotionKey}</CardText>
      <Dialog
        open={isOpen}
        onClose={() => {
          closePopup();
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all flex flex-col">
            <DialogTitle className="font-serif p-6 text-4xl font-bold mb-4">Video loop</DialogTitle>
            <VideoCapture
              storyId={storyId}
              emotionKey={emotionKey}
              collectionName={collection.collection_name}
              onVideoDone={onVideoDone}
              baseImageFile={baseImageFile}
            />
            <div className="p-6 bg-gray-50 border-t">
              <div className="flex justify-between items-center">
                <SpokableButton onClick={() => closePopup()} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default MediaUploadCard;
