import { useState } from "react";
import { useRecoilState } from "recoil";
import {
  useGenerateBranchBeatsheet,
  useGenerateBranchOnePager,
  useGenerateIdeas,
} from "./useGenerateBeatsHooks.ts";
import { modalBeatGenerateState } from "../../../states/ModalState.ts";
import { RankedBranchIdea } from "../../../types/fastApiTypes.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalBeatGenerateState);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [selectedIdea, setSelectedIdea] = useState<RankedBranchIdea | null>(null);

  const {
    data: ideas,
    status: ideasStatus,
    callApi: generateIdeas,
    setData: setIdeas,
  } = useGenerateIdeas();

  const {
    data: onePager,
    status: onePagerStatus,
    callApi: generateOnePager,
    setData: setOnePager,
  } = useGenerateBranchOnePager();

  const {
    data: beatsheet,
    status: beatsheetStatus,
    callApi: generateBeatsheet,
    setData: setBeatsheet,
  } = useGenerateBranchBeatsheet();

  const handleClose = () => {
    setIsOpen(false);
    setIdeas(null);
    setStepIndex(0);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    selectedIdea,
    setSelectedIdea,
    ideas,
    ideasStatus,
    generateIdeas,
    setIdeas,
    onePager,
    onePagerStatus,
    generateOnePager,
    setOnePager,
    beatsheet,
    beatsheetStatus,
    generateBeatsheet,
    setBeatsheet,
    handleClose,
  };
};
