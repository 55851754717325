import { useCallback, useState } from "react";
import { CHARACTER_EMOTION_NORMAL, IMAGE_TYPE_EMOTION_VIDEO } from "../../constants/constant";
import { generateMediaFileName, getSupabaseImageUrl } from "../../utils/mediaUtil";

export const useGetCharacterMediaUrl = (
  storyId: string,
  characterImages: string[] | null,
  characterEmotion: string,
  characterEmotionStrength: number,
  fallbackUrl?: string,
) => {
  const [characterMedia, setCharacterMedia] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCharacterMedia = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (characterImages && characterImages.length > 0) {
        const randomIndex = Math.floor(Math.random() * characterImages.length);
        const baseObjectUrl = characterImages[randomIndex];
        let fileName;
        if (characterEmotionStrength > 5) {
          fileName = generateMediaFileName(
            baseObjectUrl,
            IMAGE_TYPE_EMOTION_VIDEO,
            characterEmotion,
            true,
          );
        } else {
          fileName = generateMediaFileName(
            baseObjectUrl,
            IMAGE_TYPE_EMOTION_VIDEO,
            CHARACTER_EMOTION_NORMAL,
            true,
          );
        }
        const url = getSupabaseImageUrl(storyId, fileName);
        console.log("fetchCharacterMedia", url);
        setCharacterMedia(url || "/404.jpg");
      } else {
        setError("No character images available");
      }
    } catch (err) {
      console.error("Error fetching character media:", err);
      setError("Failed to fetch character media");
    } finally {
      setIsLoading(false);
    }
  }, [characterImages, characterEmotion, characterEmotionStrength, fallbackUrl, storyId]);

  return { characterMedia, isLoading, error, fetchCharacterMedia };
};
