import { Position, POSITION_CHARACTER_SPEAKING } from "../GameVisualEngine.tsx";
import { useEffect, useState } from "react";

export const useTransitionEffect = (currentPosition: Position) => {
  const [effectClasses, setEffectClasses] = useState({
    backgroundEffect: "opacity-100 transition-opacity duration-1000 ease-in-out",
    characterEffect: "opacity-0 transition-opacity duration-1000 ease-in-out",
  });

  useEffect(() => {
    if (currentPosition === POSITION_CHARACTER_SPEAKING) {
      setEffectClasses({
        backgroundEffect: "opacity-0 transition-opacity duration-1000 ease-in-out",
        characterEffect: "opacity-100 transition-opacity duration-1000 ease-in-out",
      });
    } else {
      setEffectClasses({
        backgroundEffect: "opacity-100 transition-opacity duration-1000 ease-in-out",
        characterEffect: "opacity-0 transition-opacity duration-1000 ease-in-out",
      });
    }
  }, [currentPosition]);

  return effectClasses;
};
