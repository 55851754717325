import React, { useState } from "react";

interface TextSwitcherOption {
  value: string;
  label: string;
}

interface TextSwitcherProps {
  options: TextSwitcherOption[];
  onChange: (value: string) => void;
  className?: string;
  textRgbaColor: string;
}

// TextSwitcher Component
const TextSwitcher: React.FC<TextSwitcherProps> = ({
  options,
  onChange,
  className,
  textRgbaColor,
}) => {
  const [currentValue, setCurrentValue] = useState(options[0]?.value);

  const switchOption = () => {
    const currentIndex = options.findIndex((option) => option.value === currentValue);
    const nextIndex = (currentIndex + 1) % options.length;
    const nextValue = options[nextIndex].value;
    setCurrentValue(nextValue);
    onChange(nextValue);
  };

  const combinedClassName = ` opacity-100 hover:opacity-50 transition-opacity duration-500 cursor-pointer ${className ? className : ""}`;

  return (
    <>
      {/* Empty fragment to include a space before the span */}{" "}
      <span
        className={combinedClassName}
        onClick={switchOption}
        style={{
          color: textRgbaColor,
        }}
      >
        {options.find((option) => option.value === currentValue)?.label}
      </span>
    </>
  );
};

export default TextSwitcher;
