import React, { useEffect, useState } from "react";
import { ImageVariation } from "../../../types/fastApiTypes.ts";
import {
  CHARACTER_EMOTION_DEFAULT,
  CHARACTER_EMOTION_NORMAL,
  CHARACTER_VISUALS_LIST,
} from "../../../constants/constant.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { SparklesIcon } from "@heroicons/react/16/solid";
import FormField from "../FormField.tsx";
import { Checkbox, CheckboxField } from "../../catalyst/checkbox.tsx";
import { Label } from "../../catalyst/fieldset.tsx";
import AnimatedStatus from "../AnimatedStatus.tsx";
import DatabaseSelectInput from "../DatabaseSelectInput.tsx";
import Collapsible from "../Collapsible.tsx";
import EmotionButton from "../EmotionVideoButton.tsx";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { Tables } from "../../../types/database.ts";
import {
  downloadFile,
  getCustomEmotionDrivingVideos,
  useUploadVariations,
} from "./ImageVariationUtil.ts";
import { useImageVariationCall } from "./useImageVariationCall.tsx";
import { VariationGallery } from "./VariationGallery.tsx";

export interface BluePrintImageToBeSaved {
  image_url: string;
  image_type: string;
  has_version_without_background?: boolean;
  has_depth_map?: boolean;
  has_original?: boolean;
  has_emotion_videos?: boolean;
  has_cinematic_videos?: boolean;
}

interface GenerateImageVariationsProps {
  storyId: string;
  baseImageUrlWithStory: string | null;
  number_of_variations: number;
  compute_depth_map: boolean;
  remove_background: boolean;
  compute_emotion_videos: boolean;
  compute_cinematic_videos: boolean;
  onVariationsCompleted: (imageToBeSaved: BluePrintImageToBeSaved) => void;
  videoPrompt?: string | undefined | null;
  videoEventPrompt?: string | undefined | null;
}

export const GenerateImageVariations: React.FC<GenerateImageVariationsProps> = ({
  baseImageUrlWithStory,
  storyId,
  onVariationsCompleted,
  number_of_variations = 0,
  compute_depth_map = true,
  compute_emotion_videos = false,
  compute_cinematic_videos = false,
  remove_background = false,
  videoPrompt,
  videoEventPrompt,
}) => {
  const [bluePrintImageToBeSaved, setBluePrintImageToBeSaved] =
    useState<BluePrintImageToBeSaved | null>(null);
  const [status, setStatus] = useState("");
  const [imageVariations, setImageVariations] = useState<ImageVariation[]>([]);
  const [baseImageFile, setBaseImageFile] = useState<File | null>(null);
  const [cinematicPrompt, setCinematicPrompt] = useState(videoPrompt || "");
  const [eventPrompt, setEventPrompt] = useState(videoEventPrompt || "");
  const [checkedEmotions, setCheckedEmotions] = useState<Set<string>>(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [drivingVideoCollectionId, setDrivingVideoCollectionId] = useState<string | null>(null);
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [selectedDrivingCollection, setSelectedDrivingCollection] =
    useState<Tables<"blueprint_driving_video_collections"> | null>(null);
  const { uploadVariations, canBeSaved } = useUploadVariations(storyId);
  const { makeImageVariationCall, isProcessing, status: variationStatus } = useImageVariationCall();

  useEffect(() => {
    downloadImage();
  }, [baseImageUrlWithStory]);

  useEffect(() => {
    handleUpload();
  }, [imageVariations]);

  const handleEmotionSelectionChange = (emotionKey: string, isChecked: boolean) => {
    setCheckedEmotions((prevChecked) => {
      const newChecked = new Set(prevChecked);
      if (isChecked) {
        newChecked.add(emotionKey);
      } else {
        newChecked.delete(emotionKey);
      }
      return newChecked;
    });
  };

  useEffect(() => {
    fetchDrivingVideosCollection();
  }, [drivingVideoCollectionId]);

  async function fetchDrivingVideosCollection() {
    if (!drivingVideoCollectionId) return;
    const { data, error } = await supabase
      .from("blueprint_driving_video_collections")
      .select("*")
      .eq("id", drivingVideoCollectionId)
      .limit(1)
      .single();

    if (error) {
      console.log("Error creating collection: " + error.message);
    } else {
      setSelectedDrivingCollection(data);
    }
  }

  async function downloadImage() {
    if (!baseImageUrlWithStory) return;

    const file = await downloadFile(baseImageUrlWithStory);
    if (!file) return;
    setBaseImageFile(file);
    setStatus("");
  }

  const handleProcessVariation = async () => {
    if (!baseImageFile) return;

    const emotionVideos = await getCustomEmotionDrivingVideos(
      selectedDrivingCollection,
      checkedEmotions,
      storyId,
    );

    const options = {
      numberOfVariations: number_of_variations,
      computeDepthMap: compute_depth_map,
      removeBackground: remove_background,
      computeEmotionVideos: compute_emotion_videos,
      computeCinematicVideos: compute_cinematic_videos,
      checkedEmotions: checkedEmotions,
      cinematicPrompt: cinematicPrompt,
      eventPrompt: eventPrompt,
      emotionVideos: emotionVideos,
    };

    const result = await makeImageVariationCall(baseImageFile, options);
    if (result.variations) {
      setImageVariations(result.variations);
    }
    if (result.error) {
      console.error("Processing failed:", result.error);
    } else {
      console.log("Processing succeeded:", result.variations);
    }
  };

  const handleUpload = async () => {
    const result = await uploadVariations(baseImageUrlWithStory, imageVariations);
    if (result) {
      setBluePrintImageToBeSaved(result);
      setStatus("");
    }
  };

  const toggleAll = () => {
    if (isAllSelected) {
      setCheckedEmotions(new Set());
    } else {
      setCheckedEmotions(new Set(CHARACTER_VISUALS_LIST.map((emotion) => emotion.key)));
    }
    setIsAllSelected(!isAllSelected);
  };

  return (
    <div className="flex-grow overflow-y-auto p-6 pt-0">
      {compute_cinematic_videos && (
        <>
          <FormField label={"Video when character not speaking"}>
            <AutoResizeTextArea
              className="mb-4"
              value={cinematicPrompt}
              onChange={(e) => setCinematicPrompt(e)}
            />
          </FormField>
          <FormField label={"Video when objective completed"}>
            <AutoResizeTextArea value={eventPrompt} onChange={(e) => setEventPrompt(e)} />
          </FormField>
        </>
      )}
      {compute_emotion_videos && (
        <Collapsible title="Custom emotion videos" defaultIsOpen={false}>
          <div className="space-y-4 mb-4">
            <div className="flex items-center">
              <span onClick={toggleAll} className=" cursor-pointer">
                Select emotions you want to render:
              </span>
            </div>
            <ul className="grid grid-cols-2 gap-4 mb-4">
              {CHARACTER_VISUALS_LIST.map(
                (emotion) =>
                  emotion.key !== CHARACTER_EMOTION_DEFAULT &&
                  emotion.key !== CHARACTER_EMOTION_NORMAL && (
                    <li key={emotion.key}>
                      <CheckboxField>
                        <Checkbox
                          id={emotion.key}
                          checked={checkedEmotions.has(emotion.key)}
                          onChange={(checked) =>
                            handleEmotionSelectionChange(emotion.key, checked as boolean)
                          }
                        />
                        <Label htmlFor={emotion.key} className="ml-2">
                          {emotion.value}
                        </Label>
                      </CheckboxField>
                    </li>
                  ),
              )}
            </ul>
          </div>
          <FormField className="mt-12" label={"Using those reference videos:"}>
            <div className="w-1/2">
              <DatabaseSelectInput
                table="blueprint_driving_video_collections"
                keyColumn="id"
                labelColumn="collection_name"
                storyId={storyId}
                value={drivingVideoCollectionId}
                onChange={(value) => {
                  setDrivingVideoCollectionId(value);
                }}
                refetchTrigger={refetchCounter}
                addNullValueOption={true}
                placeholder="Select a custom emotions collection"
              />
            </div>
            <div className="mt-4">
              {baseImageFile && (
                <EmotionButton
                  storyId={storyId}
                  baseImageFile={baseImageFile}
                  onSettingsDone={() => setRefetchCounter((prev) => prev + 1)}
                />
              )}
            </div>
          </FormField>
        </Collapsible>
      )}
      <FormField>
        <SpokableButton
          onClick={() => handleProcessVariation()}
          className="mb-4 mr-4"
          disabled={isProcessing}
        >
          <SparklesIcon className="mr-2 mb-4" />
          Generate videos
        </SpokableButton>
      </FormField>
      {canBeSaved && (
        <VariationGallery variations={imageVariations} checkedEmotions={checkedEmotions} />
      )}
      <AnimatedStatus status={status + variationStatus} />
      {bluePrintImageToBeSaved && canBeSaved && imageVariations.length != 0 && (
        <SpokableButton
          className="align-bottom mt-12"
          onClick={() => onVariationsCompleted(bluePrintImageToBeSaved)}
          disabled={imageVariations.length === 0}
        >
          Add videos above
        </SpokableButton>
      )}
    </div>
  );
};
