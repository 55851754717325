import { Tables } from "../types/database.ts";

type MergedBeat = {
  beat_name: string;
  beat_description: string | null;
};

type BranchWithBeats = {
  branch_id: string;
  branch_name: string;
  beats: MergedBeat[];
};

type MergedTransition = {
  to_beat_name: string;
  to_branch_name: string;
  transition_description: string;
};

export function mergeBeatsAndBeatsheetsByBranch(
  beats: Tables<"blueprint_beats">[],
  beatsheets: Tables<"blueprint_beatsheets">[],
  branches: Tables<"blueprint_branches">[],
): BranchWithBeats[] {
  const branchMap = new Map<string, BranchWithBeats>();

  // Initialize the map with all branches
  branches.forEach((branch) => {
    branchMap.set(branch.id, {
      branch_id: branch.id,
      branch_name: branch.name,
      beats: [],
    });
  });

  // Merge beats and beatsheets, and group by branch
  beats.forEach((beat) => {
    const matchingBeatsheet = beatsheets.find((bs) => bs.beat_id === beat.id);
    if (matchingBeatsheet) {
      const branchId = matchingBeatsheet.branch_id;
      const branch = branchMap.get(branchId);

      if (branch) {
        branch.beats.push({
          beat_name: beat.name,
          beat_description: matchingBeatsheet.description,
        });
      }
    }
  });

  // Convert the map to an array
  return Array.from(branchMap.values());
}

export function getMergedBeatsByBranchName(
  groupedBeats: BranchWithBeats[],
  branchName: string,
): MergedBeat[] {
  const branch = groupedBeats.find((b) => b.branch_name === branchName);
  return branch ? branch.beats : [];
}

export function mergeBeatsAndBeatsheets(
  beats: Tables<"blueprint_beats">[],
  beatsheets: Tables<"blueprint_beatsheets">[],
): MergedBeat[] {
  const mergedBeats: MergedBeat[] = [];
  beats.forEach((beat) => {
    const matchingBeatsheet = beatsheets.find((bs) => bs.beat_id === beat.id);
    if (matchingBeatsheet) {
      mergedBeats.push({
        beat_name: beat.name,
        beat_description: matchingBeatsheet.description,
      });
    }
  });
  return mergedBeats;
}

export function getMergedTransitions(
  beats: Tables<"blueprint_beats">[],
  beatsheets: Tables<"blueprint_beatsheets">[],
  branchTransitions: Tables<"blueprint_branch_transitions">[] | null,
  currentBeatSheet: Tables<"blueprint_beatsheets">,
  branches: Tables<"blueprint_branches">[] | null,
): MergedTransition[] {
  if (!branchTransitions) return [];
  if (!branches) return [];
  const mergedTransitions: MergedTransition[] = [];

  // Create a map of beat IDs to beat names for quick lookup
  const beatIdToNameMap = new Map(beats.map((beat) => [beat.id, beat.name]));

  // Create a map of branch IDs to branch names for quick lookup
  const branchIdToNameMap = new Map(beatsheets.map((beatsheet) => [beatsheet.branch_id, ""]));

  // Populate branch names
  beatsheets.forEach((beatsheet) => {
    if (beatsheet.branch_id) {
      branchIdToNameMap.set(beatsheet.branch_id, getBranchName(beatsheet.branch_id, branches));
    }
  });

  // Process each branch transition
  branchTransitions.forEach((transition) => {
    // Check if the transition's from_beat_id matches the current beatsheet's beat_id
    if (transition.from_beat_id === currentBeatSheet.id) {
      const beatsheet = beatsheets.find((beatsheet) => beatsheet.id === transition.to_beat_id);
      const beatId = beatsheet?.beat_id ? beatsheet?.beat_id : "";
      const toBeatName = beatIdToNameMap.get(beatId);
      const toBranchName = branchIdToNameMap.get(transition.to_branch_id);

      if (toBeatName && toBranchName) {
        mergedTransitions.push({
          to_beat_name: toBeatName,
          to_branch_name: toBranchName,
          transition_description: transition.transition_description || "",
        });
      }
    }
  });

  return mergedTransitions;
}

// Helper function to get branch name from branch ID
function getBranchName(branchId: string, branches: Tables<"blueprint_branches">[]): string {
  const branch = branches.find((bs) => bs.id === branchId);
  return branch ? `${branch?.name}` : "Unknown Branch";
}
