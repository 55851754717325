import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PAGE_CHARACTER,
  PAGE_CONTEXT,
  PAGE_FLOW,
  PAGE_GENERAL,
  PAGE_STORY,
} from "../../constants/constant.ts";

interface GlobalSideNavLayoutProps {
  children: React.ReactNode;
  activePageId: string;
  storyId: string | undefined;
  showSidePanel?: boolean;
  padding?: string;
}

export const GlobalSideNavLayout: React.FC<GlobalSideNavLayoutProps> = ({
  children,
  activePageId,
  storyId,
  showSidePanel = true,
  padding = "p-6",
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen font-nunito admin">
      {/* Sidebar */}
      {showSidePanel && (
        <div className="w-40 flex-shrink-0  bg-neutral-100 fixed left-0 top-0 bottom-0">
          <div
            className="flex h-16 items-center justify-center p-5 mt-6 cursor-pointer"
            onClick={() => navigate("/admin")}
          >
            <img src="/spokable_logo.svg" alt="logo" />
          </div>
          <nav className="flex-1 overflow-y-auto">
            <ul role="list" className=" py-6">
              <li>
                <a
                  onClick={() => navigate(`/admin/stories/${storyId}`)}
                  className={`cursor-pointer block px-4 py-4 text-sm ${
                    activePageId === PAGE_GENERAL ? "bg-white" : "hover:bg-black hover:text-white"
                  }`}
                >
                  {PAGE_GENERAL}
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate(`/admin/stories/${storyId}/beats`)}
                  className={`cursor-pointer block px-4 py-4 text-sm ${
                    activePageId === PAGE_STORY ? "bg-white" : "hover:bg-black hover:text-white"
                  }`}
                >
                  {PAGE_STORY}
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate(`/admin/stories/${storyId}/transitions`)}
                  className={`cursor-pointer block px-4 py-4 text-sm ${
                    activePageId === PAGE_FLOW ? "bg-white" : "hover:bg-black hover:text-white"
                  }`}
                >
                  {PAGE_FLOW}
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate(`/admin/stories/${storyId}/characters`)}
                  className={`cursor-pointer block px-4 py-4 text-sm ${
                    activePageId === PAGE_CHARACTER ? "bg-white" : "hover:bg-black hover:text-white"
                  }`}
                >
                  {PAGE_CHARACTER}
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate(`/admin/stories/${storyId}/contexts`)}
                  className={`cursor-pointer block px-4 py-4 text-sm ${
                    activePageId === PAGE_CONTEXT ? "bg-white" : "hover:bg-black hover:text-white"
                  }`}
                >
                  {PAGE_CONTEXT}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {/* Main content */}
      <main className={`flex-1 overflow-y-auto ${showSidePanel ? "ml-40" : ""}`}>
        <div className={padding}>{children}</div>
      </main>
    </div>
  );
};
