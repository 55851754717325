import React from "react";
import { Divider } from "../../catalyst/divider.tsx";
import { RankedBranchIdea } from "../../../types/fastApiTypes.ts";
import { Badge } from "../../catalyst/badge.tsx";

interface IdeaItemProps {
  idea: RankedBranchIdea;
  selectedIdea: RankedBranchIdea | null;
  setSelectedIdea: React.Dispatch<React.SetStateAction<RankedBranchIdea | null>>;
}

export const IdeaItem: React.FC<IdeaItemProps> = ({ idea, selectedIdea, setSelectedIdea }) => (
  <div>
    <div
      className={`p-4 mb-2 rounded-lg cursor-pointer transition-colors ${
        selectedIdea?.idea.branch_description === idea.idea.branch_description
          ? "bg-blue-100 hover:bg-blue-200"
          : "hover:bg-gray-100"
      }`}
      onClick={() => setSelectedIdea(idea)}
    >
      <p>
        <Badge color={"zinc"} className="mr-2">
          Description:
        </Badge>
        {idea.idea.branch_description}
      </p>
      <p className={"mt-4"}>
        <Badge color={"zinc"} className="mr-2 ">
          Decision:
        </Badge>
        {idea.idea.divergence_point}
      </p>
      <div className="text-zinc-500 mt-4">Score: {idea.score}</div>
    </div>
    <Divider className="mb-2" />
  </div>
);
