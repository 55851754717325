import colors from "tailwindcss/colors";
import { ColorResult } from "react-color";
import { Texture } from "three";

export function getRandomTailwindColor(input: string): string {
  const excludedColors = [
    "black",
    "white",
    "transparent",
    "current",
    "slate",
    "gray",
    "zinc",
    "neutral",
    "stone",
    "warmGray",
    "blueGray",
  ];

  const minShade = 300;
  const maxShade = 600;

  const availableColors = Object.keys(colors).filter((color) => !excludedColors.includes(color));

  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i);
    hash |= 0; // Convert to 32-bit integer
  }

  let colorIndex = Math.abs(hash % availableColors.length);
  let colorName = availableColors[colorIndex];
  let color = colors[colorName as keyof typeof colors];

  while (
    typeof color === "string" &&
    (color === "inherit" || color === "transparent" || color.startsWith("#"))
  ) {
    colorIndex = (colorIndex + 1) % availableColors.length;
    colorName = availableColors[colorIndex];
    color = colors[colorName as keyof typeof colors];
  }

  if (typeof color === "string") {
    return color;
  } else {
    const shades = Object.keys(color).filter(
      (shade) =>
        /^\d+$/.test(shade) && parseInt(shade, 10) >= minShade && parseInt(shade, 10) <= maxShade,
    );
    const shadeIndex = Math.abs(hash % shades.length);
    const shade = shades[shadeIndex];

    // Deterministic hue offset
    const deterministicHueOffset = ((hash % 3) - 1) * 100; // Offset between -100 and 100
    let deterministicShade = parseInt(shade, 10) + deterministicHueOffset;
    deterministicShade = Math.max(minShade, Math.min(maxShade, deterministicShade));

    const shadeKey = deterministicShade.toString() as keyof typeof color;
    return color[shadeKey];
  }
}

export const formatRGBAColor = (colorFromDatabase: string) => {
  const parsedColor = parseRGBAColor(colorFromDatabase);
  return `rgba(${parsedColor.rgb.r}, ${parsedColor.rgb.g}, ${parsedColor.rgb.b}, ${parsedColor.rgb.a})`;
};

export function parseRGBAColor(rgba: string): ColorResult {
  const [r, g, b, a] = rgba
    .replace(/^rgba?\(|\s+|\)$/g, "")
    .split(",")
    .map(parseFloat);
  const rgb = { r, g, b, a: a || 1 };
  const hex = rgbaToHex(r, g, b, a || 1);
  const hsl = rgbToHsl(r, g, b);

  return {
    rgb,
    hex,
    hsl,
  };
}

function rgbaToHex(r: number, g: number, b: number, a: number): string {
  const hex = [r, g, b, Math.round(a * 255)].map((x) => x.toString(16).padStart(2, "0")).join("");
  return `#${hex}`;
}

function rgbToHsl(r: number, g: number, b: number): { h: number; s: number; l: number } {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h: h * 360, s, l };
}

// Core logic to compute the dominant color from image data
const computeDominantColorFromImageData = (
  data: Uint8ClampedArray,
  width: number,
  height: number,
  borderDistance: number,
): string => {
  const colorFrequency: { [key: string]: number } = {};
  let maxFrequency = 0;
  let dominantColor = "#000000";

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      if (
        x < borderDistance ||
        x >= width - borderDistance ||
        y < borderDistance ||
        y >= height - borderDistance
      ) {
        const index = (y * width + x) * 4;
        const r = data[index];
        const g = data[index + 1];
        const b = data[index + 2];
        const a = data[index + 3];

        if (a > 0) {
          const color = `rgb(${r}, ${g}, ${b})`;
          colorFrequency[color] = (colorFrequency[color] || 0) + 1;

          if (colorFrequency[color] > maxFrequency) {
            maxFrequency = colorFrequency[color];
            dominantColor = color;
          }
        }
      }
    }
  }

  return dominantColor;
};

// Main function to handle the texture and canvas
export const computeDominantColor = (texture: Texture, borderDistance: number = 50): string => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return "#000000"; // Default color if canvas context is not available

  const { width, height } = texture.image;
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(texture.image, 0, 0, width, height);

  const imageData = ctx.getImageData(0, 0, width, height);
  return computeDominantColorFromImageData(imageData.data, width, height, borderDistance);
};

export const computeDominantColorFromImageRef = (
  imageRef: React.RefObject<HTMLImageElement>,
  borderDistance: number = 50,
): string => {
  const image = imageRef.current;
  if (!image) return "#000000";

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return "#000000"; // Default color if canvas context is not available

  const { width, height } = image;
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(image, 0, 0, width, height);

  const imageData = ctx.getImageData(0, 0, width, height);
  return computeDominantColorFromImageData(imageData.data, width, height, borderDistance);
};

type RGB = {
  r: number;
  g: number;
  b: number;
};

export function hexToRgb(hex: string): RGB {
  const bigint = parseInt(hex.replace("#", ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

export function luminance({ r, g, b }: RGB): number {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function contrastRatio(rgb1: RGB, rgb2: RGB): number {
  const lum1 = luminance(rgb1);
  const lum2 = luminance(rgb2);
  return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
}

export function bestFontColor(backgroundColor: string): string {
  const fontColors = ["#000000", "#FFFFFF"];
  const bgColorRgb = hexToRgb(backgroundColor);
  let bestColor = fontColors[0];
  let bestRatio = 0;

  fontColors.forEach((fontColor) => {
    const fontColorRgb = hexToRgb(fontColor);
    const ratio = contrastRatio(bgColorRgb, fontColorRgb);
    if (ratio > bestRatio) {
      bestRatio = ratio;
      bestColor = fontColor;
    }
  });

  return bestColor;
}
