import React, { useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";

interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
  defaultIsOpen?: boolean;
}

const Collapsible: React.FC<CollapsibleProps> = ({ title, children, defaultIsOpen = true }) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="border border-gray-200 rounded-md mb-4">
      <button
        className="flex justify-between items-center w-full p-4 text-left hover:bg-gray-200 transition-colors"
        onClick={toggleOpen}
      >
        <span className="font-semibold">{title}</span>
        <div
          className={`transform transition-transform duration-300 ${isOpen ? "rotate-180" : ""}`}
        >
          <ChevronDownIcon className="h-5 w-5 text-gray-500" />
        </div>
      </button>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-300 ease-in-out`}
        style={{
          maxHeight: isOpen ? contentRef.current?.scrollHeight : 0,
          opacity: isOpen ? 1 : 0,
        }}
      >
        <div className="p-4 border-t border-gray-200">{children}</div>
      </div>
    </div>
  );
};

export default Collapsible;
