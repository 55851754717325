import React, { useEffect, useState } from "react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import FormField from "../FormField.tsx";

interface AutoCompleteProps {
  basePrompt: string;
  generateFrom: string;
  onAutoComplete: (completion: string) => void;
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
  basePrompt,
  generateFrom,
  onAutoComplete,
}) => {
  const [finalPrompt, setFinalPrompt] = useState(
    basePrompt + " based on the following " + generateFrom,
  );
  const [basePromptState, setBasePromptState] = useState(basePrompt);
  const [generateFromState, setGenerateFromState] = useState(generateFrom);
  const { autoCompleteOutput, autoComplete, autoCompleteStatus, setAutoComplete } =
    useGenerateSteps();

  const handleGenerate = async () => {
    await autoComplete({
      prompt: finalPrompt,
    });
  };

  useEffect(() => {
    setFinalPrompt(basePrompt + " based on the following " + generateFrom);
  }, [generateFromState, basePromptState, basePrompt, generateFrom]);

  return (
    <div className="flex-grow overflow-y-auto p-6 pt-0">
      <div className="">
        <FormField label="Instruction">
          <AutoResizeTextArea
            value={basePromptState.trim()}
            onChange={(e) => {
              setBasePromptState(e);
            }}
          />
        </FormField>
        <FormField label="Data">
          <AutoResizeTextArea
            value={generateFromState.trim()}
            onChange={(e) => {
              setGenerateFromState(e);
            }}
          />
        </FormField>
      </div>
      <SpokableButton onClick={handleGenerate} className="mb-4 mt-4">
        <SparklesIcon className="mr-2" />
        Generate
      </SpokableButton>
      {autoCompleteStatus && <div>{autoCompleteStatus}</div>}
      {autoCompleteOutput && (
        <div>
          <AutoResizeTextArea
            value={autoCompleteOutput.completion}
            onChange={(e) => {
              setAutoComplete({ ...autoCompleteOutput, completion: e });
            }}
          />
          <SpokableButton
            onClick={() => onAutoComplete(autoCompleteOutput.completion)}
            className="mb-4 mt-4"
          >
            <SparklesIcon className="mr-2" />
            Save
          </SpokableButton>
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
