import { createClient } from "@supabase/supabase-js";
import { Database } from "../types/database.ts";
import { Database as PromptManagementDatabase } from "../types/promptManagementDatabase.ts";
import { DEV_SUPABASE_ANON, DEV_SUPABASE_URL } from "./config.ts";

export const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || DEV_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON || DEV_SUPABASE_ANON;
export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);

export const supabasePromptManagement = createClient<PromptManagementDatabase>(
  supabaseUrl,
  supabaseAnonKey,
  {
    db: {
      schema: "prompt_management",
    },
  },
);
//npx supabase gen types typescript --local > src/types/database.ts
//npx supabase gen types typescript --local --schema prompt_management > src/types/promptManagementDatabase.ts