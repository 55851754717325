import React from "react";
import { Divider } from "../../catalyst/divider.tsx";

interface Idea {
  idea: string;
  score: number;
}

interface IdeaItemProps {
  idea: Idea;
  selectedIdea: string | null;
  setSelectedIdea: React.Dispatch<React.SetStateAction<string | null>>;
}

export const IdeaItem: React.FC<IdeaItemProps> = ({ idea, selectedIdea, setSelectedIdea }) => (
  <div>
    <div
      className={`p-4 mb-2 rounded-lg cursor-pointer transition-colors ${
        selectedIdea === idea.idea ? "bg-blue-100 hover:bg-blue-200" : "hover:bg-gray-100"
      }`}
      onClick={() => setSelectedIdea(idea.idea)}
    >
      {idea.idea}
      <div className="text-gray-500">Score: {idea.score}</div>
    </div>
    <Divider className="mb-2" />
  </div>
);
