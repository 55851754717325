import React, { useEffect, useRef } from "react";
import anime from "animejs";

export enum GAME_STATES {
  RECORDABLE = "record",
  RECORDING = "recording",
  THINKING = "thinking",
  SPEAKING = "speaking",
}

interface MainButtonProps {
  gameState: GAME_STATES;
  onStartRecording: () => void;
  onStopRecording: () => void;
  color: string;
}

const MainButton: React.FC<MainButtonProps> = ({
  gameState,
  onStartRecording,
  onStopRecording,
  color,
}) => {
  const svgRefs = useRef<(SVGSVGElement | null)[]>([]);
  const textRef = useRef<HTMLDivElement>(null);

  const paths = {
    [GAME_STATES.RECORDABLE]: "M50 50H150V150H50V50Z",
    [GAME_STATES.RECORDING]:
      "M150 100C150 127.614 127.614 150 100 150C72.3858 150 50 127.614 50 100C50 72.3858 72.3858 50 100 50C127.614 50 150 72.3858 150 100Z",
    [GAME_STATES.THINKING]:
      "M133.192 109.381C135.221 104.375 136.339 98.9028 136.339 93.1694C136.339 69.3275 117.011 50 93.1694 50C69.3277 50 50 69.3275 50 93.1694C50 117.011 69.3277 136.339 93.1694 136.339C104.644 136.339 115.074 131.862 122.806 124.558C123.722 131.213 129.432 136.339 136.339 136.339C143.884 136.339 150 130.222 150 122.678C150 115.133 143.884 109.016 136.339 109.016C135.256 109.016 134.202 109.143 133.192 109.381Z",
    [GAME_STATES.SPEAKING]: "M50 90H150V110H50V90Z",
  };

  const animateTransition = () => {
    Object.values(GAME_STATES).forEach((state, index) => {
      const svgElement = svgRefs.current[index];
      if (!svgElement) return;
      const isActive = state === gameState;
      anime({
        targets: svgElement,
        scale: isActive ? [0, 1] : [1, 0],
        opacity: isActive ? [0, 1] : [1, 0],
        duration: 500,
        easing: "easeInOutCirc",
      });
    });

    // Animate text
    if (textRef.current) {
      anime({
        targets: textRef.current,
        opacity: [0, 1],
        translateY: [10, 0],
        duration: 500,
        easing: "easeOutQuad",
      });
    }
  };

  useEffect(() => {
    animateTransition();
  }, [gameState]);

  function handleClick() {
    if (gameState === GAME_STATES.RECORDABLE) onStartRecording();
    else if (gameState === GAME_STATES.RECORDING) onStopRecording();
  }

  return (
    <div className="flex flex-col items-center">
      <div
        onClick={handleClick}
        className={`${
          [GAME_STATES.RECORDABLE, GAME_STATES.RECORDING].includes(gameState)
            ? "z-10 cursor-pointer transition duration-500 ease-in-out transform hover:scale-95 z-10"
            : "z-10"
        }`}
      >
        {Object.values(GAME_STATES).map((state, index) => (
          <svg
            key={state}
            ref={(el) => (svgRefs.current[index] = el)}
            width="200"
            height="200"
            viewBox="0 0 200 200"
            preserveAspectRatio="xMidYMid meet"
            style={{ position: "absolute", transform: "translateX(-50%)" }}
          >
            <path d={paths[state]} fill={color} />
          </svg>
        ))}
      </div>
      <div ref={textRef} className="mt-4 text-lg font-semibold" style={{ color: color }}>
        {gameState === GAME_STATES.RECORDABLE ? "TAP" : ""}
      </div>
    </div>
  );
};

export default MainButton;
