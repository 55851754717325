import { useParams } from "react-router-dom";
import { SideNavLayout } from "@/components/admin/SideNavLayout";
import LogsViewer from "@/components/admin/promptManagement/LogsViewer.tsx";
import React from "react";

export interface PromptLogsProps {}

const PromptLogs: React.FC<PromptLogsProps> = () => {
  const { promptId } = useParams<{ promptId: string }>();

  if (!promptId) return;

  return (
    <SideNavLayout>
      <LogsViewer promptId={promptId} />
    </SideNavLayout>
  );
};

export default PromptLogs;
