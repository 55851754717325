import { useCallback, useState } from "react";
import { generateMediaFileName, getSupabaseImageUrl } from "../../utils/mediaUtil";
import { IMAGE_TYPE_CINEMATIC_VIDEO, IMAGE_TYPE_EVENT_VIDEO } from "../../constants/constant.ts";

export const useGetBackgroundMediaUrl = (
  storyId: string,
  backgroundImages: string[] | null,
  isEvent: boolean,
  fallbackUrl?: string,
) => {
  const [backgroundMedia, setBackgroundMedia] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchBackgroundMedia = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (backgroundImages && backgroundImages.length > 0) {
        const randomIndex = Math.floor(Math.random() * backgroundImages.length);

        let fileName = "";
        if (!isEvent) {
          fileName = generateMediaFileName(
            backgroundImages[randomIndex],
            IMAGE_TYPE_CINEMATIC_VIDEO,
            "",
            true,
          );
        } else {
          fileName = generateMediaFileName(
            backgroundImages[randomIndex],
            IMAGE_TYPE_EVENT_VIDEO,
            "",
            true,
          );
        }

        const url = getSupabaseImageUrl(storyId, fileName);
        console.log("backgroundMedia", url);
        setBackgroundMedia(url || "/404.jpg");
      } else {
        setError("No background images available");
      }
    } catch (err) {
      console.error("Error fetching background media:", err);
      setError("Failed to fetch background media");
    } finally {
      setIsLoading(false);
    }
  }, [backgroundImages, fallbackUrl, storyId]);

  return { backgroundMedia, isLoading, error, fetchBackgroundMedia };
};
