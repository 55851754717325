import React, { useEffect, useMemo, useState } from "react";
import { CardH1 } from "./Card.tsx";
import { useNavigate } from "react-router-dom";
import { SpokableButton } from "./SpokableButton.tsx";

interface LayoutProps {
  children: React.ReactNode;
  showSidePanel?: boolean;
}

export const SideNavLayout: React.FC<LayoutProps> = ({ children, showSidePanel = true }) => {
  const [activeSection, setActiveSection] = useState("");
  const navigate = useNavigate();

  const contentSections = useMemo(() => {
    const sections: {
      id: string;
      label: string;
    }[] = [];

    const extractSections = (node: React.ReactNode) => {
      React.Children.forEach(node, (child) => {
        if (React.isValidElement(child)) {
          if (child.type === CardH1 && child.props.id && child.props.label) {
            sections.push({ id: child.props.id, label: child.props.label });
          }
          extractSections(child.props.children);
        }
      });
    };

    extractSections(children);
    return sections;
  }, [children]);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href")?.slice(1);
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const headerHeight = 0; // Removed offset adjustment

      const currentSection = contentSections.find((section) => {
        const sectionElement = document.getElementById(section.id);
        if (sectionElement) {
          const sectionTop = sectionElement.offsetTop - headerHeight;
          const sectionBottom = sectionTop + sectionElement.offsetHeight;
          return scrollPosition >= sectionTop && scrollPosition < sectionBottom;
        }
        return false;
      });

      if (currentSection) {
        setActiveSection(currentSection.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check for active section

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [contentSections]);

  return (
    <div className="flex min-h-screen font-nunito">
      {/* Sidebar */}
      {showSidePanel && (
        <div className="w-40 flex-shrink-0 fixed left-0 top-0 h-full bg-neutral-100 border-r border-gray-200">
          <div
            className="flex h-16 items-center justify-center p-5 mt-6 cursor-pointer"
            onClick={() => navigate("/admin")}
          >
            <img src="/spokable_logo.svg" alt="logo" />
          </div>
          <nav className="mt-6">
            <ul role="list" className="space-y-2 px-2">
              {contentSections.map((section) => (
                <li key={section.id}>
                  <a
                    href={`#${section.id}`}
                    onClick={handleClick}
                    className={`block px-4 py-2 text-sm rounded transition-colors ${
                      activeSection === section.id ? "bg-white font-medium" : "hover:bg-white/50"
                    }`}
                  >
                    {section.label}
                  </a>
                </li>
              ))}
              <SpokableButton className={"ml-6"} onClick={() => navigate("/admin/prompts")}>
                Prompts
              </SpokableButton>
            </ul>
          </nav>
        </div>
      )}

      {/* Main content */}
      <main className={`flex-1 min-h-screen ${showSidePanel ? "ml-40" : ""}`}>
        <div className="p-8">{children}</div>
      </main>
    </div>
  );
};

export default SideNavLayout;
