import { useState } from "react";
import axios from "axios";
import { DEV_FAST_API_HTTP } from "../../../vendor/config.ts";
import { ImageVariation } from "../../../types/fastApiTypes.ts";
import { IMAGE_VARIATION_ENDPOINT } from "../../../constants/constant.ts";

type ProcessingOptions = {
  numberOfVariations: number;
  computeDepthMap: boolean;
  removeBackground: boolean;
  computeEmotionVideos: boolean;
  computeCinematicVideos: boolean;
  checkedEmotions: Set<string>;
  cinematicPrompt: string;
  eventPrompt: string;
  emotionVideos: File[] | undefined;
};

type ProcessingResult = {
  variations?: ImageVariation[];
  error?: string;
  isProcessing: boolean;
  status: string;
};

export const useImageVariationCall = () => {
  const [processingState, setProcessingState] = useState<ProcessingResult>({
    variations: [],
    isProcessing: false,
    status: "",
  });

  const makeImageVariationCall = async (
    baseImageFile: File,
    options: ProcessingOptions,
  ): Promise<ProcessingResult> => {
    if (processingState.isProcessing) {
      return processingState;
    }

    try {
      setProcessingState((prev) => ({
        ...prev,
        isProcessing: true,
        status: "Hardcore image processing in progress...",
      }));

      const formData = new FormData();

      // Append emotion videos from options
      if (options.emotionVideos && options.emotionVideos.length > 0) {
        options.emotionVideos.forEach((file, index) => {
          formData.append(`emotion_video_${index}`, file);
        });
        formData.append("emotion_videos_count", options.emotionVideos.length.toString());
      }

      // Append all required data
      formData.append("file", baseImageFile);
      formData.append("number_of_variations", options.numberOfVariations.toString());
      formData.append("compute_depth_map", options.computeDepthMap.toString());
      formData.append("remove_background", options.removeBackground.toString());
      formData.append("compute_emotion_videos", options.computeEmotionVideos.toString());
      formData.append("compute_cinematic_videos", options.computeCinematicVideos.toString());
      formData.append("emotions_to_compute", Array.from(options.checkedEmotions).join(","));
      formData.append("video_prompt", options.cinematicPrompt);
      formData.append("video_event_prompt", options.eventPrompt);
      formData.append("no_normal_emotion_video", true.toString());

      const URL = import.meta.env.VITE_FAST_API_HTTP || DEV_FAST_API_HTTP;
      const response = await axios.post<{ variations: ImageVariation[] }>(
        `${URL}${IMAGE_VARIATION_ENDPOINT}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      const newState = {
        variations: response.data.variations,
        isProcessing: false,
        status: "",
      };

      setProcessingState(newState);
      return newState;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      const errorState = {
        variations: [],
        error: errorMessage,
        isProcessing: false,
        status: `Error processing image: ${errorMessage}`,
      };

      setProcessingState(errorState);
      return errorState;
    }
  };

  return {
    makeImageVariationCall,
    ...processingState,
  };
};
