import { useNavigate } from "react-router-dom";
import { getQueryParams } from "../utils/getQueryParamsFromURL.ts";

export const useNavigateWithQueryParams = () => {
  const navigate = useNavigate();
  const queryParams = getQueryParams();

  const navigateWithQueryParams = (to: string) => {
    const filteredParams: Record<string, string> = {};
    for (const [key, value] of Object.entries(queryParams)) {
      if (value !== undefined) {
        filteredParams[key] = value;
      }
    }
    const queryString = new URLSearchParams(filteredParams).toString();
    navigate(`${to}?${queryString}`);
  };

  return navigateWithQueryParams;
};
