import React, { useState } from "react";
import { BeatSheet, OnePager } from "../../../types/fastApiTypes";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { Tables, TablesInsert } from "../../../types/database.ts";
import { DialogTitle } from "../../catalyst/dialog.tsx";
import { useCreateBeats, useStoryBeats } from "../../../hooks/database/useStory.ts";
import { SpokableButton } from "../SpokableButton.tsx";

export interface SaveAllProps {
  beatSheet: BeatSheet | null;
  onePager: OnePager | null;
  story: Tables<"blueprint_stories"> | null;
  clickedBranch: Tables<"blueprint_branches"> | undefined | null;
  clickedBeatsheet: Tables<"blueprint_beatsheets"> | undefined | null;
  handleClose: () => void;
  transitionDescription: string | undefined;
}

const SaveAll: React.FC<SaveAllProps> = ({
  beatSheet,
  onePager,
  story,
  clickedBranch,
  clickedBeatsheet,
  transitionDescription,
  handleClose,
}) => {
  const [errorMessage2, setErrorMessage] = useState<string | null>(null);
  const storyBeats = useStoryBeats();
  const { createBeats, errorMessage } = useCreateBeats();

  const saveAll = async () => {
    if (!story) return;
    if (!clickedBranch) return;
    if (!clickedBeatsheet) return;
    const branch = await createBranch();
    createBeats(
      branch,
      story,
      beatSheet,
      storyBeats,
      clickedBranch,
      clickedBeatsheet,
      transitionDescription,
    );
    handleClose();
  };

  async function createBranch() {
    if (!story) return;
    if (!onePager) return;

    // Check if the main branch already exists for this story
    const { data: existingBranch } = await supabase
      .from("blueprint_branches")
      .select()
      .eq("story_id", story.id)
      .eq("name", onePager.title)
      .limit(1)
      .single();

    if (existingBranch) {
      return existingBranch;
    }

    const branchData: TablesInsert<"blueprint_branches"> = {
      name: onePager.title,
      one_pager: JSON.stringify(onePager),
      story_id: story.id,
    };
    const { data, error } = await supabase
      .from("blueprint_branches")
      .insert(branchData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error updating story: " + error.message);
    } else {
      setErrorMessage("Saved!");
      return data;
    }
  }

  return (
    <>
      <DialogTitle as="h2" className="font-serif py-6 text-4xl font-bold mb-4">
        Complete process
      </DialogTitle>
      <div className="flex">
        <div className="flex-grow">
          {errorMessage && <div>{errorMessage}</div>}
          {errorMessage2 && <div>{errorMessage2}</div>}
          <SpokableButton onClick={saveAll} className="mt-6 mb-6">
            Save all
          </SpokableButton>
        </div>
      </div>
    </>
  );
};

export default SaveAll;
