import React from "react";
import TransitionDetail from "../../../pages/admin/TransitionDetail.tsx";
import ChapterDetail from "../../../pages/admin/ChapterDetail.tsx";
import { Tables } from "../../../types/database.ts";
import ChapterList from "../../../pages/admin/ChapterList.tsx";
import CreateChapterButton from "../CRUD/CreateChapterButton.tsx";

interface TransitionSidePanelProps {
  selectedChapterId: string | null | undefined;
  selectedChapterTransitionId: string | null | undefined;
  onUpdateChapter?: (updatedChapter: Tables<"blueprint_chapters">) => void;
  onUpdateChapterTransition?: (updatedTransition: Tables<"blueprint_chapter_transitions">) => void;
  story: Tables<"blueprint_stories">;
  chapters: Tables<"blueprint_chapters">[];
  onRefreshChapters: () => void;
}

const TransitionSidePanel: React.FC<TransitionSidePanelProps> = ({
  selectedChapterId,
  selectedChapterTransitionId,
  onUpdateChapter,
  onUpdateChapterTransition,
  story,
  chapters,
  onRefreshChapters,
}) => {
  if (selectedChapterId) {
    return (
      <div className="w-full h-full bg-gray-100">
        <div className="h-full overflow-y-auto">
          <ChapterDetail chapterId={selectedChapterId} onChapterUpdate={onUpdateChapter} />
        </div>
      </div>
    );
  } else if (selectedChapterTransitionId) {
    return (
      <div className="w-full h-full bg-gray-100 p-4">
        <TransitionDetail
          transitionId={selectedChapterTransitionId}
          onTransitionUpdate={onUpdateChapterTransition}
        />
      </div>
    );
  } else {
    return (
      <div className="w-full h-full bg-gray-100 p-4">
        <div className="h-full overflow-y-auto">
          <div className="m-6">No chapter or transition selected.</div>
          <div className="ml-6 ">
            <CreateChapterButton
              story={story}
              chapters={chapters}
              onChapterCreated={onRefreshChapters}
            />
          </div>
          <ChapterList
            onChapterUpdateOrDeleted={onRefreshChapters}
            story={story}
            chapters={chapters}
          />
        </div>
      </div>
    );
  }
};

export default TransitionSidePanel;
