const SvgLogo = ({ size = 50, color = "#E5FF76" }) => (
  <svg
    width={size}
    height={(size * 171) / 334} // Maintain aspect ratio
    viewBox="0 0 334 171"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 74.026C0 33.1426 33.1426 0 74.026 0H259.972C300.856 0 333.998 33.1426 333.998 74.026C333.998 114.909 300.856 148.052 259.972 148.052H116.458L101.045 171L85.6333 148.052H74.026C33.1426 148.052 0 114.909 0 74.026ZM86.0877 28.1299H116.932V57.8722H148.052V88.7164H116.932V120.662H86.0877V88.7164H55.5195V57.8722H86.0877V28.1299ZM235.773 120.662C261.325 120.662 282.039 99.9482 282.039 74.3961C282.039 48.844 261.325 28.1299 235.773 28.1299C210.221 28.1299 189.506 48.844 189.506 74.3961C189.506 99.9482 210.221 120.662 235.773 120.662Z"
      fill={color}
    />
  </svg>
);

export default SvgLogo;
