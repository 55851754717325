import {
  databaseOperations,
  DatabaseTypes,
  handleDatabaseError,
} from "@/components/admin/promptManagement/database/databaseOperations.ts";
import { useState } from "react";

interface UseViewDatasetButtonDatabaseReturn {
  errorMessage: string | null;
  isExecutionIdInEvalDatasetMap: Record<string, boolean>;
  fetchEvalDatasets: () => Promise<void>;
  handleEvalDatasetChange: (
    log: DatabaseTypes["PromptExecutionDetails"],
    promptId: string,
    evalId: string,
    isChecked: boolean,
  ) => Promise<void>;
}

export const useDatasetModalButtonDatabase = (
  promptId: string | undefined,
  evalId: string | undefined,
): UseViewDatasetButtonDatabaseReturn => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isExecutionIdInEvalDatasetMap, setIsExecutionIdInEvalDatasetMap] = useState<
    Record<string, boolean>
  >({});

  const fetchEvalDatasets = async () => {
    if (!promptId) return;
    if (!evalId) return;
    try {
      const { data, error } = await databaseOperations.fetchEvalDatasets(promptId, evalId);
      if (error) throw error;

      const isExecutionIdInEvalDatasetMapTemp = data.reduce(
        (acc, eval_dataset) => {
          acc[eval_dataset.prompt_execution_id] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );

      setIsExecutionIdInEvalDatasetMap(isExecutionIdInEvalDatasetMapTemp);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  const handleEvalDatasetChange = async (
    log: DatabaseTypes["PromptExecutionDetails"],
    promptId: string,
    evalId: string,
    isChecked: boolean,
  ) => {
    if (!log.id) return;

    try {
      if (isChecked) {
        const { error } = await databaseOperations.createEvalDataset(log.id, promptId, evalId);
        if (error) throw error;

        setIsExecutionIdInEvalDatasetMap((prev) => ({
          ...prev,
          [log.id!]: true,
        }));
      } else {
        const { error } = await databaseOperations.deleteEvalDataset(log.id, promptId, evalId);
        if (error) throw error;

        setIsExecutionIdInEvalDatasetMap((prev) => ({
          ...prev,
          [log.id!]: false,
        }));
      }
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  return {
    isExecutionIdInEvalDatasetMap,
    handleEvalDatasetChange,
    errorMessage,
    fetchEvalDatasets,
  };
};
