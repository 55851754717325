import React from "react";
import { Card, CardHeader, CardImage, CardText, CardVideo } from "../Card";
import { ImageVariation } from "../../../types/fastApiTypes";
import { CHARACTER_EMOTION_NORMAL } from "../../../constants/constant.ts";

interface VariationGalleryProps {
  variations: ImageVariation[];
  checkedEmotions: Set<string>;
  hideOriginal?: boolean;
}

export const VariationGallery: React.FC<VariationGalleryProps> = ({
  variations,
  checkedEmotions,
  hideOriginal = false,
}) => {
  return (
    <ul className="grid grid-cols-4 gap-4">
      {variations.map((variation, index) => (
        <React.Fragment key={index}>
          {/* Original Image */}
          {!hideOriginal && (
            <li>
              <Card className="aspect-square">
                <CardHeader className="h-full">
                  <div className="relative w-full h-full">
                    <CardImage src={variation.original} />
                  </div>
                  <CardText>Original</CardText>
                </CardHeader>
              </Card>
            </li>
          )}

          {/* Background Removed Version */}
          {variation.without_bg && (
            <li>
              <Card className="aspect-square">
                <CardHeader className="h-full">
                  <div className="relative w-full h-full">
                    <CardImage src={variation.without_bg} />
                  </div>
                  <CardText>Without Background</CardText>
                </CardHeader>
              </Card>
            </li>
          )}

          {/* Depth Map */}
          {variation.depth_map && (
            <li>
              <Card className="aspect-square">
                <CardHeader className="h-full">
                  <div className="relative w-full h-full">
                    <CardImage src={variation.depth_map} />
                  </div>
                  <CardText>Depth Map</CardText>
                </CardHeader>
              </Card>
            </li>
          )}

          {/* Emotion Videos */}
          {variation.emotion_videos?.map(
            (emotionVideo, videoIndex) =>
              (checkedEmotions.has(emotionVideo.emotion) ||
                (!hideOriginal && emotionVideo.emotion === CHARACTER_EMOTION_NORMAL)) && (
                <li key={`${videoIndex}`}>
                  <Card className="aspect-square">
                    <CardHeader className="h-full">
                      <div className="relative w-full h-full">
                        <CardVideo
                          src={emotionVideo.url}
                          className="absolute inset-0 object-cover"
                        />
                      </div>
                      <CardText>{emotionVideo.emotion}</CardText>
                    </CardHeader>
                  </Card>
                </li>
              ),
          )}

          {/* Cinematic Video */}
          {variation.cinematic_video && (
            <li>
              <Card className="aspect-square">
                <CardHeader className="h-full">
                  <div className="relative w-full h-full">
                    <CardVideo
                      src={variation.cinematic_video}
                      className="absolute inset-0 object-cover"
                    />
                  </div>
                  <CardText>Cinematic Video</CardText>
                </CardHeader>
              </Card>
            </li>
          )}

          {/* Event Video */}
          {variation.event_video && (
            <li>
              <Card className="aspect-square">
                <CardHeader className="h-full">
                  <div className="relative w-full h-full">
                    <CardVideo
                      src={variation.event_video}
                      className="absolute inset-0 object-cover"
                    />
                  </div>
                  <CardText>Event Video</CardText>
                </CardHeader>
              </Card>
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};
