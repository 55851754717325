import { Tag } from "../components/admin/SpokableTagSelector.tsx";

const storyGenres: { key: string; value: string }[] = [
  { key: "ACTION_ADVENTURE", value: "Action & Adventure" },
  { key: "CLASSICS", value: "Classics" },
  { key: "COMEDY", value: "Comedy" },
  { key: "DRAMA", value: "Drama" },
  { key: "FANTASY", value: "Fantasy" },
  { key: "HORROR", value: "Horror" },
  { key: "MYSTERY_THRILLER", value: "Mystery & Thriller" },
  { key: "ROMANCE", value: "Romance" },
  { key: "SCIENCE_FICTION", value: "Science Fiction" },
  { key: "BIOGRAPHY_MEMOIR", value: "Biography & Memoir" },
  { key: "HISTORY", value: "History" },
  { key: "POETRY", value: "Poetry" },
  { key: "CHILDREN_YA", value: "Children & Young Adult" },
  { key: "NONFICTION", value: "Nonfiction" },
  { key: "GRAPHIC_NOVELS_COMICS", value: "Graphic Novels & Comics" },
  { key: "CRIME", value: "Crime" },
  { key: "HISTORICAL_FICTION", value: "Historical Fiction" },
  { key: "URBAN_FICTION", value: "Urban Fiction" },
  { key: "DYSTOPIAN", value: "Dystopian" },
  { key: "SUSPENSE", value: "Suspense" },
  { key: "PSYCHOLOGICAL_THRILLER", value: "Psychological Thriller" },
  { key: "PARANORMAL", value: "Paranormal" },
  { key: "WESTERNS", value: "Westerns" },
  { key: "SHORT_STORIES", value: "Short Stories" },
  { key: "ESSAYS", value: "Essays" },
  { key: "SELF_HELP", value: "Self-help" },
  { key: "SPIRITUALITY", value: "Spirituality" },
  { key: "SCIENCE_NATURE", value: "Science & Nature" },
  { key: "TRAVEL", value: "Travel" },
  { key: "COOKBOOKS", value: "Cookbooks" },
  { key: "ART_PHOTOGRAPHY", value: "Art & Photography" },
  { key: "MYTHOLOGY", value: "Mythology" },
  { key: "FOLKLORE", value: "Folklore" },
  { key: "WAR_MILITARY", value: "War & Military" },
  { key: "GOTHIC", value: "Gothic" },
  { key: "CONTEMPORARY", value: "Contemporary" },
  { key: "FABLES_FAIRY_TALES", value: "Fables & Fairy Tales" },
  { key: "HUMOR", value: "Humor" },
  { key: "STEAMPUNK", value: "Steampunk" },
  { key: "CYBERPUNK", value: "Cyberpunk" },
  { key: "SPACE_OPERA", value: "Space Opera" },
  { key: "ALTERNATE_HISTORY", value: "Alternate History" },
  { key: "EPIC_FANTASY", value: "Epic Fantasy" },
  { key: "DARK_FANTASY", value: "Dark Fantasy" },
  { key: "HIGH_FANTASY", value: "High Fantasy" },
  { key: "MEDICAL", value: "Medical" },
  { key: "LEGAL_THRILLER", value: "Legal Thriller" },
  { key: "POLITICAL_THRILLER", value: "Political Thriller" },
  { key: "SPY_FICTION", value: "Spy Fiction" },
  { key: "TECHNO_THRILLER", value: "Techno-Thriller" },
  { key: "SAGA", value: "Saga" },
  { key: "WOMENS_FICTION", value: "Women's Fiction" },
  { key: "NEW_ADULT", value: "New Adult" },
  { key: "KIDS", value: "Kids" },
  { key: "ANIME", value: "Anime" },
  { key: "DOCUMENTARY", value: "Documentary" },
  { key: "REALITY_TV", value: "Reality TV" },
  { key: "FOREIGN_FILMS", value: "Foreign Films" },
  { key: "MUSIC_MUSICALS", value: "Music & Musicals" },
  { key: "SPORTS", value: "Sports" },
  { key: "GAME_SHOWS", value: "Game Shows" },
  { key: "TALK_SHOWS", value: "Talk Shows" },
  { key: "ANIMATION", value: "Animation" },
];

export const GENRE_TAGS: Tag[] = storyGenres.map(({ key, value }) => ({ key, value }));
