import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalStoryGenerateState } from "../../../states/ModalState.ts";
import {
  useGenerateBeatSheet,
  useGenerateCharactersSummary,
  useGenerateIdeas,
  useGenerateLogLine,
  useGenerateOnePager,
} from "./useGenerateHooks.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalStoryGenerateState);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [selectedIdea, setSelectedIdea] = useState<string | null>(null);

  const {
    data: ideas,
    status: ideasStatus,
    callApi: generateIdeas,
    setData: setIdeas,
  } = useGenerateIdeas();

  const {
    data: logLine,
    status: logLineStatus,
    callApi: generateLogLine,
    setData: setLogLine,
  } = useGenerateLogLine();

  const {
    data: onePager,
    status: onePagerStatus,
    callApi: generateOnePager,
    setData: setOnePager,
  } = useGenerateOnePager();

  const {
    data: charactersSummary,
    status: charactersSummaryStatus,
    callApi: generateCharactersSummary,
    setData: setCharactersSummary,
  } = useGenerateCharactersSummary();

  const {
    data: beatSheet,
    status: beatSheetStatus,
    callApi: generateBeatSheet,
    setData: setBeatSheet,
  } = useGenerateBeatSheet();

  const handleClose = () => {
    setIsOpen(false);
    setIdeas(null);
    setStepIndex(0);
    setLogLine(null);
    setOnePager(null);
    setCharactersSummary(null);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    selectedIdea,
    setSelectedIdea,
    ideas,
    ideasStatus,
    generateIdeas,
    setIdeas,
    logLine,
    logLineStatus,
    generateLogLine,
    setLogLine,
    onePager,
    onePagerStatus,
    generateOnePager,
    setOnePager,
    charactersSummary,
    charactersSummaryStatus,
    generateCharactersSummary,
    setCharactersSummary,
    beatSheet,
    beatSheetStatus,
    generateBeatSheet,
    setBeatSheet,
    handleClose,
  };
};
