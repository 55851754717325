import React, {createContext, ReactNode, useContext, useEffect, useRef, useState} from 'react';
import {WebSocketWrapper} from "./WebSocketWrapper.ts";
import {useWebSocketMessageHandler} from "./useWebSocketMessageHandler.ts";
import {DEV_FAST_API_WS} from "../vendor/config.ts";
import {supabase} from "../vendor/supabaseClient.ts";

const WebSocketContext = createContext<WebSocketWrapper | null>(null);

export const useWebSocket = (): WebSocketWrapper | null => {
    return useContext(WebSocketContext);
};

type WebSocketProviderProps = {
    children: ReactNode;
};

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({children}) => {
    const wsRef = useRef<WebSocketWrapper | null>(null);
    const [serverAvailable, setServerAvailable] = useState<boolean>(false);
    const handleMessage = useWebSocketMessageHandler();

    const connectWebSocket = async () => {
        if (wsRef.current && wsRef.current.ws.readyState === WebSocket.OPEN) {
            return; // If the WebSocket is already open, do not reconnect
        }
        const {data} = await supabase.auth.getSession()
        let jwtToken: string
        if (!data || !data.session) {
            return
        } else {
            jwtToken = data.session?.access_token
        }
        let URL = import.meta.env.VITE_FAST_API_WS || DEV_FAST_API_WS
        URL += `?token=${encodeURIComponent(jwtToken)}`
        const ws = new WebSocket(URL);
        ws.binaryType = 'arraybuffer';
        wsRef.current = new WebSocketWrapper(ws);

        ws.onopen = () => {
            console.log("Connected to the WebSocket.");
            setServerAvailable(true);
        };

        ws.onmessage = (event: MessageEvent) => {

            // Check if the message data is in binary format (ArrayBuffer)
            if (event.data instanceof ArrayBuffer) {
                const dataBuffer = new Uint8Array(event.data);

                // Find the null byte indicating the end of the message type
                const nullByteIndex = dataBuffer.findIndex(element => element === 0);
                if (nullByteIndex === -1) {
                    console.error("Invalid message format: No null byte found.");
                    return;
                }

                // Extract the message type
                const typeBuffer = dataBuffer.subarray(0, nullByteIndex);
                const messageType = new TextDecoder().decode(typeBuffer);

                // Extract the data
                const messageDataBuffer = dataBuffer.subarray(nullByteIndex + 1);

                handleMessage(messageType, messageDataBuffer);

            } else {
                // Handle non-binary messages if needed
                console.log("Received non-binary message:", event.data);
            }
        };


        ws.onclose = () => {
            console.log("WebSocket disconnected.");
            setServerAvailable(false);
        };
    };

    // Effect for initial connection
    useEffect(() => {
        connectWebSocket();
        return () => {
            wsRef.current?.ws.close();
        };
    }, []);

    // Effect for handling reconnections
    useEffect(() => {
        let interval: ReturnType<typeof setInterval>;

        if (!serverAvailable) {
            interval = setInterval(() => {
                console.log("Attempting to reconnect...");
                connectWebSocket();
            }, 5000); // Attempt to reconnect every 5 seconds
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [serverAvailable]);

    return (
        <WebSocketContext.Provider value={wsRef.current}>
            {children}
        </WebSocketContext.Provider>
    );
};
