import { FormEvent, useState } from "react";
import { supabase } from "../vendor/supabaseClient.ts";
import SvgLogo from "../components/SvgLogo.tsx";
import { Input } from "../components/catalyst/input.tsx";
import FormField from "../components/admin/FormField.tsx";
import { SpokableButton } from "../components/admin/SpokableButton.tsx";

export default function AuthScreen() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLogin, setIsLogin] = useState<boolean>(true);

  const handleAuth = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLogin) {
      const { error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });
      if (error) {
        alert(error.message);
      } else {
        alert("Logged in successfully!");
      }
    } else {
      const { error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });
      if (error) {
        alert(error.message);
      } else {
        alert("Signup successful! Please check your email for verification.");
      }
    }
  };

  return (
    <main className="grow white">
      <section className="relative h-screen">
        <div
          className="md:block absolute left-1/2 -translate-x-1/2 -mt-36 blur-2xl opacity-70 pointer-events-none "
          aria-hidden="true"
        ></div>
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="max-w-3xl mx-auto text-center pb-12">
              <div className="flex justify-center items-center ">
                <SvgLogo color={"black"} />
              </div>
              <h1 className="font-sans bold text-4xl">SPOKABLE</h1>
            </div>
            <div className="max-w-sm mx-auto">
              <form onSubmit={handleAuth}>
                <div className="space-y-4">
                  <div>
                    <FormField label={email.length === 0 ? "" : "Email"}>
                      <Input
                        className="form-input w-full"
                        type="email"
                        required
                        placeholder="Your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormField>
                  </div>
                  <div>
                    <FormField label={password.length === 0 ? "" : "Password"}>
                      <Input
                        className="form-input w-full"
                        type="password"
                        required
                        placeholder="Your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="mt-6">
                  <SpokableButton type="submit" className="w-full shadow-sm group">
                    {isLogin ? "Sign In" : "Sign Up"}
                  </SpokableButton>
                </div>
              </form>
              <div className="mt-4 text-center">
                <button
                  onClick={() => setIsLogin(!isLogin)}
                  className="text-sm text-blue-600 hover:underline"
                >
                  {isLogin ? "Need an account? Sign up" : "Already have an account? Log in"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
