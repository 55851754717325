import React from "react";
import { Button, ButtonProps } from "../catalyst/button.tsx";

export type SpokableButtonProps = ButtonProps & {
  isIconButton?: boolean;
};

export const SpokableButton = React.forwardRef<HTMLElement, SpokableButtonProps>(
  ({ className, children, isIconButton = false, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        className={`!rounded-full 
          before:!rounded-full 
          after:!rounded-full
          after:!shadow-none
          before:!shadow-none
          after:opacity-0 hover:after:opacity-100
          after:transition-opacity after:duration-200
          !font-light
         
           ${isIconButton ? "" : "!px-4"} 
           ${className || ""}`}
        {...props}
      >
        {children}
      </Button>
    );
  },
);

SpokableButton.displayName = "SpokableButton";
