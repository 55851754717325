import { useParams } from "react-router-dom";
import { PlayIcon } from "@heroicons/react/16/solid";

import SideNavLayout from "../../../components/admin/SideNavLayout";
import { Card, CardContent } from "@/components/admin/Card";
import EditableTitle from "@/components/EditableTitle";
import { SpokableButton } from "@/components/admin/SpokableButton";
import { useEvalRunDatabase } from "@/components/admin/promptManagement/database/useEvalRunDatabase";
import PromptDefinitionEditor from "@/components/admin/promptManagement/PromptDefinitionEditor.tsx";
import DatasetModalButton from "@/components/admin/promptManagement/DatasetModalButton.tsx";
import usePromptManagementWebSocket, {
  PromptManagementMessageType,
  WebSocketPromptManagementMessage,
} from "@/components/admin/promptManagement/service/usePromptManagementWebSocket.ts";
import { useEffect, useState } from "react";
import {
  DTOCriteria,
  DTOEvalRunDefinition,
  DTOInputOrOutputSchema,
  DTOPromptDef,
  DTOPromptExecutionLog,
} from "@/types/fastApiPromptManagerTypes.ts";
import { DatabaseTypes } from "@/components/admin/promptManagement/database/databaseOperations.ts";

interface AudioAnswer {
  received: string;
}

export default function EvalRun() {
  const { evalId, promptId } = useParams();
  const {
    promptEval,
    prompt,
    criterias,
    errorMessage,
    handleSaveEval,
    setErrorMessage,
    fetchPromptExecutionLogs,
    promptExecutionLogs,
    latestPromptForRun,
  } = useEvalRunDatabase(evalId, promptId);

  const [isPromptModified, setIsPromptModified] = useState<boolean>(false);
  const [audioAnswer, setAudioAnswer] = useState<AudioAnswer | null>(null);
  const [modifiedPrompt, setModifiedPrompt] = useState<
    DatabaseTypes["LatestPromptVersionForRun"] | null
  >(null);
  const ws = usePromptManagementWebSocket();

  useEffect(() => {
    ws.onReceive(
      PromptManagementMessageType.RUN_EVAL,
      (message: WebSocketPromptManagementMessage) => {
        switch (message.messageType) {
          case PromptManagementMessageType.RUN_EVAL:
            setAudioAnswer(message.messageObject as AudioAnswer);
            break;
          default:
            console.error("Unknown message type:", message.messageType);
        }
      },
    );
  }, [ws]);

  const runEval = async () => {
    if (!latestPromptForRun) return;

    const prompt = isPromptModified && modifiedPrompt ? modifiedPrompt : latestPromptForRun;
    console.log(prompt.general_instructions);
    console.log(prompt.output_schema);
    const dtoCriteria: DTOCriteria[] = criterias.map(
      (criteria): DTOCriteria => ({
        criteria_name: criteria.eval_criteria_name,
        criteria_prompt: criteria.criteria,
      }),
    );

    const dtoPromptDef: DTOPromptDef = {
      input_schema: prompt.input_schema as unknown as DTOInputOrOutputSchema,
      general_instruction: prompt.general_instructions || "",
      output_schema: prompt.output_schema as unknown as DTOInputOrOutputSchema,
    };

    const dtoPromptExecutionLogs: DTOPromptExecutionLog[] = promptExecutionLogs.map(
      (log): DTOPromptExecutionLog => ({
        variable_input: JSON.stringify(log.prompt_variable_inputs),
        llm_answer: log.response || "",
      }),
    );

    const runDefinition: DTOEvalRunDefinition = {
      criterias: dtoCriteria,
      prompt_def: dtoPromptDef,
      promptExecutionLogs: dtoPromptExecutionLogs,
      is_prompt_overridden: isPromptModified,
    };

    ws.send(PromptManagementMessageType.RUN_EVAL, runDefinition);
  };

  if (!promptEval || !latestPromptForRun || !promptId || !evalId)
    return (
      <div className="flex items-center justify-center h-32">
        <div className="animate-pulse text-gray-500">Loading...</div>
      </div>
    );

  return (
    <SideNavLayout>
      <div className="mb-20">
        <div className="top-sectio sticky top-0 backdrop-blur-xl z-50 p-6">
          {errorMessage && (
            <div
              className={`rounded px-4 py-3 mb-4 cursor-pointer 
                  bg-gray-100 border border-gray-400 text-gray-700
              `}
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}
          <EditableTitle
            initialTitle={promptEval.eval_name}
            titleClassName={"text-5xl font-bold font-serif"}
            onSave={(newTitle) => {
              const newPromptEval = {
                ...promptEval,
                eval_name: newTitle,
              };
              handleSaveEval(newPromptEval);
            }}
          />
          <p>{audioAnswer && audioAnswer.received}</p>
          <h3 className="text-xl font-bold mb-4 text-zinc-500">{`${prompt?.prompt_name}`}</h3>
          <div className="flex gap-4">
            <SpokableButton onClick={() => runEval()}>
              <PlayIcon />
              Run
            </SpokableButton>
            <DatasetModalButton
              promptId={promptId}
              evalId={evalId}
              onClose={() => fetchPromptExecutionLogs()}
            />
          </div>
        </div>
        <Card isFullWidth={true}>
          <CardContent>
            <div className="flex gap-4 h-[calc(100vh-230px)] w-full">
              <div className="w-1/2 overflow-hidden">
                <div className="h-full overflow-y-auto">
                  <div className="mr-12">
                    <PromptDefinitionEditor
                      latestPromptForRun={latestPromptForRun}
                      onPromptChange={(modifiedPrompt) => setModifiedPrompt(modifiedPrompt)}
                      onModificationStateChange={(isModified) => setIsPromptModified(isModified)}
                    />
                    <SpokableButton onClick={() => console.log(criterias.length)}>
                      <PlayIcon />
                      Run
                    </SpokableButton>
                  </div>
                </div>
              </div>
              <div className="w-1/2 overflow-hidden">
                <div className="h-full overflow-y-auto">
                  <CardContent className="pl-10 mt-1">
                    <div className="text-gray-500 text-center py-8">No result yet</div>
                  </CardContent>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </SideNavLayout>
  );
}
