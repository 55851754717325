import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalSoundGenerateState } from "../../../states/ModalState.ts";
import { useGenerateSound } from "./useGenerateChaptersHooks.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalSoundGenerateState);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const {
    data: sound,
    status: soundStatus,
    callApi: generateSound,
    setData: setSound,
  } = useGenerateSound();

  const handleClose = () => {
    setIsOpen(false);
    setStepIndex(0);
    setSound(null);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    soundOutput: sound,
    soundStatus,
    generateSound,
    setSound,

    handleClose,
  };
};
