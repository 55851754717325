import { atom } from "recoil";

export const modalState = atom({
  key: "modalState",
  default: false,
});
export const clickedCatalogueImageState = atom<DOMRect | null>({
  key: "clickedCatalogueImageState",
  default: null,
});
