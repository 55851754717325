import {
  databaseOperations,
  DatabaseTypes,
  handleDatabaseError,
  showTemporaryMessage,
} from "@/components/admin/promptManagement/database/databaseOperations.ts";
import { useEffect, useState } from "react";

interface UsePromptEvalDetailReturn {
  promptEval: DatabaseTypes["Eval"] | null;
  prompt: DatabaseTypes["Prompt"] | null;
  criterias: DatabaseTypes["Criteria"][];
  selectedCriteria: DatabaseTypes["Criteria"] | null;
  errorMessage: string | null;
  setSelectedCriteria: (criteria: DatabaseTypes["Criteria"] | null) => void;
  handleSaveEval: (promptEval: DatabaseTypes["Eval"]) => Promise<void>;
  handleSaveCriteria: (criteria: DatabaseTypes["Criteria"]) => Promise<void>;
  handleCreateCriteria: () => Promise<void>;
  setErrorMessage: (message: string | null) => void;
}

export const useEvalDetailDatabase = (
  evalId: string | undefined,
  promptId: string | undefined,
): UsePromptEvalDetailReturn => {
  const [promptEval, setPromptEval] = useState<DatabaseTypes["Eval"] | null>(null);
  const [prompt, setPrompt] = useState<DatabaseTypes["Prompt"] | null>(null);
  const [criterias, setCriterias] = useState<DatabaseTypes["Criteria"][]>([]);
  const [selectedCriteria, setSelectedCriteria] = useState<DatabaseTypes["Criteria"] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchData = async () => {
    if (!evalId || !promptId) return;

    try {
      // Fetch eval with correct operation
      const evalResponse = await databaseOperations.fetchEvalById(evalId);
      if (evalResponse.error) throw evalResponse.error;
      setPromptEval(evalResponse.data);

      // Fetch prompt
      const promptResponse = await databaseOperations.fetchPrompt(promptId);
      if (promptResponse.error) throw promptResponse.error;
      setPrompt(promptResponse.data);

      // Fetch criterias
      const criteriasResponse = await databaseOperations.fetchEvalCriterias(evalId);
      if (criteriasResponse.error) throw criteriasResponse.error;
      setCriterias(criteriasResponse.data);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  const handleSaveEval = async (promptEval: DatabaseTypes["Eval"]) => {
    try {
      const { error } = await databaseOperations.updateEval(promptEval.id, promptEval.eval_name);
      if (error) throw error;

      setPromptEval(promptEval);
      showTemporaryMessage("Successfully saved!", setErrorMessage);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  const handleSaveCriteria = async (criteria: DatabaseTypes["Criteria"]) => {
    try {
      const { error } = await databaseOperations.updateCriteria(criteria.id, {
        eval_criteria_name: criteria.eval_criteria_name,
        criteria: criteria.criteria,
      });
      if (error) throw error;

      await fetchData();
      showTemporaryMessage("Successfully saved!", setErrorMessage);
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  const handleCreateCriteria = async () => {
    if (!evalId) return;
    try {
      const { error } = await databaseOperations.createCriteria(evalId);
      if (error) throw error;
      await fetchData();
    } catch (error) {
      handleDatabaseError(error as Error, setErrorMessage);
    }
  };

  useEffect(() => {
    fetchData();
  }, [evalId, promptId]);

  return {
    promptEval,
    prompt,
    criterias,
    selectedCriteria,
    errorMessage,
    setSelectedCriteria,
    handleSaveEval,
    handleSaveCriteria,
    handleCreateCriteria,
    setErrorMessage,
  };
};
