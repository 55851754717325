import React from "react";
import { useDeleteChapter, useUpdateChapter } from "../../../hooks/database/useChapter.ts";
import { Tables } from "../../../types/database.ts";
import { SpokableButton } from "../SpokableButton.tsx";

interface DeleteChapterComponentProps {
  chapterId: string | undefined | null;
  chapters: Tables<"blueprint_chapters">[] | undefined;
  onChapterDeleted?: () => void;
}

const DeleteChapterButton: React.FC<DeleteChapterComponentProps> = ({
  chapterId,
  chapters,
  onChapterDeleted,
}) => {
  const { updateChapter } = useUpdateChapter();
  const { deleteChapter } = useDeleteChapter(updateChapter);

  const handleDelete = () => {
    if (!chapterId) return;
    if (!chapters) return;
    deleteChapter(chapterId, chapters, onChapterDeleted);
  };

  return (
    <SpokableButton color={"light"} onClick={handleDelete}>
      Delete
    </SpokableButton>
  );
};

export default DeleteChapterButton;
