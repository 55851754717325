import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/catalyst/table.tsx";
import { LOCALE_ENGLISH, PAGE_CONTEXT } from "../../constants/constant.ts";
import { formatDateString } from "../../utils/date.ts";
import { Card, CardContent, CardH1 } from "../../components/admin/Card.tsx";
import { capitalize, truncateText } from "../../utils/stringUtil.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import { useEffect, useState } from "react";
import { Tables, TablesInsert } from "../../types/database.ts";
import { GlobalSideNavLayout } from "../../components/admin/GlobalSideNavLayout.tsx";
import { SpokableButton } from "../../components/admin/SpokableButton.tsx";

function ContextList() {
  const { storyId } = useParams<{
    storyId: string;
  }>();
  const navigate = useNavigate();
  const [contextBlocks, setContextBlocks] = useState<Tables<"blueprint_context_blocks">[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchContextBlocks();
  }, []);

  async function fetchContextBlocks() {
    if (storyId == undefined) return;
    const { data, error } = await supabase
      .from("blueprint_context_blocks")
      .select("*")
      .eq("blueprint_story_id", storyId)
      .order("created_at", { ascending: true });

    if (error) {
      setErrorMessage("Error fetching chapters: " + error.message);
    } else {
      setContextBlocks(data);
    }
  }

  async function createContext() {
    if (storyId == undefined) return;

    const contextData: TablesInsert<"blueprint_context_blocks"> = {
      blueprint_story_id: storyId,
      context_block_name: "New context",
    };

    const { data, error } = await supabase
      .from("blueprint_context_blocks")
      .insert(contextData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error creating chapter: " + error.message);
    } else {
      const createdContext = data as Tables<"blueprint_context_blocks">;
      navigate(`/admin/contexts/${storyId}/${createdContext.id}`);
    }
  }

  async function deleteContext(contextId: string) {
    if (window.confirm("Are you sure you want to delete this context?")) {
      const { error } = await supabase
        .from("blueprint_context_blocks")
        .delete()
        .eq("id", contextId);

      if (error) {
        console.error("Error deleting context:", error);
      } else {
        fetchContextBlocks();
      }
    } else {
      // User clicked 'Cancel' or closed the confirmation dialog
      console.log("context deletion cancelled by user.");
    }
  }

  return (
    <GlobalSideNavLayout activePageId={PAGE_CONTEXT} storyId={storyId}>
      <Card isFullWidth={true}>
        {errorMessage && (
          <div
            className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
            onClick={() => setErrorMessage(null)}
          >
            {errorMessage}
          </div>
        )}
        <CardContent>
          <CardH1 label="Contexts" id="context" />
          <div className="flex justify-between items-center mt-8 mb-4">
            <SpokableButton onClick={createContext}>Create New context</SpokableButton>
          </div>
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
            <TableHead>
              <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Updated at</TableHeader>
                <TableHeader>Desc</TableHeader>
                <TableHeader></TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {contextBlocks.map((context) => (
                <TableRow key={context.id} href={`/admin/contexts/${storyId}/${context.id}`}>
                  <TableCell>{capitalize(context.context_block_name)}</TableCell>
                  <TableCell>
                    {context.updated_at ? formatDateString(context.updated_at, LOCALE_ENGLISH) : ""}
                  </TableCell>
                  <TableCell>{truncateText(context.context_block_description, 100)}</TableCell>
                  <TableCell>
                    <SpokableButton color={"light"} onClick={() => deleteContext(context.id)}>
                      Delete
                    </SpokableButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </GlobalSideNavLayout>
  );
}

export default ContextList;
