import { KeyboardEvent, useState } from "react";
import { Input } from "../../catalyst/input.tsx";
import { Select } from "../../catalyst/select.tsx";
import FormField from "../FormField.tsx";
import { DatePickerWithRange } from "@/components/admin/DatePickerWithRange.tsx";
import { DateRange } from "react-day-picker";
import { SearchFilters } from "@/components/admin/promptManagement/database/databaseOperations.ts";

const SearchInterface = ({
  onSearch,
  isLoading,
  hideUsedInEval,
}: {
  onSearch: (filters: SearchFilters) => void;
  isLoading: boolean;
  hideUsedInEval: boolean;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  });
  const [version, setVersion] = useState("");
  const [evalStatus, setEvalStatus] = useState<"all" | "true" | "false">(
    hideUsedInEval ? "true" : "all",
  );

  const handleSearch = () => {
    const searchFilters: SearchFilters = {
      searchTerm,
      dateRange:
        dateRange && dateRange.from
          ? {
              startDate: dateRange.from.toISOString(),
              endDate: dateRange.to?.toISOString() || null,
            }
          : null,
      version,
      evalStatus,
    };
    onSearch(searchFilters);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.key === "Enter" && !isLoading) {
      handleSearch();
    }
  };

  return (
    <div className="space-y-4 p-4 bg-white rounded-lg border border-gray-200">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        {/* Search Term */}
        <FormField label="Search Response">
          <Input
            id="search"
            placeholder="Search in response..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            className="w-full"
          />
        </FormField>
        {/* Date Range */}
        <FormField label="Date Range">
          <DatePickerWithRange date={dateRange} onDateChange={setDateRange} />
        </FormField>
        {/* Version */}
        <FormField label="Prompt Version">
          <Input
            id="version"
            placeholder="Enter version..."
            value={version}
            onChange={(e) => setVersion(e.target.value)}
            onKeyDown={handleKeyDown}
            className="w-full"
          />
        </FormField>
        {!hideUsedInEval && (
          <FormField label="Used in Eval">
            <Select
              value={evalStatus}
              onChange={(e) => setEvalStatus(e.target.value as "all" | "true" | "false")}
              onKeyDown={handleKeyDown}
            >
              <option value="all">All</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormField>
        )}
        <div className="flex items-center justify-center">
          <button
            onClick={handleSearch}
            disabled={isLoading}
            className="px-4 py-2 bg-black text-white rounded-md hover:bg-black/90 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? "Searching..." : "Search"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchInterface;
