import React, { useState } from "react";
import { Tables, TablesInsert } from "../../../types/database.ts";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import { getEnvironment } from "../../../utils/envUtil.ts";

interface CreateChapterProps {
  story: Tables<"blueprint_stories">;
  chapters: Tables<"blueprint_chapters">[];
  onChapterCreated?: (newChapter: Tables<"blueprint_chapters">) => void;
  transitionFromChapterId?: string;
}

const CreateChapter: React.FC<CreateChapterProps> = ({
  story,
  chapters,
  onChapterCreated,
  transitionFromChapterId,
}) => {
  const [error, setErrorMessage] = useState<string | null>(null);

  async function createCharacter(): Promise<Tables<"blueprint_characters"> | null> {
    const { data: voice, error: voiceError } = await supabase
      .from("blueprint_voices")
      .select("*")
      .limit(1)
      .eq("environment", getEnvironment())
      .single();

    if (!voice) return null;

    const characterData: TablesInsert<"blueprint_characters"> = {
      name: "Default character",
      voice_id: voice.id,
      blueprint_story_id: story.id,
    };

    const { data, error } = await supabase
      .from("blueprint_characters")
      .insert(characterData)
      .select()
      .limit(1)
      .single();

    if (error || voiceError) {
      setErrorMessage(`Error creating character: ${error?.message || voiceError}`);
      return null;
    }

    return data as Tables<"blueprint_characters">;
  }

  const createTransition = async (fromChapterId: string, toChapterId: string) => {
    const transitionData: TablesInsert<"blueprint_chapter_transitions"> = {
      current_chapter_id: fromChapterId,
      next_chapter_id: toChapterId,
      blueprint_story_id: story.id,
      condition: "",
    };

    const { error } = await supabase.from("blueprint_chapter_transitions").insert(transitionData);

    if (error) {
      setErrorMessage(`Error creating transition: ${error.message}`);
      return false;
    }
    return true;
  };

  const createChapter = async (
    isStarting: boolean = false,
  ): Promise<Tables<"blueprint_chapters"> | null> => {
    let character = await supabase
      .from("blueprint_characters")
      .select("*")
      .eq("blueprint_story_id", story.id)
      .limit(1)
      .single()
      .then(({ data }) => data);

    if (!character) {
      character = await createCharacter();
    }

    if (!character) return null;

    const chapterData = {
      blueprint_story_id: story.id,
      chapter_name: `New Chapter`,
      is_starting_chapter: isStarting,
      text_rgba_color: story?.text_rgba_color,
      background_rgba_color: story?.background_rgba_color,
      control_rgba_color: story?.control_rgba_color,
      blueprint_character_id: character.id,
    };

    const { data, error } = await supabase
      .from("blueprint_chapters")
      .insert(chapterData)
      .select()
      .single();

    if (error) {
      setErrorMessage(`Error creating chapter: ${error.message}`);
      return null;
    }

    return data as Tables<"blueprint_chapters">;
  };

  const handleCreateChapter = async () => {
    let newChapter: Tables<"blueprint_chapters"> | null = null;
    let secondChapter: Tables<"blueprint_chapters"> | null = null;

    if (chapters.length === 0) {
      // Create two chapters and a transition between them
      newChapter = await createChapter(true);
      if (newChapter) {
        secondChapter = await createChapter();
        if (secondChapter) {
          await createTransition(newChapter.id, secondChapter.id);
        }
      }
    } else if (chapters.length === 1) {
      // Create one chapter and link it to the existing one
      newChapter = await createChapter();
      if (newChapter) {
        await createTransition(chapters[0].id, newChapter.id);
      }
    } else {
      // Create a single chapter
      newChapter = await createChapter();

      // If transitionFromChapterId is provided, create a transition
      if (transitionFromChapterId && newChapter) {
        await createTransition(transitionFromChapterId, newChapter.id);
      }
    }

    if (newChapter && onChapterCreated) {
      onChapterCreated(newChapter);
    }
    if (secondChapter && onChapterCreated) {
      onChapterCreated(secondChapter);
    }
  };

  return (
    <div className="space-y-4">
      {error && <p className="text-red-500">{error}</p>}
      <SpokableButton onClick={handleCreateChapter}>
        {chapters.length === 0 ? "Create Initial Chapters" : "Create Chapter"}
      </SpokableButton>
    </div>
  );
};

export default CreateChapter;
