import React, { useEffect, useRef, useState } from "react";
import { DatabaseTypes } from "@/components/admin/promptManagement/database/databaseOperations.ts";
import FormField from "@/components/admin/FormField.tsx";
import AutoResizeTextArea from "@/components/admin/AutoResizeTextArea.tsx";
import { cloneDeep, isEqual } from "lodash";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";

interface PromptDefinitionRendererProps {
  latestPromptForRun: DatabaseTypes["LatestPromptVersionForRun"];
  onPromptChange?: (promptDef: DatabaseTypes["LatestPromptVersionForRun"]) => void;
  onModificationStateChange?: (isModified: boolean) => void;
}

interface SchemaField {
  field_name: string;
  description: string;

  [key: string]: string | undefined;
}

interface InputOrOutputSchema {
  schema_name: string;
  fields: SchemaField[];
}

const PromptDefinitionEditor: React.FC<PromptDefinitionRendererProps> = ({
  latestPromptForRun,
  onPromptChange,
  onModificationStateChange,
}) => {
  const originalPromptDef = useRef(cloneDeep(latestPromptForRun));
  const [promptDef, setPromptDef] = useState<DatabaseTypes["LatestPromptVersionForRun"]>(
    cloneDeep(latestPromptForRun),
  );
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    onPromptChange?.(promptDef);
  }, [promptDef, onPromptChange]);

  const checkIfModified = (newValue: DatabaseTypes["LatestPromptVersionForRun"]) => {
    const isChanged = !isEqual(newValue, originalPromptDef.current);
    setIsModified(isChanged);
    onModificationStateChange?.(isChanged);
  };

  const handleGeneralInstructionsUpdate = (value: string) => {
    const newPromptDef = { ...promptDef, general_instructions: value };
    setPromptDef(newPromptDef);
    checkIfModified(newPromptDef);
  };

  const handleDescriptionUpdate = (
    schemaType: "input_schema" | "output_schema",
    index: number,
    value: string,
  ) => {
    setPromptDef((prev) => {
      const newPromptDef = { ...prev };
      const schema = { ...(newPromptDef[schemaType] as unknown as InputOrOutputSchema) };
      const fields = [...schema.fields];

      fields[index] = {
        ...fields[index],
        description: value,
      };

      newPromptDef[schemaType] = {
        ...schema,
        fields,
      } as unknown as (typeof prev)[typeof schemaType];

      checkIfModified(newPromptDef);
      return newPromptDef;
    });
  };

  const handleReset = () => {
    const resetValue = cloneDeep(originalPromptDef.current);
    setPromptDef(resetValue);
    setIsModified(false);
    onModificationStateChange?.(false);
    onPromptChange?.(resetValue);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Prompt Definition</h2>
        <div className="flex items-center gap-4">
          {isModified && (
            <>
              <span>Changes detected</span>
              <SpokableButton onClick={handleReset} color="light">
                Reset Changes
              </SpokableButton>
            </>
          )}
        </div>
      </div>
      <FormField label="General instruction" isSerif={true}>
        <AutoResizeTextArea
          value={promptDef.general_instructions || ""}
          onChange={handleGeneralInstructionsUpdate}
          readonly={false}
          className="max-w-3xl mr-auto"
        />
      </FormField>
      <FormField label="Input schema" divider={true} isSerif={true}>
        {(promptDef.input_schema as unknown as InputOrOutputSchema).fields.map((field, index) => (
          <div key={`input-${field.field_name}-${index}`} className="mb-4">
            <label className="block text-sm text-zinc-500 font-medium mb-2">
              {field.field_name}
            </label>
            <AutoResizeTextArea
              value={field.description || ""}
              onChange={(value) => handleDescriptionUpdate("input_schema", index, value)}
              readonly={false}
              className="max-w-3xl mr-auto"
              minNumberOfRows={1}
            />
          </div>
        ))}
      </FormField>
      <FormField label="Output schema" divider={true} isSerif={true}>
        {(promptDef.output_schema as unknown as InputOrOutputSchema).fields.map((field, index) => (
          <div key={`output-${field.field_name}-${index}`} className="mb-4">
            <label className="block text-sm text-zinc-500 font-medium mb-2">
              {field.field_name}
            </label>
            <AutoResizeTextArea
              value={field.description || ""}
              onChange={(value) => handleDescriptionUpdate("output_schema", index, value)}
              readonly={false}
              className="max-w-3xl mr-auto"
              minNumberOfRows={1}
            />
          </div>
        ))}
      </FormField>
    </div>
  );
};

export default PromptDefinitionEditor;
