import { useState } from "react";
import { useRecoilState } from "recoil";
import { useGenerateChapters } from "./useGenerateChaptersHooks.ts";
import { modalChaptersGenerateState } from "../../../states/ModalState.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalChaptersGenerateState);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const {
    data: chapters,
    status: chapterStatus,
    callApi: generateChapters,
    setData: setChapters,
  } = useGenerateChapters();

  const handleClose = () => {
    setIsOpen(false);
    setStepIndex(0);
    setChapters(null);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    chapters,
    chapterStatus,
    generateChapters,
    setChapters,

    handleClose,
  };
};
