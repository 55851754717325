import React, { useState } from "react";
import { Chapter, Chapters } from "../../../types/fastApiTypes";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { Tables, TablesInsert } from "../../../types/database.ts";
import { DialogTitle } from "../../catalyst/dialog.tsx";
import { SpokableButton } from "../SpokableButton.tsx";

export interface SaveAllProps {
  story: Tables<"blueprint_stories"> | null;
  chapters: Chapters | null;
  scene: Tables<"blueprint_scenes"> | null;
  handleClose: () => void;
}

const SaveAll: React.FC<SaveAllProps> = ({ story, chapters, scene, handleClose }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const saveAll = async () => {
    if (!chapters || !story) {
      setErrorMessage("Missing chapters or story data");
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);

    try {
      const chapterMap = new Map<string, Chapter>();
      const savedChapters = await createChaptersSequentially(chapters.chapters, chapterMap);

      if (savedChapters.length > 1) {
        await createTransitions(savedChapters, chapterMap);
      }

      handleClose();
    } catch (error) {
      setErrorMessage(
        `Error saving chapters: ${error instanceof Error ? error.message : String(error)}`,
      );
    } finally {
      setIsProcessing(false);
    }
  };

  async function createChaptersSequentially(
    chaptersList: Chapter[],
    chapterMap: Map<string, Chapter>,
  ): Promise<Tables<"blueprint_chapters">[]> {
    let parentChapterId: string | null = null;
    const savedChapters: Tables<"blueprint_chapters">[] = [];

    for (const [index, chapter] of chaptersList.entries()) {
      const savedChapter = await createChapter(chapter, parentChapterId, chapterMap);
      if (savedChapter) {
        savedChapters.push(savedChapter);
        if (index === 0) {
          parentChapterId = savedChapter.id;
        }
      }
    }

    return savedChapters;
  }

  async function createChapter(
    chapter: Chapter,
    parentChapterId: string | null,
    chapterMap: Map<string, Chapter>,
  ): Promise<Tables<"blueprint_chapters"> | null> {
    if (!story) return null;
    if (!scene) return null;

    const chaptersData: TablesInsert<"blueprint_chapters"> = {
      blueprint_character_id: chapter.blueprint_character_id,
      blueprint_story_id: story.id,
      chapter_name: chapter.chapter_name,
      chapter_objectives: chapter.chapter_objectives,
      chapter_setting: scene.scene_location + " " + scene.scene_summary,
      chapter_traps: chapter.chapter_traps,
      chapter_type: chapter.chapter_type,
      parent_chapter_id: parentChapterId,
      scene_id: scene.id,
      starting_dialogue: chapter.starting_dialogue,
      image_generation_prompt: chapter.image_generation_prompt,
      video_generation_prompt: chapter.video_generation_prompt,
      video_generation_event_prompt: chapter.video_generation_event_prompt,
      background_sound_prompt: chapter.background_sound_prompt,
      opening_sound_prompt: chapter.opening_sound_prompt,
      end_sound_prompt: chapter.end_sound_prompt,
    };

    const { data, error } = await supabase
      .from("blueprint_chapters")
      .insert(chaptersData)
      .select()
      .single();

    if (error) {
      throw new Error(`Error inserting chapter: ${error.message}`);
    } else {
      setErrorMessage((prev) => `${prev ? prev + "\n" : ""}Saved chapter: ${data.chapter_name}`);
      chapterMap.set(data.id, chapter);
      return data;
    }
  }

  async function createTransitions(
    savedChapters: Tables<"blueprint_chapters">[],
    chapterMap: Map<string, Chapter>,
  ): Promise<void> {
    for (let i = 0; i < savedChapters.length - 1; i++) {
      const currentChapter = savedChapters[i];
      const nextChapter = savedChapters[i + 1];
      await createTransition(currentChapter, nextChapter, chapterMap);
    }
  }

  async function createTransition(
    currentChapter: Tables<"blueprint_chapters">,
    nextChapter: Tables<"blueprint_chapters">,
    chapterMap: Map<string, Chapter>,
  ): Promise<void> {
    if (!story) return;
    const originalCurrentChapter = chapterMap.get(currentChapter.id);

    const transitionData: TablesInsert<"blueprint_chapter_transitions"> = {
      blueprint_story_id: story.id,
      condition: originalCurrentChapter?.chapter_objectives || "",
      current_chapter_id: currentChapter.id,
      next_chapter_id: nextChapter.id,
    };

    const { error } = await supabase.from("blueprint_chapter_transitions").insert(transitionData);

    if (error) {
      throw new Error(`Error creating transition: ${error.message}`);
    } else {
      setErrorMessage(
        (prev) =>
          `${prev ? prev + "\n" : ""}Created transition from ${currentChapter.chapter_name} to ${nextChapter.chapter_name}`,
      );
    }
  }

  return (
    <>
      <DialogTitle as="h2" className="font-serif py-6 text-4xl font-bold mb-4">
        Complete process
      </DialogTitle>
      <div className="flex flex-col">
        <div className="flex-grow">
          {errorMessage && <div className="mb-4 whitespace-pre-wrap">{errorMessage}</div>}
          <SpokableButton onClick={saveAll} className="mt-6 mb-6" disabled={isProcessing}>
            {isProcessing ? "Saving..." : "Save all"}
          </SpokableButton>
        </div>
      </div>
    </>
  );
};

export default SaveAll;
