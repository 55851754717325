import { useEffect, useRef, useState } from "react";

interface VideoTrimmingState {
  startFrame: number;
  endFrame: number;
  duration: number;
  currentTime: number;
  isVideoReady: boolean;
  isInitialized: boolean;
  isValidDuration: boolean;
}

const MAX_DURATION = 5; // Maximum duration in seconds

export const useVideoTrimming = (rawVideoUrl: string | null) => {
  const [state, setState] = useState<VideoTrimmingState>({
    startFrame: 0,
    endFrame: 0,
    duration: 0,
    currentTime: 0,
    isVideoReady: false,
    isInitialized: false,
    isValidDuration: false,
  });

  const previewRef = useRef<HTMLVideoElement>(null);

  const validateDuration = (start: number, end: number) => {
    const duration = end - start;
    const isValid = duration <= MAX_DURATION;

    return { isValid };
  };

  const handleVideoMetadata = (video: HTMLVideoElement) => {
    setState((prev) => {
      if (prev.isInitialized) return prev;
      if (isFinite(video.duration) && video.duration > 0) {
        const videoDuration = Math.max(0.1, video.duration);
        const initialEnd = Math.min(videoDuration, MAX_DURATION);
        const { isValid } = validateDuration(0, initialEnd);

        console.log("Video initialized with duration:", videoDuration);
        return {
          ...prev,
          duration: videoDuration,
          endFrame: initialEnd,
          isVideoReady: true,
          isInitialized: true,
          isValidDuration: isValid,
        };
      }
      return prev;
    });
  };

  const handleTimeUpdate = (video: HTMLVideoElement) => {
    if (video.readyState >= 2) {
      setState((prev) => {
        const time = video.currentTime;
        if (prev.isVideoReady && time >= prev.endFrame) {
          video.currentTime = prev.startFrame;
          video.play().catch((err) => console.warn("Error resuming playback:", err));
        }
        return { ...prev, currentTime: time };
      });
    }
  };

  const handleRangeChange = (value: number[]) => {
    setState((prev) => {
      if (!prev.isVideoReady || !previewRef.current) return prev;
      const [start, end] = value;
      const video = previewRef.current;

      if (isFinite(start) && isFinite(end) && start >= 0 && end <= prev.duration) {
        if (video.currentTime < start || video.currentTime > end) {
          video.currentTime = start;
        }

        const { isValid } = validateDuration(start, end);

        return {
          ...prev,
          startFrame: start,
          endFrame: end,
          isValidDuration: isValid,
        };
      }
      return prev;
    });
  };

  const resetState = () => {
    setState((prev) => ({
      ...prev,
      startFrame: 0,
      endFrame: 0,
      duration: 0,
      currentTime: 0,
      isVideoReady: false,
      isInitialized: false,
      isValidDuration: false,
      validationMessage: "",
    }));
  };

  useEffect(() => {
    if (!rawVideoUrl || !previewRef.current) return;
    const video = previewRef.current;
    console.log("Initializing video with URL:", rawVideoUrl);

    resetState();

    const handleMetadata = () => handleVideoMetadata(video);
    const handleTime = () => handleTimeUpdate(video);
    const handleLoadedData = () => {
      if (video.readyState >= 2) {
        handleVideoMetadata(video);
      }
    };

    video.addEventListener("loadeddata", handleLoadedData);
    video.addEventListener("canplay", handleMetadata);
    video.addEventListener("timeupdate", handleTime);

    video.src = rawVideoUrl;
    video.load();

    return () => {
      video.removeEventListener("loadeddata", handleLoadedData);
      video.removeEventListener("canplay", handleMetadata);
      video.removeEventListener("timeupdate", handleTime);
    };
  }, [rawVideoUrl]);

  return {
    state,
    previewRef,
    handleVideoMetadata,
    handleTimeUpdate,
    handleRangeChange,
    resetState,
  };
};
