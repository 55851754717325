interface OutputSchemaField {
  field_name: string;
  description: string;
}

interface OutputSchema {
  schema_name: string;
  fields: OutputSchemaField[];
}

interface SchemaResponseViewerProps {
  schema: OutputSchema;
  response: string;
}

const ResponseViewer: React.FC<SchemaResponseViewerProps> = ({ schema, response }) => {
  const responseData = JSON.parse(response);

  const renderArrayItem = (item: any) => {
    if (typeof item === "object" && item !== null) {
      return (
        <div className="space-y-1">
          {Object.entries(item).map(([key, value]) => (
            <div key={key}>
              <strong>{key}: </strong>
              {String(value)}
            </div>
          ))}
        </div>
      );
    }
    return String(item);
  };

  const renderValue = (value: any) => {
    if (Array.isArray(value)) {
      return (
        <ol className="list-decimal ml-6 ">
          {value.map((item, index) => (
            <li key={index} className="mb-2">
              {renderArrayItem(item)}
            </li>
          ))}
        </ol>
      );
    }

    if (typeof value === "boolean") {
      return <span>{value ? "Yes" : "No"}</span>;
    }

    if (typeof value === "object" && value !== null) {
      return (
        <div className="space-y-1">
          {Object.entries(value).map(([key, val]) => (
            <div key={key}>
              <strong>{key}: </strong>
              {String(val)}
            </div>
          ))}
        </div>
      );
    }

    return <span>{String(value)}</span>;
  };

  return (
    <div className="space-y-4">
      {schema.fields.map((field) => (
        <div key={field.field_name} className="mb-4 text-base/6 sm:text-sm/6">
          <h3 className="text-sm font-medium mb-2">
            {field.field_name.charAt(0).toUpperCase() + field.field_name.slice(1)}
          </h3>
          {responseData[field.field_name] !== undefined ? (
            renderValue(responseData[field.field_name])
          ) : (
            <span className="text-gray-500 italic">No data</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default ResponseViewer;
