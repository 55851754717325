import {
  IMAGE_TYPE_CINEMATIC_VIDEO,
  IMAGE_TYPE_DEPTH_MAP,
  IMAGE_TYPE_EMOTION_VIDEO,
  IMAGE_TYPE_EVENT_VIDEO,
  IMAGE_TYPE_ORIGINAL,
  IMAGE_TYPE_WITHOUT_BG,
  VISUAL_TYPE_IMAGE,
} from "../../../constants/constant.ts";
import {
  downloadImageFromSupabase,
  generateMediaFileName,
  getCustomEmotionVideoFileName,
  getImageNameWithoutStoryIdAndFileExtension,
  uploadToSupabaseFileFromUrl,
} from "../../../utils/mediaUtil.ts";
import { useState } from "react";
import { BluePrintImageToBeSaved } from "./GenerateImageVariations.tsx";
import { ImageVariation } from "../../../types/fastApiTypes.ts";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { Tables } from "../../../types/database.ts";

export async function downloadFile(url: string, type = VISUAL_TYPE_IMAGE) {
  const blob = await downloadImageFromSupabase(url);
  if (!blob) {
    console.log("image not found ", url);
    return;
  }
  return new File([blob], url, {
    type: `${type === VISUAL_TYPE_IMAGE ? "image/jpeg" : "video/webm"}`,
  });
}

type VariationFlag = keyof Pick<
  BluePrintImageToBeSaved,
  "has_depth_map" | "has_version_without_background" | "has_cinematic_videos"
>;

interface Upload {
  url: string | undefined | null;
  fileName: string;
  flag: VariationFlag;
}

export const useUploadVariations = (storyId: string) => {
  const [status, setStatus] = useState<string>("");
  const [canBeSaved, setCanBeSaved] = useState<boolean>(true);

  const uploadVariations = async (
    baseImageUrlWithStory: string | null,
    imageVariations: ImageVariation[],
  ): Promise<BluePrintImageToBeSaved | undefined> => {
    if (!baseImageUrlWithStory || !canBeSaved || !imageVariations.length) return;
    const baseObjectUrl = getImageNameWithoutStoryIdAndFileExtension(baseImageUrlWithStory);
    if (!baseObjectUrl) return;

    try {
      setStatus("Uploading...");
      const variation = imageVariations[0];
      const returnedImageObject: BluePrintImageToBeSaved = {
        image_url: generateMediaFileName(baseObjectUrl, IMAGE_TYPE_ORIGINAL),
        image_type: IMAGE_TYPE_ORIGINAL,
        has_original: true,
      };

      const uploads: Upload[] = [
        {
          url: variation.depth_map,
          fileName: generateMediaFileName(baseObjectUrl, IMAGE_TYPE_DEPTH_MAP),
          flag: "has_depth_map",
        },
        {
          url: variation.without_bg,
          fileName: generateMediaFileName(baseObjectUrl, IMAGE_TYPE_WITHOUT_BG),
          flag: "has_version_without_background",
        },
        {
          url: variation.cinematic_video,
          fileName: generateMediaFileName(baseObjectUrl, IMAGE_TYPE_CINEMATIC_VIDEO, "", true),
          flag: "has_cinematic_videos",
        },
        {
          url: variation.event_video,
          fileName: generateMediaFileName(baseObjectUrl, IMAGE_TYPE_EVENT_VIDEO, "", true),
          flag: "has_cinematic_videos",
        },
      ];

      await Promise.all([
        ...uploads.map(async ({ url, fileName, flag }) => {
          if (url && (await uploadToSupabaseFileFromUrl(storyId, url, fileName))) {
            returnedImageObject[flag] = true;
          }
        }),
        ...(variation.emotion_videos?.map(({ url, emotion }) =>
          uploadToSupabaseFileFromUrl(
            storyId,
            url,
            generateMediaFileName(baseObjectUrl, IMAGE_TYPE_EMOTION_VIDEO, emotion, true),
          ),
        ) || []),
      ]);

      if (variation.emotion_videos?.length) {
        returnedImageObject.has_emotion_videos = true;
      }

      return returnedImageObject;
    } catch (error) {
      console.error("Error uploading variations:", error);
    } finally {
      setStatus("");
      setCanBeSaved(true);
    }
  };

  return {
    uploadVariations,
    status,
    canBeSaved,
    setCanBeSaved,
  };
};

export async function getCustomEmotionDrivingVideos(
  selectedDrivingCollection: Tables<"blueprint_driving_video_collections"> | null,
  checkedEmotions: Set<string>,
  storyId: string,
) {
  if (!selectedDrivingCollection) return [];
  const { data, error } = await supabase
    .from("blueprint_driving_videos")
    .select("*")
    .eq("blueprint_driving_video_collection_id", selectedDrivingCollection.id);

  if (error) {
    console.log("Error fetching videos: " + error.message);
    return;
  }

  const filteredDrivingVideos = data?.filter(
    (video) => video.video_key && checkedEmotions.has(video.video_key),
  );

  if (!filteredDrivingVideos?.length) return [];

  const files: File[] = [];

  try {
    const downloadPromises = filteredDrivingVideos.map(async (video) => {
      const fileName = getCustomEmotionVideoFileName(
        selectedDrivingCollection.collection_name,
        video.video_key,
      );

      const file = await downloadFile(storyId + "/" + fileName);
      if (file) {
        files.push(file);
      }
    });

    // Wait for all downloads to complete
    await Promise.all(downloadPromises);

    return files;
  } catch (err) {
    console.error("Error downloading files:", err);
    return files; // Return any successfully downloaded files
  }
}
