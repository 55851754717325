import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalAutoCompleteState } from "../../../states/ModalState.ts";
import { useAutoComplete } from "./useGenerateChaptersHooks.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalAutoCompleteState);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const {
    data: autoCompleteOutput,
    status: autoCompleteStatus,
    callApi: autoComplete,
    setData: setAutoComplete,
  } = useAutoComplete();

  const handleClose = () => {
    setIsOpen(false);
    setStepIndex(0);
    setAutoComplete(null);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    autoCompleteOutput,
    autoCompleteStatus,
    autoComplete,
    setAutoComplete,

    handleClose,
  };
};
